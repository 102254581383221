/*
 * @Author: Chu Yifei
 * @LastEditors: Chu Yifei
 */
import { dateTimeFormats } from './dateTimeFormats'
import zh from './KS/zh'
import zh_CD from './CD/zh'
import en from './KS/en'
import en_CD from './CD/en'
import enGB from './KS/en-GB'
import enGB_CD from './CD/en-GB'
import VueI18n from 'vue-i18n'
import store from '@/store/index'
import Vue from 'vue'

let locale = store.state.locale
Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale,
  dateTimeFormats,
  fallbackLocale: 'zh',
  messages: {
    zh,
    en,
    enGB,
    zh_CD,
    en_CD,
    enGB_CD,
  },
})
