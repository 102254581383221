/*
 * @Author: Chu Yifei
 * @LastEditors: Chu Yifei
 */
import { DateTimeFormats } from 'vue-i18n'
export const dateTimeFormats: DateTimeFormats = {
  en: {
    month: {
      year: 'numeric',
      month: 'short',
    },
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  zh: {
    month: {
      year: 'numeric',
      month: 'short',
    },
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    },
  },
}
