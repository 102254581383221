













import { Component, Vue, Watch } from 'vue-property-decorator'
import {
  CurriculumController,
  StudentController,
  DropDownController,
} from '@/services/request.service'
import moment from 'moment'
import curriculumForExport from '@/components/curriculumForExport.vue'
import flattenDeep from 'lodash/flattenDeep'
@Component({
  components: {
    curriculumForExport,
  },
})
export default class ExportCurriculum extends Vue {
  private startTime = moment().startOf('isoWeek')
  private endTime = moment().endOf('isoWeek')
  private yearKey: number = 0
  private yearList: Array<any> = []
  private id: any = 0
  private type: any
  private classroomId: any
  private studentInfo: any = null
  private curriculumData: any = {
    courses: {},
    total: 0,
    substitute: 0,
  }
  @Watch('$route', { immediate: true })
  private onRouteChanged(to: any, from: any) {
    this.type = to.params.type
    this.yearKey = Number(to.params.key)
    this.startTime = moment(Number(to.params.time.split('-')[0]))
    this.endTime = moment(Number(to.params.time.split('-')[1].split('&')[0]))
    this.id = to.params.id * 1
    this.getCurriculum()
  }

  private getCurriculum(): void {
    const start = this.startTime.valueOf()
    const end = this.endTime.valueOf()
    if (this.type === 'teacherSelf') {
      CurriculumController.getPersonalCurriculum(start, end)
        .then(res => {
          this.curriculumData = {
            courses: res.data.classArranges,
            total: res.data.regular + res.data.institute,
            substitute: res.data.institute,
          }
        })
        .catch(err => {
          console.error(err)
        })
    } else if (this.type === 'classroom') {
      CurriculumController.getClassArrangesByClassRoomAndWeek(this.id, start, end)
        .then(res => {
          this.curriculumData.courses = res.data
          this.curriculumData.total = flattenDeep(Object.values(res.data)).length
        })
        .catch(err => {
          console.error(err)
        })
    } else if (this.type === 'teacherOther') {
      CurriculumController.getClassArrangesByTeacherAndWeek(this.id, start, end)
        .then(res => {
          this.curriculumData = {
            substitute: res.data.institute,
            total: res.data.regular + res.data.institute,
            courses: res.data.classArranges,
          }
        })
        .catch(err => {
          console.error(err)
        })
    } else {
      DropDownController.getSchoolYearRuleList().then(res => {
        if (res.status == 200) {
          this.yearList = res.data
          this.getStudentInfoAndClassArranges(this.yearKey, start, end)
        }
      })
    }
  }
  private getStudentInfoAndClassArranges(schoolYearId, start, end) {
    Promise.all([
      StudentController.getStudentBriefInfo(this.id),
      CurriculumController.getClassArrangesByStudentAndWeek(schoolYearId, this.id, start, end),
    ])
      .then(res => {
        this.studentInfo = res[0].data
        const { classArranges, classPeriods, dayOfArranged } = res[1].data
        let total = 0
        try {
          total = Object.keys(classArranges)
            .map(
              key =>
                classArranges[key] &&
                Object.keys(classArranges[key])
                  .map(c => classArranges[key][c])
                  .filter(item => item.type !== 'periodArranges').length
            )
            .filter(f => f || f === 0)
            .reduce((total, current) => total + current)
        } catch (err) {
          console.log(err)
        }
        this.curriculumData = {
          classArranges,
          classPeriods,
          dayOfArranged,
          total,
        }
      })
      .catch(err => {
        console.error(err)
      })
  }
}
