import Vue from 'vue'
import Router from 'vue-router'
import Main from '@/views/Main.vue'
import Login from '@/views/Login.vue'
import exportCurriculum from '@/views/ExportPage/ExportCurriculum.vue'
import ExportWeekTable from '@/views/DataForm/LifeBlockReport/WeekTableForExport.vue'
import ExceptionPage from '@/views/DefaultPage/ExceptionPage.vue'
import Store from '@/store/index'
import { ssoUtils } from 'sso-utils'
import { routerRightCode } from '@/constant/constant'

import {
  getToken,
  getSchoolInfo,
  removeToken,
  removeSchoolInfo,
  setToken,
  setSchoolInfo,
  validateVersion,
  setFeature,
  removeFeature,
  removeQRcode,
  setQRcode,
  setLocale,
} from '@/utils/utils'
import { FeatureController, LoginController, DropDownController } from './services/request.service'

Vue.use(Router)

const teaching = {
  path: 'teaching',
  name: 'teaching',
  component: () => import(/* webpackChunkName: "teaching" */ '@/views/Teaching/Teaching.vue'),
  meta: {
    routerName: 'teaching',
    rootPage: true,
    noChild: true,
  },
  children: [
    {
      path: 'assignmentEditor',
      name: 'assignmentEditor',
      component: () =>
        import(
          /* webpackChunkName: "teaching" */ '@/views/Teaching/components/AssignmentEditor.vue'
        ),
      meta: {
        routerName: 'addAssignment',
      },
    },
    {
      path: 'assignmentDetail/:id',
      name: 'assignmentDetail',
      component: () =>
        import(
          /* webpackChunkName: "teaching" */ '@/views/Teaching/components/AssignmentDetail.vue'
        ),
      meta: {
        routerName: 'assignmentDetail',
      },
    },
    {
      path: 'materialEditor',
      name: 'materialEditor',
      component: () =>
        import(/* webpackChunkName: "teaching" */ '@/views/Teaching/components/MaterialEditor.vue'),
      meta: {
        routerName: 'addMaterial',
        rootPage: true,
      },
    },
    {
      path: 'assignmentDetail/:id',
      name: 'sAssignmentDetail',
      component: () =>
        import(
          /* webpackChunkName: "scoreSheet" */ '@/views/Teaching/components/AssignmentDetail.vue'
        ),
      meta: {
        routerName: 'assignmentDetail',
      },
    },
  ],
}

const home = {
  path: 'home',
  name: 'home',
  component: () => import(/* webpackChunkName: "home" */ '@/views/Home/Home.vue'),
  meta: {
    routerName: 'home',
    rootPage: true,
    noChild: true,
  },
  children: [
    {
      path: 'studentList/:studentListType/:id',
      name: 'studentList',
      component: () =>
        import(/* webpackChunkName: "home" */ '@/views/Home/components/ClassViewer.vue'),
      meta: {
        routerName: 'studentList',
        rootPage: true,
      },
      children: [
        {
          path: 'detail/:studentId',
          name: 'classStudentDetail',
          component: () =>
            import(/* webpackChunkName: "home" */ '@/views/common/studentDetail/StudentDetail.vue'),
          meta: {
            routerName: 'studentDetail',
          },
        },
      ],
    },
    {
      path: 'noticeList',
      name: 'noticeList',
      component: () => import(/* webpackChunkName: "home" */ '@/views/Home/components/Notice.vue'),
      meta: {
        routerName: 'noticeList',
        rootPage: true,
      },
    },
    {
      path: 'noticeViewHome',
      name: 'noticeViewHome',
      component: () =>
        import(/* webpackChunkName: "home" */ '@/views/Home/components/NoticeViewHome.vue'),
      meta: {
        routerName: 'noticeView',
        rootPage: true,
      },
    },
  ],
}

// const myClass = {
//   path: 'myClass',
//   name: 'myClass',
//   component: () =>
//     import(/* webpackChunkName: "myClass" */ '@/views/MyClass/MyClass.vue'),
//   meta: {
//     routerName: 'myClass',
//     rootPage: true,
//     noChild: true,
//   },
//   children: [
//     {
//       path: 'studentList/:studentListType/:id',
//       name: 'studentList',
//       component: () =>
//         import(
//           /* webpackChunkName: "myClass" */ '@/views/MyClass/components/ClassViewer.vue'
//         ),
//       meta: {
//         routerName: 'studentList',
//         rootPage: true,
//       },
//       children: [
//         {
//           path: 'detail/:studentId',
//           name: 'classStudentDetail',
//           component: () =>
//             import(
//               /* webpackChunkName: "myClass" */ '@/views/common/studentDetail/StudentDetail.vue'
//             ),
//           meta: {
//             routerName: 'studentDetail',
//           },
//         },
//       ]
//     },
//   ],
// }

const myStudent = {
  path: 'myStudent/:status',
  name: 'myStudent',
  component: () => import(/* webpackChunkName: "myStudent" */ '@/views/MyStudent/MyStudent.vue'),
  meta: {
    routerName: 'myStudent',
    rootPage: true,
    noChild: true,
  },
  children: [
    {
      path: 'detail/:studentId',
      name: 'studentDetail',
      component: () =>
        import(
          /* webpackChunkName: "myStudent" */ '@/views/common/studentDetail/StudentDetail.vue'
        ),
      meta: {
        routerName: 'studentDetail',
      },
    },
  ],
}

// const attendanceReport = {
//   path: '/',
//   name: 'attendanceReport',
//   redirect: { name: 'reportViewer', params: { reportType: 'abnormal' } },
//   component: () =>
//     import(
//       /* webpackChunkName: "attendance" */ '@/views/Attendance/AttendanceReport/AttendanceReport.vue'
//     ),
//   meta: {
//     routerName: 'attendanceReport',
//     rootPage: true,
//     noChild: true,
//   },
//   children: [
//     {
//       path: 'reportViewer/:reportType?',
//       name: 'reportViewer',
//       component: () =>
//         import(
//           /* webpackChunkName: "attendance" */ '@/views/Attendance/AttendanceReport/components/ReportViewer.vue'
//         ),
//       meta: {
//         routerName: '',
//         noChild: true,
//       },
//     },
//   ],
// }
const studentAttendance = {
  path: '',
  name: 'studentAttendance',
  redirect: { name: 'attendanceViewer', params: { attendanceType: 'class' } },
  component: () =>
    import(
      /* webpackChunkName: "attendance" */ '@/views/Attendance/StudentAttendance/StudentAttendance.vue'
    ),
  meta: {
    routerName: 'studentAttendance',
    requestFor: 'attendance',
    noChild: true,
  },
  children: [
    {
      path: 'attendanceViewer/:attendanceType?',
      name: 'attendanceViewer',
      component: () =>
        import(
          /* webpackChunkName: "attendance" */ '@/views/Attendance/StudentAttendance/components/AttendanceViewer.vue'
        ),
      meta: {
        routerName: 'kindergartenAtten',
        requestFor: 'attendance',
        noChild: true,
      },
    },
    {
      path: 'kindergartenAtten',
      name: 'kindergartenAtten',
      component: () =>
        import(
          /* webpackChunkName: "kindergartenAtten" */ '@/views/Attendance/KindergartenAtten/KindergartenAtten.vue'
        ),
      meta: {
        routerName: 'kindergartenAtten',
        requestFor: 'attendance',
        rootPage: true,
        noChild: true,
      },
    },
    {
      path: 'dormitoryAtten',
      name: 'dormitoryAtten',
      component: () =>
        import(
          /* webpackChunkName: "attendance" */ '@/views/Attendance/StudentAttendance/components/DormitoryAtten.vue'
        ),
      meta: {
        routerName: 'dormitoryAtten',
        requestFor: 'attendance',
        rootPage: true,
        noChild: true,
      },
    },
    {
      path: 'studyRoomAtten',
      name: 'studyRoomAtten',
      component: () =>
        import(
          /* webpackChunkName: "attendance" */ '@/views/Attendance/StudentAttendance/components/StudyRoomAtten.vue'
        ),
      meta: {
        routerName: 'studyRoomAtten',
        requestFor: 'attendance',
        rootPage: true,
        noChild: true,
      },
    },
  ],
}
const attendanceStat = {
  path: 'attendanceStat',
  name: 'attendanceStat',
  redirect: { name: 'statViewer', params: { statType: 'grade' } },
  component: () =>
    import(
      /* webpackChunkName: "attendance" */ '@/views/Attendance/AttendanceStat/AttendanceStat.vue'
    ),
  meta: {
    routerName: 'attendanceStat',
    requestFor: 'attendanceStat',
    noChild: true,
  },
  children: [
    {
      path: 'statViewer/:statType?',
      name: 'statViewer',
      component: () =>
        import(
          /* webpackChunkName: "attendance" */ '@/views/Attendance/AttendanceStat/components/StatViewer.vue'
        ),
      meta: {
        routerName: '',
        requestFor: 'attendanceStat',
        noChild: true,
      },
    },
  ],
}
const attendance = {
  path: 'attendance',
  name: 'attendance',
  redirect: { name: 'studentAttendance' },
  component: () => import(/* webpackChunkName: "attendance" */ '@/views/Attendance/Attendance.vue'),
  meta: {
    routerName: 'attendance',
    requestFor: 'attendance',
  },
  children: [
    studentAttendance,
    attendanceStat,
    // kindergartenAtten,
  ],
}

const lifeBlockReport = {
  path: 'lifeBlockReport/:LBRType?',
  name: 'lifeBlockReport',
  component: () =>
    import(
      /* webpackChunkName: "dataForm" */ '@/views/DataForm/LifeBlockReport/LifeBlockReport.vue'
    ),
  meta: {
    routerName: 'lifeBlockReport',
    rootPage: true,
  },
}
const attendanceReport = {
  path: 'attendanceReport/:reportType?',
  name: 'attendanceReport',
  component: () =>
    import(
      /* webpackChunkName: "dataForm" */ '@/views/Attendance/AttendanceReport/AttendanceReport.vue'
    ),
  meta: {
    routerName: 'attendanceReport',
    rootPage: true,
  },
}
const eGradeReport = {
  path: 'eGradeReport',
  name: 'eGradeReport',
  component: () => import(/* webpackChunkName: "attendance" */ '@/views/DataForm/EGradeReport.vue'),
  meta: {
    routerName: 'eGradeReport',
    rootPage: true,
  },
}
const gradeReport = {
  path: 'gradeReport',
  name: 'gradeReport',
  component: () => import(/* webpackChunkName: "attendance" */ '@/views/DataForm/GradeReport.vue'),
  meta: {
    routerName: 'gradeReport',
    rootPage: true,
  },
}
const dataForm = {
  path: 'dataForm',
  name: 'dataForm',
  component: () => import(/* webpackChunkName: "dataForm" */ '@/views/DataForm/DataForm.vue'),
  meta: {
    routerName: 'dataForm',
    rootPage: true,
    noChild: true,
  },
  children: [attendanceReport, lifeBlockReport, eGradeReport, gradeReport],
}

const examPoints = {
  path: 'examPoints',
  name: 'examPoints',
  component: () => import(/* webpackChunkName: "exam" */ '@/views/ExamMgmt/ExamPoints.vue'),
  meta: {
    routerName: 'examPoints',
    rootPage: true,
    noChild: true,
  },
  children: [
    {
      path: 'studentExamPoints',
      name: 'studentExamPoints',
      component: () =>
        import(/* webpackChunkName: "exam" */ '@/views/ExamMgmt/components/StudentExamPoints.vue'),
      meta: {
        routerName: 'studentExamPoints',
      },
    },
  ],
}
const examRemark = {
  path: 'examRemark',
  name: 'examRemark',
  component: () => import(/* webpackChunkName: "exam" */ '@/views/ExamMgmt/ExamRemark.vue'),
  meta: {
    routerName: 'examRemark',
    rootPage: true,
    noChild: true,
  },
  children: [
    {
      path: 'studentExamPointsRemark',
      name: 'studentExamPointsRemark',
      component: () =>
        import(
          /* webpackChunkName: "exam" */ '@/views/ExamMgmt/components/studentExamPointsRemark.vue'
        ),
      meta: {
        routerName: 'studentExamPoints',
      },
    },
  ],
}
const exam = {
  path: 'examMgmt',
  name: 'examMgmt',
  component: () => import(/* webpackChunkName: "exam" */ '@/views/ExamMgmt/ExamMgmt.vue'),
  meta: {
    routerName: 'examMgmt',
  },
  children: [examPoints, examRemark],
}

const diary = {
  path: 'diary',
  name: 'diary',
  component: () => import(/* webpackChunkName: "diary" */ '@/views/Diary/Diary.vue'),
  meta: {
    routerName: 'diary',
    requestFor: 'diary',
    rootPage: true,
    noChild: true,
  },
  children: [
    {
      path: 'detail/:studentId',
      name: 'diaryDetail',
      component: () => import(/* webpackChunkName: "diary" */ '@/views/Diary/Diarydetail.vue'),
      meta: {
        routerName: 'diaryDetail',
        requestFor: 'diary',
      },
    },
    {
      path: 'diaryEditor',
      name: 'diaryEditor',
      component: () =>
        import(/* webpackChunkName: "diary" */ '@/views/Diary/components/DiaryEditor.vue'),
      meta: {
        routerName: 'addDiary',
        requestFor: 'diary',
      },
    },
  ],
}

const eGrade = {
  path: 'eGrade',
  name: 'eGrade',
  component: () =>
    import(/* webpackChunkName: "reportMgmt" */ '@/views/ReportMgmt/EGrade/EGrade.vue'),
  meta: {
    routerName: 'eGrade',
    rootPage: true,
    noChild: true,
  },
}
const comment = {
  path: 'comment/:commentType?',
  name: 'comment',
  // redirect: { name: 'headTeacherRemark' },
  component: () =>
    import(/* webpackChunkName: "reportMgmt" */ '@/views/ReportMgmt/Comment/Comment.vue'),
  meta: {
    routerName: 'comment',
    noChild: true,
  },
  // children: [
  //   {
  //     path: 'headTeacher',
  //     name: 'headTeacherRemark',
  //     component: () =>
  //       import(
  //         /* webpackChunkName: "reportMgmt" */ '@/views/ReportMgmt/Comment/HeadTeacherRemark.vue'
  //       ),
  //     meta: {
  //       routerName: 'headTeacherRemark',
  //       rootPage: true,
  //       noChild: true,
  //     },
  //   },
  //   {
  //     path: 'advisor',
  //     name: 'advisorRemark',
  //     component: () =>
  //       import(
  //         /* webpackChunkName: "reportMgmt" */ '@/views/ReportMgmt/Comment/AdvisorRemark.vue'),
  //     meta: {
  //       routerName: 'advisorRemark',
  //       rootPage: true,
  //       noChild: true,
  //     },
  //   },
  //   {
  //     path: 'subject',
  //     name: 'subjectRemark',
  //     component: () =>
  //       import(
  //         /* webpackChunkName: "reportMgmt" */ '@/views/ReportMgmt/Comment/SubjectRemark.vue'
  //       ),
  //     meta: {
  //       routerName: 'subjectRemark',
  //       rootPage: true,
  //       noChild: true,
  //     },
  //   },
  // ],
}
const report = {
  path: 'report/:reportType',
  name: 'report',
  component: () =>
    import(/* webpackChunkName: "reportMgmt" */ '@/views/ReportMgmt/Report/Report.vue'),
  meta: {
    routerName: 'report',
    rootPage: true,
    noChild: true,
  },
}

const academicStanding = {
  path: 'academicStanding',
  name: 'academicStanding',
  component: () =>
    import(
      /* webpackChunkName: "reportMgmt" */ '@/views/ReportMgmt/AcademicStanding/AcademicStanding.vue'
    ),
  meta: {
    routerName: 'academicStanding',
    rootPage: true,
  },
  children: [
    {
      path: 'academicDetail/:studentId',
      name: 'academicDetail',
      component: () =>
        import(
          /* webpackChunkName: "reportMgmt" */ '@/views/ReportMgmt/AcademicStanding/AcademicDetail.vue'
        ),
      meta: {
        routerName: 'academicStandingDetail',
        rootPage: true,
      },
    },
    {
      path: 'gradeBook/:studentId',
      name: 'gradeBook',
      component: () =>
        import(
          /* webpackChunkName: "reportMgmt" */ '@/views/ReportMgmt/AcademicStanding/GradeBook.vue'
        ),
      meta: {
        routerName: 'gradeBook',
        rootPage: true,
        noChild: true,
      },
    },
  ],
}
const reportMgmt = {
  path: 'reportMgmt',
  name: 'reportMgmt',
  component: () => import(/* webpackChunkName: "reportMgmt" */ '@/views/ReportMgmt/ReportMgmt.vue'),
  meta: {
    routerName: 'reportMgmt',
    rootPage: true,
    noChild: true,
  },
  children: [comment, report, eGrade, academicStanding],
}

const monthlyReport = {
  path: 'monthlyReport',
  name: 'monthlyReport',
  component: () =>
    import(/* webpackChunkName: "monthlyReport" */ '@/views/MonthlyReport/MonthlyReport.vue'),
  meta: {
    routerName: 'reportMgmt',
    rootPage: true,
    noChild: true,
  },
  children: [
    {
      path: 'assessment',
      name: 'assessment',
      component: () =>
        import(
          /* webpackChunkName: "monthlyReport" */ '@/views/MonthlyReport/Assessment/Assessment.vue'
        ),
      meta: {
        routerName: 'assessment',
        noChild: true,
        requestFor: 'assessment',
      },
    },
    {
      path: 'monthlyComment/:commentType?',
      name: 'monthlyComment',
      component: () =>
        import(
          /* webpackChunkName: "monthlyReport" */ '@/views/MonthlyReport/MonthlyComment/MonthlyComment.vue'
        ),
      meta: {
        routerName: 'monthlyComment',
        noChild: true,
        requestFor: 'monthlyComment',
      },
    },
    {
      path: 'conduct',
      name: 'conduct',
      component: () =>
        import(/* webpackChunkName: "monthlyReport" */ '@/views/MonthlyReport/Conduct/Conduct.vue'),
      meta: {
        routerName: 'conduct',
        noChild: true,
      },
    },
    {
      path: 'report2/:reportType',
      name: 'report2',
      component: () =>
        import(
          /* webpackChunkName: "monthlyReport" */ '@/views/MonthlyReport/Report/MonthlyReport.vue'
        ),
      meta: {
        routerName: 'report2',
        noChild: true,
      },
    },
  ],
}

const CCAClass = {
  path: 'CCAClass',
  name: 'CCAClass',
  component: () =>
    import(/* webpackChunkName: "otherApp" */ '@/views/OtherApp/CCAClass/CCAClass.vue'),
  meta: {
    routerName: 'CCAClass',
    rootPage: true,
  },
  children: [
    {
      path: 'CCAClassDetail/:classId',
      name: 'CCAClassDetail',
      component: () =>
        import(
          /* webpackChunkName: "otherApp" */ '@/views/OtherApp/CCAClass/components/ClassDetail.vue'
        ),
      meta: {
        routerName: 'CCAClassDetail',
        showHeader: false,
      },
    },
  ],
}
const parentsMeeting = {
  path: 'parentsMeeting',
  name: 'parentsMeeting',
  component: () =>
    import(/* webpackChunkName: "otherApp" */ '@/views/OtherApp/ParentsMeeting/ParentsMeeting.vue'),
  meta: {
    routerName: 'parentsMeeting',
    rootPage: true,
  },
  children: [
    {
      path: 'parentsMeetingDetail/:meetingId',
      name: 'parentsMeetingDetail',
      component: () =>
        import(
          /* webpackChunkName: "otherApp" */ '@/views/OtherApp/ParentsMeeting/components/MeetingDetail.vue'
        ),
      meta: {
        routerName: 'parentsMeetingDetail',
        showHeader: false,
      },
    },
  ],
}
const leaveApproval = {
  path: 'leaveApproval/:approvalStatus',
  name: 'leaveApproval',
  component: () =>
    import(/* webpackChunkName: "otherApp" */ '@/views/OtherApp/LeaveApproval/LeaveApproval.vue'),
  meta: {
    routerName: 'leaveApproval',
    rootPage: true,
  },
  children: [
    {
      path: 'leaveEditor',
      name: 'leaveEditor',
      component: () =>
        import(
          /* webpackChunkName: "takeLeave" */ '@/views/OtherApp/LeaveApproval/components/LeaveEditor.vue'
        ),
      meta: {
        routerName: 'addLeave',
        showHeader: false,
      },
    },
  ],
}
const moment = {
  path: 'moment',
  name: 'moment',
  component: () => import(/* webpackChunkName: "otherApp" */ '@/views/OtherApp/Moment/Moment.vue'),
  meta: {
    routerName: 'moment',
    rootPage: true,
    noChild: true,
  },
  children: [
    {
      path: 'momentEditor',
      name: 'momentEditor',
      component: () =>
        import(
          /* webpackChunkName: "otherApp" */ '@/views/OtherApp/Moment/components/MomentEditor.vue'
        ),
      meta: {
        routerName: 'addMoment',
        rootPage: true,
      },
    },
  ],
}
const homework = {
  path: 'homework/:classType',
  name: 'homework',
  component: () => import(/* webpackChunkName: "homework" */ '@/views/HomeWork/HomeWork.vue'),
  meta: {
    routerName: 'homework',
    // noChild: true,
    rootPage: true,
  },
  children: [
    {
      path: 'homeworkDetail/:homeworkId',
      name: 'homeworkDetail',
      component: () =>
        import(/* webpackChunkName: "homework" */ '@/views/HomeWork/HomeWorkDetail.vue'),
      meta: {
        routerName: 'homeworkDetail',
        rootPage: true,
      },
      children: [
        {
          path: 'studentHomeworkDetail',
          name: 'studentHomeworkDetail',
          component: () =>
            import(/* webpackChunkName: "homework" */ '@/views/HomeWork/StudentHomeWorkDetail.vue'),
          meta: {
            routerName: 'studentHomeworkDetail',
            rootPage: true,
          },
        },
      ],
    },
    {
      path: 'publishHomework',
      name: 'publishHomework',
      component: () =>
        import(/* webpackChunkName: "homework" */ '@/views/HomeWork/PublishHomeWork.vue'),
      meta: {
        routerName: 'publishHomework',
        rootPage: true,
      },
    },
  ],
}
const calendar = {
  path: 'calendar',
  name: 'calendar',
  component: () => import(/* webpackChunkName: "calendar" */ '@/views/Calendar/Calendar.vue'),
  meta: {
    routerName: 'calendar',
    rootPage: true,
  },
}
const sen = {
  path: 'sen',
  name: 'sen',
  component: () => import(/* webpackChunkName: "personal" */ '@/views/OtherApp/Sen/Sen.vue'),
  meta: {
    routerName: 'sen',
    rootPage: true,
  },
  children: [
    {
      path: 'senEditor',
      name: 'senEditor',
      component: () =>
        import(/* webpackChunkName: "homework" */ '@/views/OtherApp/Sen/components/SenEditor.vue'),
      meta: {
        routerName: 'addSen',
        rootPage: true,
      },
    },
  ],
}
const addressBook = {
  path: 'addressBook',
  name: 'addressBook',
  component: () => import(/* webpackChunkName: "otherApp" */ '@/views/AddressBook/AddressBook.vue'),
  meta: {
    routerName: 'addressBook',
    noChild: true,
    rootPage: true,
  },
  children: [
    // {
    //   path: 'manageExternal',
    //   name: 'manageExternal',
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "otherApp" */ '@/views/AddressBook/components/ManageExternal.vue'
    //     ),
    //   meta: {
    //     routerName: 'manageExternalStaff'
    //   },
    // },
    {
      path: 'staffDetail',
      name: 'staffDetail',
      component: () =>
        import(/* webpackChunkName: "otherApp" */ '@/views/AddressBook/components/StaffDetail.vue'),
      meta: {
        routerName: 'staffDetail',
      },
    },
  ],
}

const noticeManage = {
  path: 'noticeManage',
  name: 'noticeManage',
  redirect: { name: 'contentManagement' },
  component: () => import(/* webpackChunkName: "otherApp" */ '@/views/OtherApp/Notice/Notice.vue'),
  meta: {
    routerName: 'noticeManage',
    rootPage: true,
  },
  children: [
    {
      path: 'contentManagement',
      name: 'contentManagement',
      component: () =>
        import(
          /* webpackChunkName: "otherApp" */ '@/views/OtherApp/Notice/ContentManagement/ContentManagement.vue'
        ),
      meta: {
        routerName: 'contentManagement',
        rootPage: true,
        // showHeader: true,
      },
    },
    {
      path: 'noticeEdit',
      name: 'noticeEdit',
      component: () =>
        import(
          /* webpackChunkName: "otherApp" */ '@/views/OtherApp/Notice/components/NoticeEdit.vue'
        ),
      meta: {
        routerName: 'noticeEdit',
        rootPage: true,
        // showHeader: true,
      },
    },
    {
      path: 'noticeView',
      name: 'noticeView',
      component: () =>
        import(
          /* webpackChunkName: "otherApp" */ '@/views/OtherApp/Notice/components/NoticeView.vue'
        ),
      meta: {
        routerName: 'noticeView',
        rootPage: true,
        // showHeader: true,
      },
    },
    {
      path: 'recycleBin',
      name: 'recycleBin',
      component: () =>
        import(
          /* webpackChunkName: "otherApp" */ '@/views/OtherApp/Notice/components/RecycleBin.vue'
        ),
      meta: {
        routerName: 'recycleBin',
        rootPage: true,
        // showHeader: true,
      },
    },
  ],
}

const otherApp = {
  path: 'otherApp',
  name: 'otherApp',
  component: () => import(/* webpackChunkName: "otherApp" */ '@/views/OtherApp/OtherApp.vue'),
  meta: {
    routerName: 'otherApp',
    rootPage: true,
    noChild: true,
  },
  children: [
    CCAClass,
    parentsMeeting,
    leaveApproval,
    // moment,
    calendar,
    homework,
    sen,
    noticeManage,
  ],
}

const myCurriculum = {
  path: 'my',
  name: 'myCurriculum',
  component: () =>
    import(/* webpackChunkName: "curriculum" */ '@/views/Curriculum/MyCurriculum.vue'),
  meta: {
    routerName: 'myCurriculum',
    rootPage: true,
    showHeader: true,
  },
}
const myAdjustCurriculum = {
  path: 'adjust',
  name: 'myAdjustCurriculum',
  component: () =>
    import(/* webpackChunkName: "curriculum" */ '@/views/Curriculum/MyAdjustCurriculum.vue'),
  meta: {
    routerName: 'myAdjustCurriculum',
    rootPage: true,
    noChild: true,
  },
}
const adjustApproval = {
  path: 'approval',
  name: 'adjustApproval',
  component: () =>
    import(/* webpackChunkName: "curriculum" */ '@/views/AdjustCurriculum/AdjustApproval.vue'),
  meta: {
    routerName: 'adjustApproval',
    rootPage: true,
    noChild: true,
  },
}
const teacherCurriculum = {
  path: 'teacherCurriculum',
  name: 'teacherCurriculum',
  component: () =>
    import(/* webpackChunkName: "curriculum" */ '@/views/AdjustCurriculum/TeacherCurriculum.vue'),
  meta: {
    routerName: 'teacherCurriculum',
    rootPage: true,
    noChild: true,
  },
}
const classroomCurriculum = {
  path: 'classroomCurriculum',
  name: 'classroomCurriculum',
  component: () =>
    import(/* webpackChunkName: "curriculum" */ '@/views/AdjustCurriculum/ClassroomCurriculum.vue'),
  meta: {
    routerName: 'classroomCurriculum',
    rootPage: true,
    noChild: true,
  },
}
const curriculum = {
  path: 'curriculum',
  name: 'curriculum',
  redirect: { name: 'myCurriculum' },
  component: () => import(/* webpackChunkName: "curriculum" */ '@/views/Curriculum/Curriculum.vue'),
  meta: {
    routerName: 'curriculum',
  },
  children: [
    myCurriculum,
    myAdjustCurriculum,
    adjustApproval,
    teacherCurriculum,
    classroomCurriculum,
  ],
}

const message = {
  path: 'message',
  name: 'message',
  component: () => import(/* webpackChunkName: "message" */ '@/views/Message/Message.vue'),
  redirect: { name: 'outbox' },
  meta: {
    routerName: 'message',
  },
  children: [
    {
      path: 'outbox',
      name: 'outbox',
      component: () => import(/* webpackChunkName: "message" */ '@/views/Message/Outbox.vue'),
      meta: {
        routerName: 'outbox',
        rootPage: true,
        noChild: true,
      },
      children: [
        {
          path: 'send',
          name: 'sendMessage',
          hidden: true,
          component: () =>
            import(/* webpackChunkName: "message" */ '@/views/Message/components/Send.vue'),
          meta: {
            routerName: 'sendMessage',
          },
        },
        {
          path: 'detail/:messageId',
          name: 'outDetail',
          hidden: true,
          component: () =>
            import(/* webpackChunkName: "message" */ '@/views/Message/components/Detail.vue'),
          meta: {
            routerName: 'sentDetail',
            rootPage: true,
          },
          children: [
            {
              path: 'detail/:studentId',
              name: 'outMesStudentDetail',
              component: () =>
                import(
                  /* webpackChunkName: "myClass" */ '@/views/common/studentDetail/StudentDetail.vue'
                ),
              meta: {
                routerName: 'studentDetail',
              },
            },
          ],
        },
      ],
    },
    {
      path: 'inbox/:mesType',
      name: 'inbox',
      component: () => import(/* webpackChunkName: "message" */ '@/views/Message/Inbox.vue'),
      meta: {
        routerName: 'inbox',
        rootPage: true,
        noChild: true,
      },
      children: [
        {
          path: 'reply',
          name: 'replyMessage',
          hidden: true,
          component: () =>
            import(/* webpackChunkName: "message" */ '@/views/Message/components/Send.vue'),
          meta: {
            routerName: 'replyMessage',
          },
        },
        {
          path: 'detail/:messageId',
          name: 'inDetail',
          hidden: true,
          component: () =>
            import(/* webpackChunkName: "message" */ '@/views/Message/components/Detail.vue'),
          meta: {
            routerName: 'receivedDetail',
            rootPage: true,
          },
          children: [
            {
              path: 'detail/:studentId',
              name: 'inMesStudentDetail',
              component: () =>
                import(
                  /* webpackChunkName: "myClass" */ '@/views/common/studentDetail/StudentDetail.vue'
                ),
              meta: {
                routerName: 'studentDetail',
              },
            },
          ],
        },
      ],
    },
  ],
}

const graduation = {
  path: 'graduation',
  name: 'graduation',
  component: () => import(/* webpackChunkName: "graduation" */ '@/views/Graduation/Graduation.vue'),
  meta: {
    routerName: 'graduation',
    rootPage: true,
    noChild: true,
  },
  children: [
    {
      path: 'studentInfo/:studentId',
      name: 'studentInfo',
      component: () =>
        import(/* webpackChunkName: "graduation" */ '@/views/Graduation/StudentInfo.vue'),
      meta: {
        routerName: 'studentInfo',
        rootPage: true,
        showHeader: true,
        refresh: true,
      },
    },
  ],
}

const personal = {
  path: 'personal',
  name: 'personal',
  component: () => import(/* webpackChunkName: "personal" */ '@/views/Personal/Personal.vue'),
  hidden: true,
  meta: {
    routerName: 'personal',
    rootPage: true,
  },
}

const systemMessage = {
  path: 'systemMessage/:messageType',
  name: 'systemMessage',
  component: () =>
    import(/* webpackChunkName: "systemMessage" */ '@/views/SystemMessage/SystemMessage.vue'),
  hidden: true,
  meta: {
    routerName: 'systemMessage',
    rootPage: true,
    noChild: true,
  },
}

const DefaultPage = [
  {
    path: '/404',
    name: '404',
    component: ExceptionPage,
    props: {
      type: '404',
    },
  },
  {
    path: '/403',
    name: '403',
    component: ExceptionPage,
    props: {
      type: '403',
    },
  },
]

const exportPage = [
  {
    path: '/exportCurriculum/:token/:schoolId/:time/:type/:id/:key',
    name: 'exportCurriculum',
    component: exportCurriculum,
  },
  {
    path:
      '/exportWeekTable/:token/:schoolId/:week/:classIds/:houseGroupIds/:studentName/:studentNum',
    name: 'exportWeekTable',
    component: ExportWeekTable,
  },
  {
    path:
      '/exportReport/:token/:schoolId/:studentId/:periodId/:reportType/:campusType/:time/:schoolYearType',
    name: 'exportReport',
    component: () =>
      import(/* webpackChunkName: "exportPage" */ '@/views/ExportPage/ExportReport.vue'),
  },
  {
    path: '/exportMonthlyReport/:token/:schoolId/:studentId/:periodId',
    name: 'exportMonthlyReport',
    component: () =>
      import(/* webpackChunkName: "exportPage" */ '@/views/ExportPage/ExportMonthlyReport.vue'),
  },
  {
    path: '/exportAcademicProgressReport/:token/:schoolId/:studentId',
    name: 'exportAcademicProgressReport',
    component: () =>
      import(
        /* webpackChunkName: "exportAcademicProgressReport" */ '@/views/ExportPage/ExportAcademicProgressReport.vue'
      ),
  },
]
const routes = [
  {
    path: '/',
    redirect: to => {
      const auths = Store.state.routeAuths
      return calcRedirectRoute(auths)
    },
    name: 'main',
    component: Main,
    children: [
      // myClass,
      home,
      myStudent,
      attendance,
      diary,
      message,
      curriculum,
      exam,
      // courseMaterial,
      otherApp,
      // moment,
      personal,
      systemMessage,
      reportMgmt,
      monthlyReport,
      teaching,
      dataForm,
      addressBook,
      graduation,
    ],
  },
]
Store.commit('setRoutes', routes)
const constantRouter = [
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  ...exportPage,
  ...DefaultPage,
  ...routes,
]

const router = new Router({
  mode: 'history',
  routes: constantRouter,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: to.meta.savedPosition || 0 }
    }
  },
}) as any

function calcRedirectRoute(auths): any {
  let name

  for (let i = 0; i < auths.length; i++) {
    if (auths[i]) {
      name = auths[i]
      break
    }
  }

  // 首页重定向
  if (auths.includes('home')) {
    name = 'home'
  }
  if (!name) {
    name = '403'
  }
  return { name }
}

function syncRoute(): Promise<any> {
  return new Promise(resolve => {
    Promise.all([FeatureController.getFeatureList(), DropDownController.getAttendanceKinds()])
      .then(res => {
        res[0].data.featureCodes.sort((a, b) => {
          return Number(a) - Number(b)
        })
        let routeAuth = res[0].data.featureCodes
          .map(item => {
            if (typeof routerRightCode[item] !== 'object') return routerRightCode[item]
            const { name, children } = routerRightCode[item]
            return [name].concat(children || [])
          })
          .flat()
          .filter(item => item)
        Store.commit('setRouteAuths', routeAuth)
        Store.commit('setOperationAuths', res[0].data.featureCodes)
        Store.commit('setDataPermission', res[0].data.dataPermission === '1001')
        Store.commit('setAuthLoadStatus', true)
        Store.commit(
          'setAttendanceStatusList',
          res[1].data.map(item => item.extraValue)
        )
        resolve('success')
      })
      .catch(err => {
        Store.commit('setAuthLoadStatus', true)
        resolve('failed')
      })
  })
}

async function getRightToken(token, next, to): Promise<any> {
  try {
    const res = await LoginController.switchToken(token)
    const rightToken = res.data.token
    const schools = res.data.schools
    setToken(rightToken, true)
    // setSchoolInfo(schools[0] || {})
    removeSchoolInfo()
    localStorage.setItem('schoolInfo', JSON.stringify(schools[0] || {}))

    const locale = schools[0].schoolId === 8 ? 'enGB' : 'en'
    Store.commit('changeLocale', locale)
    setLocale(locale)

    const qrcode = await LoginController.getQRCode()
    setQRcode(qrcode.data)
    syncRoute().then(() => {
      // let el = document.getElementById('project-loading') as any
      // if (el) el.remove()
      // let query = to.query
      // delete query.access_token
      // next(
      //   to.name === '403'
      //     ? { name: 'main' }
      //     : {
      //         ...to,
      //         query: {},
      //       }
      // )
      window.location.replace(window.location.origin)
    })
  } catch (err) {
    console.log(err => err)
  }
}

router.beforeEach((to, from, next) => {
  Store.commit('changeForceBack', false)
  const schoolId = (getSchoolInfo() || {}).schoolId
  if (exportList.includes(to.name)) {
    let el = document.getElementById('project-loading') as any
    if (el) el.remove()
    document.body.style.overflowY = 'auto'
    if (!to.params.token) {
      next({ name: 'login' })
    } else {
      setToken(to.params.token as string, false)
      // setSchoolInfo({ schoolId: to.params.schoolId })
      removeSchoolInfo()
      localStorage.setItem('schoolInfo', JSON.stringify({ schoolId: to.params.schoolId }))
      next()
    }
    return
  }
  let routeAuth = Store.state.routeAuths
  let authLoaded = Store.state.authLoaded
  if (to.name === 'login') {
    // next()
    const schoolInfo = getSchoolInfo()
    if (getToken() && schoolInfo && schoolInfo.schoolId) {
      next({ name: 'main' })
    } else {
      removeToken()
      removeSchoolInfo()
      removeQRcode()
      next()
    }
  } else if (to.query.sso) {
    ssoUtils.fromSSOLogic(to, from, next, to.query.sso as string, getToken(), async () => {
      if (!authLoaded) {
        syncRoute().then(() => {
          let el = document.getElementById('project-loading') as any
          if (el) el.remove()
          next(
            to.name === '403'
              ? { name: 'main' }
              : {
                  ...to,
                }
          )
        })
      } else {
        next()
      }
    })
  } else if (to.query.access_token) {
    getRightToken(to.query.access_token as string, next, to)
  } else if (!getToken()) {
    ssoUtils.commonLogic(to, from, next, getToken())
  } else if (!authLoaded) {
    syncRoute().then(() => {
      let el = document.getElementById('project-loading') as any
      if (el) el.remove()
      next(
        to.name === '403'
          ? { name: 'main' }
          : {
              ...to,
            }
      )
    })
  } else if (
    to.matched.length === 0 ||
    (to.meta.authSchoolId && !to.meta.authSchoolId.includes(schoolId))
  ) {
    next({ name: '404' })
  } else if (whiteList.includes(to.name) || routeAuth.includes(to.name)) {
    const schoolInfo = getSchoolInfo()
    if (getToken() && schoolInfo && schoolInfo.schoolId) {
      validateVersion(false, () => {
        next()
      })
    } else {
      removeToken()
      removeSchoolInfo()
      removeQRcode()
      next({ name: 'login' })
    }
  } else {
    // 没有权限
    next({ name: '403' })
  }
})

router.beforeResolve((to, from, next) => {
  if (to?.meta?.requestFor) {
    setFeature(to.meta.requestFor)
  } else {
    removeFeature()
  }
  next()
})

const exportList = [
  'exportCurriculum',
  'exportWeekTable',
  'exportReport',
  'export',
  'exportMonthlyReport',
  'exportAcademicProgressReport',
]

export const whiteList = ['personal', 'systemMessage', 'login', '404', '403']

export default router
