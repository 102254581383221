import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { RootState } from './modules/types'
import { calcI18n } from '../utils/utils'
import { i18n } from '@/i18n/i18n'

Vue.use(Vuex)

const store: StoreOptions<RootState> = {
  state: {
    locale: 'zh',
    i18nLocale: 'zh',
    username: '',
    memberId: 0,
    avatar: '',
    fileUploadProgress: {},
    forceBack: false,
    routeAuths: [],
    operationAuths: [],
    routes: [],
    messageStudents: [],
    diaryStudents: [],
    dataPermission: false,
    refresh: false,
    currentSchoolYear: {},
    unreadStatus: false,
    systemMessageNum: {},
    authLoaded: false,
    userAuths: {},
    CSInfo: {},
    studentIds: [],
    seniorStudentIds: [],
    ratio: 1,
    messageIds: [],
    unreadSystemNum: 0,
    isPc: true,
    attendanceStatusList: [],
  },
  getters: {
    getProgress: state => {
      return state.fileUploadProgress
    },
  },
  mutations: {
    changeLocale(state, payload: string) {
      state.locale = payload
      state.i18nLocale = calcI18n(payload)
      i18n.locale = calcI18n(payload)
    },
    setUserInfo(
      state,
      payload: { username: string; memberId: number; avatar: string; userAuths: any }
    ) {
      const { username, avatar, memberId, userAuths } = payload
      state.username = username
      state.memberId = memberId
      state.avatar = avatar
      state.userAuths = userAuths
    },
    setCurriculumStudentInfo(state, info) {
      state.CSInfo = info
    },
    setAuthLoadStatus(state, status) {
      state.authLoaded = status
    },
    setUserName(state, name) {
      state.username = name
    },
    setSystemMessageNum(state, systemMessageNum) {
      state.systemMessageNum = systemMessageNum
    },
    setCurrentSchoolYear(state, payload) {
      state.currentSchoolYear = payload
    },
    setFileUploadProgress(state, payload: { key: string; progress: number }) {
      const { key, progress } = payload
      Vue.set(state.fileUploadProgress, key, progress)
    },
    changeForceBack(state, payload: boolean) {
      state.forceBack = payload
    },
    setRouteAuths(state, payload) {
      state.routeAuths = payload
    },
    setUnreadStatus(state, status) {
      state.unreadStatus = status
    },
    setSystemUnreadNum(state, num) {
      state.unreadSystemNum = num
    },
    removeRouteAuths(state) {
      state.routeAuths = []
    },
    setRoutes(state, payload) {
      state.routes = payload
    },
    setOperationAuths(state, payload) {
      state.operationAuths = payload
    },
    removeOperationAuths(state) {
      state.operationAuths = []
    },
    setDataPermission(state, payload) {
      state.dataPermission = payload
    },
    removeDataPermission(state) {
      state.dataPermission = false
    },
    setMessageStudents(state, payload) {
      state.messageStudents = payload
    },
    setDiaryStudents(state, payload) {
      state.diaryStudents = payload
    },
    refresh(state) {
      state.refresh = true
    },
    cleanRefresh(state) {
      state.refresh = false
    },
    setSeniorStudentIds(state, ids) {
      state.seniorStudentIds = ids
    },
    setAllStudentIds(state, ids) {
      state.studentIds = ids
    },
    setAllMessageIds(state, ids) {
      state.messageIds = ids
    },
    setRatio(state, ratio) {
      state.ratio = ratio
    },
    setIsPc(state, isPc) {
      state.isPc = isPc
    },
    setAttendanceStatusList(state, payload) {
      state.attendanceStatusList = payload
    },
  },
  actions: {
    refreshRoutes({ commit }) {
      commit('removeRouteAuths')
      commit('setAuthLoadStatus', false)
      commit('removeOperationAuths')
      commit('removeDataPermission')
    },
  },
}

export default new Vuex.Store<RootState>(store)
