








































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class TableContainer extends Vue {
  @Prop({ default: () => [] }) private students!: Array<any>
}
