const defaultMenu = [
  {
    name: 'home',
  },
  {
    name: 'myStudent',
  },
  {
    name: 'attendance',
    redirect: 'studentAttendance',
    children: [
      {
        name: 'studentAttendance',
        redirect: 'attendanceViewer',
      },
      {
        name: 'attendanceStat',
        redirect: 'statViewer',
      },
    ],
  },
  {
    name: 'diary',
  },
  // {
  //   name: "moment",
  // },
  {
    name: 'curriculum',
    children: [
      {
        name: 'myCurriculum',
      },
      {
        name: 'myAdjustCurriculum',
      },
      {
        name: 'adjustApproval',
      },
      {
        name: 'teacherCurriculum',
      },
      {
        name: 'classroomCurriculum',
      },
    ],
  },
  {
    name: 'addressBook',
  },
  {
    name: 'message',
    showDot: true,
    children: [
      {
        name: 'inbox',
        showDot: true,
      },
      {
        name: 'outbox',
      },
    ],
  },
  {
    name: 'examMgmt',
    children: [
      {
        name: 'examPoints',
      },
      {
        name: 'examRemark',
      },
    ],
  },
  {
    name: 'teaching',
  },
  {
    name: 'reportMgmt',
    children: [
      {
        name: 'eGrade',
      },
      {
        name: 'comment',
      },
      {
        name: 'report',
      },
      {
        name: 'academicStanding',
      },
    ],
  },
  {
    name: 'monthlyReport',
    children: [
      {
        name: 'assessment',
      },
      {
        name: 'monthlyComment',
      },
      {
        name: 'conduct',
      },
      {
        name: 'report2',
      },
    ],
  },
  {
    name: 'graduation',
  },
  {
    name: 'otherApp',
  },
  {
    name: 'dataForm',
  },
]

const noAuth = {
  2: ['examMgmt'],
  7: ['reportMgmt', 'examMgmt'],
  8: ['examMgmt'],
}
export const pcMenu = {
  default: defaultMenu,
  2: defaultMenu
    .filter(menu => !noAuth[2].includes(menu.name))
    .map(menu => ({
      ...menu,
      children: menu.children
        ? menu.children.filter(child => !noAuth[2].includes(child.name))
        : undefined,
    })),
  7: defaultMenu
    .filter(menu => !noAuth[7].includes(menu.name))
    .map(menu => ({
      ...menu,
      children: menu.children
        ? menu.children.filter(child => !noAuth[7].includes(child.name))
        : undefined,
    })),
  8: defaultMenu
    .filter(menu => !noAuth[8].includes(menu.name))
    .map(menu => ({
      ...menu,
      children: menu.children
        ? menu.children.filter(child => !noAuth[8].includes(child.name))
        : undefined,
    })),
}

const defaultDetail = [
  {
    key: 'student',
    label: 'studentInfo',
  },
  {
    key: 'parent',
    label: 'parentInfo',
  },
  {
    key: 'curriculum',
    label: 'curriculumInfo',
  },
  // {
  //   key: 'teacher',
  //   label: 'teacherInfo',
  // },
  {
    key: 'class',
    label: 'classInfo',
  },
  {
    key: 'subject',
    label: 'subjectInfo',
  },
  {
    key: 'transferInfo',
    label: 'transferInfo',
  },
  {
    key: 'grading',
    label: 'gradeBook',
  },
  {
    key: 'report',
    label: 'report',
  },
  // {
  //   key: 'points',
  //   label: 'points',
  // },
  // {
  //   key: 'honor',
  //   label: 'honor',
  // },
  // {
  //   key: 'leadership',
  //   label: 'leadership',
  // },
  {
    key: 'diary',
    label: 'diary',
    auth: '2012',
  },
  {
    key: 'attendance',
    label: 'attendanceInfo',
    auth: '2008',
  },
  {
    key: 'instruction',
    label: 'instruction',
  },
]
export const detailMenu = {
  default: defaultDetail,
  2: defaultDetail.filter(item => !['points'].includes(item.key)),
  7: defaultDetail.filter(item => !['points', 'report'].includes(item.key)),
  8: defaultDetail.filter(item => !['points'].includes(item.key)),
}

const defaultApp = [
  {
    id: 1,
    label: 'CCAClass',
    value: 'CCAClass',
    icon: 'book',
    color: '#FFBF00',
    isAntIcon: true,
    auth: '2043',
  },
  {
    id: 2,
    value: 'parentsMeeting',
    label: 'parentsMeeting',
    iconClass: 'index-parents-meeting-icon',
    selfIcon: true,
    auth: '2048',
  },
  {
    id: 3,
    label: 'leaveApproval',
    value: 'leaveApproval',
    iconClass: 'leave-approval-icon',
    selfIcon: true,
    auth: '2051',
  },
  {
    id: 4,
    label: 'homework',
    value: 'homework',
    icon: 'pushpin',
    color: '#69C0FF',
    isAntIcon: true,
    auth: '2021',
  },
  {
    id: 5,
    label: 'calendar',
    value: 'calendar',
    icon: 'calendar',
    color: '#FF9C6E',
    isAntIcon: true,
    auth: '2023',
  },
  {
    id: 7,
    label: 'sen',
    value: 'sen',
    iconClass: 'sen-icon',
    selfIcon: true,
    auth: '2069',
  },
  // {
  //   id:6,
  //   label: 'addressBook',
  //   value:'addressBook',
  //   icon: 'solution',
  //   color: '#69C0FF',
  //   isAntIcon: true,
  //   auth: '2094'
  // },
  {
    id: 8,
    label: 'noticeManage',
    value: 'noticeManage',
    icon: 'notification',
    color: '#FFBF00',
    auth: '2095',
  },
]
export const appList = {
  default: defaultApp,
  2: defaultApp.filter(item => !['homework'].includes(item.value)),
  7: defaultApp.filter(item => !['homework'].includes(item.value)),
}

export function createPagination({
  pageSize,
  current,
  defaultCurrent,
  defaultPageSize,
  pageSizeOptions,
  showQuickJumper,
  showSizeChanger,
  showTotal,
}: any) {
  return {
    defaultCurrent: defaultCurrent || 1,
    defaultPageSize: defaultPageSize || 30,
    pageSizeOptions: pageSizeOptions || ['10', '20', '30', '50'],
    showQuickJumper: showQuickJumper || true,
    showSizeChanger: showSizeChanger || true,
    showTotal: showTotal,
    pageSize: pageSize || 30,
    current: current === 0 ? current : current || 1,
    total: 0,
  }
}

export const statusList = [
  'intime',
  'online',
  'late',
  'excusedLate',
  'absent',
  'examAbsent',
  'personal',
  'illness',
  'holiday',
  'exams',
  'others',
]

export const routerRightCode = {
  // 2001: {
  //   name: 'myClass',
  //   children: [
  //     'studentList'
  //   ]
  // },
  2001: 'studentList',
  2002: 'classStudentDetail',
  2003: 'myStudent',
  2004: 'studentDetail',
  2005: 'attendance',
  2006: {
    name: 'attendanceViewer',
    children: ['kindergartenAtten', 'dormitoryAtten', 'studyRoomAtten'],
  },
  2009: 'statViewer',
  2010: 'diary',
  2011: 'diaryEditor',
  2012: 'diaryDetail',
  2013: {
    name: 'message',
    children: ['inbox', 'outbox', 'sendMessage', 'inDetail', 'outDetail', 'replyMessage'],
  },
  2017: 'curriculum',
  2018: 'myCurriculum',
  2019: 'myAdjustCurriculum',
  2021: 'homework',
  2022: 'publishHomework',
  2023: 'calendar',
  2027: 'adjustCurriculum',
  2028: 'adjustApproval',
  2031: 'teacherCurriculum',
  2033: 'classroomCurriculum',
  2035: 'examMgmt',
  2036: 'examPoints',
  2038: 'studentExamPoints',
  2039: 'examRemark',
  2041: 'studentExamPointsRemark',
  2042: {
    name: 'homeworkDetail',
    children: ['studentHomeworkDetail'],
  },
  2043: 'CCAClass',
  2045: 'CCAClassDetail',
  // 2046: 'reportViewer',
  2046: 'attendanceReport',
  2048: 'parentsMeeting',
  2050: 'parentsMeetingDetail',
  2051: 'leaveApproval',
  2054: {
    name: 'courseMaterial',
    children: ['materialInCourse'],
  },
  2055: 'materialEditor',
  2057: 'otherApp',
  2058: 'moment',
  2059: 'momentEditor',
  2060: 'assignment',
  2061: 'assignmentEditor',
  2062: 'assignmentDetail',
  2063: 'grading',
  // 2066: 'kindergartenAtten',
  2069: 'sen',
  2070: 'senEditor',
  2072: 'reportMgmt',
  2073: 'eGrade',
  2075: {
    name: 'comment',
    children: ['headTeacherRemark', 'advisorRemark', 'subjectRemark'],
  },
  2077: 'report',
  2083: 'leaveEditor',
  2085: {
    name: 'teaching',
    children: ['assignmentDetail', 'sAssignmentDetail'],
  },
  2086: {
    name: 'teachingEditor',
    children: ['assignmentEditor', 'materialEditor'],
  },
  2088: {
    name: 'mesStudentDetail',
    children: ['inMesStudentDetail', 'outMesStudentDetail'],
  },
  2090: 'dataForm',
  2091: 'lifeBlockReport',
  2094: {
    name: 'addressBook',
    children: ['manageExternal', 'staffDetail'],
  },
  2095: 'home',
  2096: {
    name: 'notice',
    children: ['noticeViewHome', 'noticeList'],
  },
  2097: {
    name: 'noticeManage',
    children: [
      'contentManagement',
      'noticeEdit',
      // 'noticeView',
      'recycleBin',
    ],
  },
  // 2097: 'noticeManage',

  2100: 'noticeView',
  2101: 'monthlyReport',
  2102: 'assessment',
  2103: 'monthlyComment',
  2104: 'conduct',
  2105: 'report2',
  2111: {
    name: 'academicStanding',
    children: ['academicDetail', 'gradeBook'],
  },
  2116: 'eGradeReport',
  2124: 'gradeReport',
  2200: {
    name: 'graduation',
    children: ['studentInfo'],
  },
}

export const codeByFeature = {
  attendance: 2006,
  attendanceStat: 2009,
  diary: 2010,
  assessment: 2102,
  monthlyComment: 2103,
}

export const waitFor = 3000

const i18nConfig = {
  zh: 'zh',
  en: 'en',
  enGB: 'enGB',
}

export const i18nBySchool = {
  default: i18nConfig,
  2: i18nConfig,
  8: {
    zh: 'zh_CD',
    en: 'en_CD',
    enGB: 'enGB_CD',
  },
}
