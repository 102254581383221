import en from '../KS/en'

export default {
  ...en,
  router: {
    studentHomeworkDetail: 'Student Task Detail',
    homeworkDetail: 'Task Detail',
    publishHomework: 'Publish Task',
    homework: 'Task',
    studentExamPoints: 'Student Exam Score',
    examMgmt: 'Exam',
    examPoints: 'Exam Score',
    examRemark: 'Exam Remark',
    diaryDetail: 'Student Log Detail',
    myStudent: 'Student',
    myClass: 'My Class',
    myMainClass: 'Homeroom',
    myCourseClass: 'Subject Class',
    myHouse: 'Advisory',
    studentDetail: 'Student Detail',
    attendance: 'Attendance',
    studentAttendance: 'Student Attendance',
    dailyAttendance: 'Daily Attendance',
    CCAClassAttendance: 'Life Block',
    subjectClassAttendance: 'Subject Class',
    classAttendance: 'Class Attendance',
    attendanceStat: 'Attendance Analysis',
    dormitoryAtten: 'Dormitory Attendance',
    studyRoomAtten: 'Night Study Attendance',
    sectionStat: 'Grade',
    houseStat: 'Advisory',
    courseClassStat: 'Subject Class',
    classPeriodStat: 'Class Period',
    diary: 'Student Log',
    addDiary: 'Add Student Log',
    editDiary: 'Edit Student Log',
    curriculum: 'Timetable',
    myCurriculum: 'Timetable',
    myAdjustCurriculum: 'Course Adjustment',
    calendar: 'Calendar',
    message: 'Message',
    inbox: 'Inbox',
    outbox: 'Outbox',
    sendMessage: 'Send Message',
    sentDetail: 'Detail',
    replyMessage: 'Reply Message',
    receivedDetail: 'Detail',
    personal: 'Personal',
    systemMessage: 'System Message',
    adjustCurriculum: 'Timetable Adjustment',
    adjustApproval: 'Adjustment Approval',
    teacherCurriculum: 'Teacher Timetable',
    classroomCurriculum: 'Room Timetable',
    otherApp: 'Other',
    CCAClass: 'Life Block',
    CCAClassDetail: 'Life Block Detail',
    parentsMeeting: "Parents' Meeting",
    parentsMeetingDetail: "Parents' Meeting Detail",
    courseMaterial: 'Class Resource',
    leaveApproval: 'Leave Approval',
    addMaterial: 'Add Resource',
    editMaterial: 'Edit Resource',
    dataForm: 'Data Form',
    attendanceReport: 'Attendance Form',
    lifeBlockReport: 'Life Block Form',
    eGradeReport: 'Elementary Grading Form',
    gradeReport: 'Secondary Grading Form',
    reportType: {
      abnormal: 'Absent Form',
      semester: 'Session Attendance Form',
      course: 'Class Attendance Form',
      subjectClass: 'Subject Class Attendance Form',
      absent: 'Missed Register Form',
      cca: 'Life Block Attendance Form',
      selfStudy: 'Night Study Form',
      dormitory: 'Dormitory Attendance Form',
    },
    mainClass: 'Homeroom',
    advisory: 'advisory',
    subjectClass: 'Subject Class',
    moment: 'Moment',
    addMoment: 'Add Moment',
    editMoment: 'Edit Moment',
    gradeStat: 'Grade',
    advisoryStat: 'Advisory',
    subjectClassStat: 'Subject Class',
    assignment: 'Assignment',
    addAssignment: 'Add Assignment',
    editAssignment: 'Edit Assignment',
    assignmentDetail: 'Assignment Detail',
    grading: 'Grading',
    scoreSheet: 'Gradebook',
    editSen: 'Edit SEN',
    addSen: 'Add SEN',
    sen: 'SEN',
    kindergartenAtten: 'Preschool Attendance',
    materialInCourse: 'MaterialInCourse',
    reportMgmt: 'Reporting',
    eGrade: 'Elementary Grading',
    comment: 'Comments',
    report: 'Report',
    headTeacherRemark: 'Tutor',
    advisorRemark: 'Advisor',
    subjectRemark: 'Subject',
    LBRemark: 'Life Block',
    lifeBlockRemark: 'Life Block',
    class: 'Class',
    studentList: 'Student List',
    noAuthAttendance: 'No Auth',
    noAuth: 'No Auth',
    addLeave: 'Add Leave',
    teaching: 'Teaching',
    classPeriod: 'Class Period',
    classStat: 'Class',
    dormitoryStat: 'Dormitory',
    selfStudyStat: 'Night Study',
    addressBook: 'Faculty Directory',
    noticeManage: 'Announcement Management',
    contentManagement: 'Content Management',
    noticeEdit: 'Edit Announcement',
    noticeAdd: 'Add Announcement',
    noticeView: 'Announcement Detail',
    departmentManagement: 'Department Management',
    labelManagement: 'Label Management',
    manageExternalStaff: 'Mange External Staff',
    staffDetail: 'Staff Detail',
    recycleBin: 'Recycle Bin',
    home: 'Home',
    schoolNotice: 'School Announcement',
    noticeList: 'Announcement List',
    monthlyReport: 'Reporting',
    assessment: 'Assessment',
    monthlyComment: 'Comment',
    report2: 'Report',
    conduct: 'Conduct',
    academicStanding: 'Academic Standing',
    academicStandingDetail: 'Academic Standing Detail',
    gradeBook: 'GradeBook',
    deputyHeadRemark: 'Head of Section Remark',
    graduation: 'Upper School Academic Management',
    studentInfo: 'Student Info',
  },
  common: {
    finish: 'Finish',
    batchOperate: 'Batch Operate',
    integral: 'Integral',
    operation: 'Operation',
    textLimit100: 'Limit to 100 characters',
    textLimit50: 'Limit to 50 characters',
    textLimit256: 'Limit to 256 characters',
    textLimit2000: 'Limit to 2000 characters',
    textLimit500: 'Limit to 500 characters',
    selected: 'Selected {total} records',
    submit: 'Submit',
    unsaveConfirm: 'Are you sure leave before content has been saved?',
    clear: 'Clear',
    seq: 'Sequence',
    operations: 'Operations',
    total: 'Total {total} records',
    totalStudent: 'Total {total} students',
    add: 'Add',
    edit: 'Edit',
    delete: 'Delete',
    reset: 'Reset',
    resetPwd: 'Reset Password',
    enter: 'Please enter',
    select: 'Please select',
    confirm: 'Confirm',
    cancel: 'Cancel',
    true: 'Yes',
    false: 'No',
    gender: { male: 'Male', female: 'Female' },
    saveSuccess: 'Save Successfully',
    deleteSuccess: 'Delete Successfully',
    resetSuccess: 'Reset Successfully',
    ignoreSuccess: 'Ignore Successfully',
    save: 'Save',
    all: 'All',
    saveAndAdd: 'Save And Add',
    submitSuccess: 'Submit Successfully',
    terminateSuccess: 'Terminate Successfully',
    adjustSuccess: 'Adjust Successfully',
    attendanceSuccess: 'Set Attendance Successfully',
    view: 'View',
    enterContent: 'Please enter content',
    send: 'Send',
    sendSuccess: 'Send Successfully',
    blankOptions: 'Please check all required fields',
    addSuccess: 'Add Successfully',
    setSuccess: 'Set Successfully',
    batchSelectError: 'Please select students first!',
    publish: 'Publish',
    saveAndPublish: 'Save And Publish',
    pass: 'pass',
    reject: 'reject',
    status: 'Status',
    homeroom: 'Homeroom',
    export: 'Export',
    am: 'Am',
    pm: 'Pm',
    downloadResource: 'Download Class Resource',
    grade: 'Grade',
    advisory: 'Advisory',
    house: 'Advisory',
    subjectClass: 'Subject Class',
    subject: 'Subject Category',
    teacher: 'Teacher',
    operateSuccess: 'Operate Success',
    deleteConfirm: 'Are you sure delete?',
    uploadSuccess: 'Upload Success',
    search: 'Search',
    headTeacher: 'Tutor',
    others: 'Others',
    other: 'Other',
    subjectTeacher: 'Subject Teacher',
    courseName: 'Course Name',
    pageTotal: 'Total {total} Items',
    date: 'Date',
    share: 'share',
    parent: 'Parent',
    student: 'Student',
    studentId: 'Student Id',
    unshared: 'Unshared',
    CCAClass: 'Life Block',
    description: 'Description',
    time: 'Time',
    loadMore: 'Load More...',
    noMore: 'No More Data',
    images: 'Images',
    backToHome: 'Back To Home',
    logout: 'Logout',
    name: 'Name',
    type: 'Type',
    term: 'Term',
    schoolYear: 'School Year',
    level: 'Level',
    enterCont: 'Please enter content',
    score: 'Score',
    comment: 'Comment',
    mark: 'Mark',
    color: 'Color',
    percentage: 'Percentage',
    backToPortal: 'Back To Portal',
    cancelEdit: 'Cancel Edit',
    next: 'Next',
    last: 'Last',
    download: 'Download',
    tutor: 'Advisor',
    rejectReason: 'Reason',
    content: 'Content',
    attachment: 'Attachment',
    campus: 'Campus',
    personName: 'Name',
    createTime: 'Create Time',
    exportStudent: 'Export Student',
    exportCurriculum: 'Export Time Table',
    studentName: 'Student Name',
    fold: 'Fold',
    unfold: 'Unfold',
    creator: 'Creator',
    surname: 'Last Name',
    givenName: 'First Name',
    enName: 'English Name',
    cnName: 'Name',
    preview: 'Preview',
    academicAchieve: 'Academic Achievements',
    learningEfforts: 'Learning Efforts',
    class: 'Class',
    unset: 'unset',
    success: 'Success',
    fail: 'Fail',
    sent: 'Sent',
    completed: 'Completed',
    quickEntry: 'Quick Entry',
    dormitory: 'Dormitory',
    studyRoom: 'Night Study',
    classroom: 'Room',
    remark: 'Remark',
    startTime: 'Start Time',
    endTime: 'End Time',
    reason: 'Reason',
    replyDemo: 'Reply Demo',
    copy: 'Copy',
    tbe: 'TBE',
    customGroup: 'Custom Group',
    lifeBlock: 'Life Block',
    copySuccess: 'Copy Succeeded',
    birthday: 'Birthday',
    average: 'Average',
    operator: 'Operator',
    operationTime: 'Operation Time',
    credit: 'Credit',
    filter: 'Filter',
    moreOperations: 'More',
  },
  diary: {
    selectReceiver: 'Please select receiver',
    selectTime: 'Please select time',
    createTime: 'Create time',
    selectStudents: 'Please select students!',
    pleaseSelect: 'Please select type!',
    student: 'student',
    parent: 'parent',
    teacher: 'teacher',
    deleteConfirm: 'Are you sure delete?',
    description: 'Describe',
    addTeacher: 'Add teacher',
    addIntroduction: 'Add Introduction',
    injuredpartion: 'Injured partion',
    uploadWaring: 'Upload the size of file limits 100m，not more than 10',
    uploadtext: 'Upload file ({total}/10)',
    inputText: 'Please input confirm content',
    receipt: 'Recipient',
    sendEmail: 'Send Message',
    shareParent: 'Share parent platform',
    shareStudent: 'Share student platform',
    accessory: 'Attachment',
    addStudent: 'Add Student',
    relationstudent: 'Linked Student',
    introduction: 'Remark',
    place: 'place',
    time: 'Time',
    achievement: 'achievement',
    attendance: 'attendance',
    className: 'Homeroom',
    consultation: 'consultation',
    countNum: 'Total',
    detention: 'detention',
    dormitory: 'dormitory',
    incident: 'incident',
    medical: 'medical',
    points: 'Points',
    studentNName: 'Name',
    diaryType: 'Student Log Type',
    childType: 'Subtype',
    score: 'Points',
    section: 'Grade',
    class: 'Class',
    house: 'Advisory',
    studentName: 'Name',
    reset: 'Reset',
    open: 'Open',
    fold: 'Fold',
    unFold: 'Unfold',
    shareHeadTeacher: 'Notify Tutors',
    shareTutor: 'Notify Advisors',
    housePoint: 'House Points',
    conductPoint: 'Conduct Points',
    exportDiary: 'Export Student Log',
    recordTime: 'Time',
    schoolYear: 'School Year',
    modifyRecord: 'Modify Record',
    emailNoticeTeacher: 'Email Notice to Teacher',
    modifyRecordText: {
      recordDesc: '@:(diary.description)',
      recordPoint: '@:(diary.points)',
      recordPrimaryType: '@:(diary.diaryType)',
      recordTime: '@:(diary.time)',
      recordType: '@:(diary.childType)',
      changeTo: 'changed [{type}] to [{value}]',
      createDiary:
        '{user} created a diary，type：{type}，time：{time}，description：{desc}，point:{point}',
    },
    sendedTeachers: 'Sended',
    waitForSendTeachers: 'Selected for Send',
  },
  grading: {
    scoreSheet: {
      rules: 'Categories',
      FSRules: 'Term Weights',
      warningTips: 'The percentage must add up to 100%',
      mark: {
        missing: 'Missing',
        incomplete: 'Incomplete',
        late: 'Late',
      },
      finalScore: 'Final Percentage',
      addType: 'Add Type',
      export: 'Export',
      maxScore: 'Max: {score}',
      level: 'Level: {score}',
      notIncludeTips: 'Not included in final percentage',
      hideTips: 'Score are not shown to students',
      systemScore: 'System Score',
      customScore: 'Custom Score',
      studentVisible: 'Student Visible',
      studentInvisible: 'Student Invisible',
      foldStudent: 'Fold student out of class',
      unfoldStudent: 'Show dropped students',
      scoreIn: 'Edit Grades',
      finishScore: 'Save',
      last: 'Previous',
    },
    report: {
      generateReport: 'Generate',
      sendReport: 'Send',
      regenerateReport: 'Regenerate Report',
      downloadReport: 'Download',
      notGenerated: 'Not Generated',
      unpublished: 'Not Send',
      published: 'Sent',
      reportName: 'Report Name',
      generateTime: 'Generate Time',
      editComment: 'Edit Comment',
      sendConfirm: 'The report cannot be modified after sending,are you sure to send?',
      regenerateConfirm: 'Are you sure to regenerate report?',
      sendResult: 'Send Result',
      exportComments: 'Export Comments',
      headteacherComments: 'Tutor Comments',
      advisorComments: 'Advisor Comments',
      subjectTeacherComments: 'Subject Teacher Comments',
      academicAchieve: 'Academic Achieve Table',
      commentProgress: '{type} Progress',
      completeStatus: '{type} Complete Status',
      incompleteAssess: 'Incomplete Assessment',
    },
  },
  eGrade: {
    reportTime: 'Report Time',
    gradeAssessment: 'Grade assessment',
    headteacherRemark: 'Tutor Comments',
    subjectTeacherRemark: 'SubjectTeacher Comments',
    LBRemark: 'LB Comments',
    assessmentTips: 'academic achievement items Completed / All',
    headteacherTips:
      'The Number of people who have filled in comments/Number of people who need to fill in',
    subjectTeacherTips:
      'The Number of people who have filled in comments/Number of people who need to fill in',
    commentTips:
      'The Number of people who have filled in comments/Number of people who need to fill in',
    applyAll: 'Apply All',
    reportPeriodLockedTip:
      'The report period has been locked and cannot be edited. Please contact the administrator to edit it.',
    reportPeriodEndedTip: 'The report period has been ended, only available for viewing',
    reportPeriodNotEndTip:
      'The current reporting period is not over, you can only send the report after the administrator ends it',
    reportPeriodNotLockTip:
      'The current report period is not locked. After the administrator locks the report, the report can be generated and sent',
    reportPeriodNotLockTipCD:
      'The current report period is not locked. After the administrator locks the report, the report can be generated',
    reportPeriodProgress:
      'The report is being generated, you can send and download after all completed, the current progress: {progress}%',
    reportPeriodProgressCD:
      'The report is being generated, you can download after all completed, the current progress: {progress}%',
    graded: 'Graded',
    levelOfMastery: 'Level of Mastery',
    descriptionOfPerformance: 'Description of Performance',
    viewStandard: 'View Standard',
    remark: 'Comments',
    complete: 'Complete',
    academicAchievements: 'Academic Achievements',
    learningEfforts: 'Learning Efforts',
    advisorRemark: 'Advisor Comments',
    subject: 'Subject Class',
    headTeacher: 'Homeroom',
    advisor: 'Advisory',
    lifeBlock: 'Life Block',
    batchApplyConfirm: 'Are you sure to apply all?',
    termReport: 'Term Report',
    yearlyReport: 'Yearly Report',
    progress: 'The progress of assess',
    completed: 'Completed: 0 student | Completed: 1 student | Completed: {num} students',
    incompleted: 'Incompleted: 0 student | Incompleted: 1 student | Incompleted: {num} students',
    academicAchievementsStandard: 'Academic Achievements Standard',
    learningEffortsStandard: 'Learning Efforts Standard',
    exportAssessmentSheet: 'Export Assessments',
    editFirst: 'Please click the Edit button to edit the grade',
  },
  monthlyMgm: {
    courseClass: 'Subject Class',
    setAll: 'Assign grades to all students',
    selectGrade: 'Please select grade',
    tutor: 'Tutor',
    progress: 'The progress of assess',
    progressTip: 'Number of courses completed/all courses requiring assessment',
    title: {
      assess: 'The progress of assess',
      tutor: 'Tutor Comments Complete Status',
      headerTeacher: 'Tutor Comments Complete Status',
      deputyHead: 'Head of Section Comments Complete Status',
      courseTeacher: 'Course Comments Complete Status',
      percentage: 'Percentage',
    },
    singleColumn: {
      tutor: 'Advisory',
      headerTeacher: 'Homeroom',
      deputyHead: 'Campus',
      courseTeacher: 'Course Class',
      percentage: 'Course Class',
    },
    status: {
      assess: 'Assessments Complete Status',
      other: 'Comments Complete Status',
    },
    comments: 'Comments',
    housePoint: 'House Points',
    houseAchievement: 'House Achievement',
    conductPoint: 'Conduct Points',
    behaviourEvent: 'Bhaviour Event',
    point: 'Point',
    edithouse: 'Edit House Points',
    editconduct: 'Edit Conduct Points',
    advisorRemark: 'Tutor Comments',
    export: 'Export Assessment Data',
    month: 'Month',
    selectMonth: 'Please Select Month',
    deputyHead: 'Head of Section',
    courseTeacher: 'Course Comments',
    percentage: 'Percentage',
    name: 'Name',
    grade: 'Grade',
    house: 'Advisory',
    exportConduct: 'Export Conduct',
    schoolYearHousePoint: 'House points for the school year',
    schoolYearConductPoint: 'Conduct points for the school year',
  },
}
