


































import { Component, Vue } from 'vue-property-decorator'
import { RouteRecord } from 'vue-router'
import { getSchoolInfo } from '@/utils/utils'
import cloneDeep from 'lodash/cloneDeep'
import { pcMenu } from '@/constant/constant'
import { whiteList } from '@/router'
import { mapState } from 'vuex'

@Component({
  computed: {
    ...mapState({
      unreadStatus: 'unreadStatus',
    }),
  },
})
export default class PadMenu extends Vue {
  public menuKeys: Array<string> = []
  public menuOpenKeys: Array<string> = []
  public newMessage = 0

  public get subRoutes(): any {
    return this.$store.state.routes[0].children.filter(child => !child.hidden)
  }

  public get routes(): Array<any> {
    const router = this.$route as any
    return router.matched.filter((item: RouteRecord) => item.meta && item.meta.routerName)
  }

  private get realMenus(): any {
    let allMenu = cloneDeep(
      Object.keys(pcMenu).includes(this.schoolId.toString())
        ? pcMenu[this.schoolId]
        : pcMenu.default
    )
    let menuList = allMenu.filter(
      menu =>
        this.userAuths.includes(menu.name) ||
        (this.userAuths.includes(menu.redirect) && menu.redirect) ||
        whiteList.includes(menu.name)
    )
    menuList.forEach(menu => {
      let specialMenu = ['message']
      if (specialMenu.includes(menu.name)) {
        switch (menu.name) {
          case 'message':
            menu.children = [
              {
                name: 'inbox',
                showDot: true,
              },
              {
                name: 'outbox',
              },
            ]
            break
        }
      } else {
        menu.children = (menu.children || []).filter(
          childMenu =>
            this.userAuths.includes(childMenu.name) ||
            (childMenu.redirect && this.userAuths.includes(childMenu.redirect))
        )
      }
    })
    return menuList
  }

  private get userAuths(): any {
    return this.$store.state.routeAuths
  }

  public get schoolId(): number {
    return (getSchoolInfo() || {}).schoolId
  }

  public get menus(): Array<object> {
    return this.subRoutes
  }

  private calcType(name): string {
    const nameTypeList = {
      home: 'home',
      myStudent: 'contacts',
      attendance: 'carry-out',
      diary: 'book',
      curriculum: 'profile',
      message: 'mail',
      examMgmt: 'highlight',
      otherApp: 'appstore',
      courseMaterial: 'file-zip',
      assignment: 'snippets',
      moment: 'copyright-circle',
      reportMgmt: 'container',
      monthlyReport: 'container',
      teaching: 'snippets',
      dataForm: 'fund',
      addressBook: 'index-address-book-icon',
    }
    return nameTypeList[name]
  }

  public created(): void {
    const routes = this.routes
    this.menuOpenKeys = routes[2]
      ? [routes[1].name, routes[0].name]
      : routes[1]
      ? [routes[0].name]
      : []
    this.menuKeys = routes[2] ? [routes[2].name] : routes[1] ? [routes[1].name] : [routes[0].name]
  }

  public clickTopMenu({ key }: { key: string }): void {
    this.$router.push({ name: key }).catch(err => {})
  }
}
