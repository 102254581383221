

















import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import store from '@/store/index'

@Component
export default class SystemUpdate extends Vue {
  @Prop() private tips!: any
  @Prop() private enTips!: any

  private store = store
  private locale = store.state.locale || 'zh'

  @Watch('store.state.locale', { deep: true })
  private onStateChange(val) {
    this.locale = val
  }
}
