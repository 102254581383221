












































































































































































































































import { Component, Inject, Vue, Watch } from 'vue-property-decorator'
import {
  setLocale,
  removeToken,
  removeSchoolInfo,
  getSchoolInfo,
  setSchoolInfo,
  getLocale,
  removeQRcode,
  getQRcode,
  setQRcode,
} from '@/utils/utils'
import { RouteRecord } from 'vue-router'
import { LoginController, SemesterController, MessageController } from '@/services/request.service'
import PCMenu from '@/components/PCMenu.vue'
import PadMenu from '@/components/PadMenu.vue'
import MenuLevel3 from '@/components/MenuLevel3.vue'
import moment from 'moment'
import { ssoUtils } from 'sso-utils'
import debounce from 'lodash/debounce'
import { versionUpdateTip } from '@/components/version'
import { staticData } from '@/constant/config'
import SystemUpdate from '@/components/SystemUpdate.vue'
import qrCode from 'qrcode'
// import vueQr from 'vue-qr'
import html2canvas from 'html2canvas'
import { usePush } from 'vue-baidu-analytics'

@Component({
  components: {
    PCMenu,
    PadMenu,
    MenuLevel3,
    // vueQr
  },
})
export default class Main extends Vue {
  @Inject() private readonly reloadPage!: any
  private schoolVisible = false
  private schoolList: Array<any> = []
  private selectedSchool: any = {
    schoolId: 0,
    name: '',
  }
  private resizeEvent: any = null
  private feedBackVis = false
  private sMessageNum = 0
  private collapsed = false
  private mail = staticData.feedbackMail
  private newMesInterval: any = 5
  private mesInfo: any = null
  private moment = moment
  private qrcodeImg: string = ''
  private imageUrl = require('@/assets/images/logo_icon.png')
  private loading: Boolean = false
  private baidu: any = usePush()

  @Watch('$route', { immediate: true })
  private onRouteChanged(to: any, from: any) {
    if (to.path === '/class') return
    this.getMessageStatus()
    this.getSystemMessage()
    const schoolInfo = getSchoolInfo()
    if (schoolInfo) {
      this.selectedSchool = schoolInfo
    } else {
      this.logout()
    }
  }

  private get homeSchoolName(): string {
    if (this.$route.query.schoolId === 'all') {
      return 'all'
    } else {
      if (
        this.schoolList.filter(school => school.key.toString() === this.$route.query.schoolId)
          .length
      ) {
        return this.schoolList.filter(
          school => school.key.toString() === this.$route.query.schoolId
        )[0].value
      } else {
        return 'all'
      }
    }
  }

  private get contentHeaderShow(): boolean {
    const route = this.routes[this.routes.length - 1] || {}
    if ((route.meta || {}).noChild) {
      return false
    } else {
      return true
    }
  }

  private get routes(): Array<any> {
    const router = this.$route as any
    return router.matched.filter((item: RouteRecord) => item.meta && item.meta.routerName)
  }

  private get isPad(): boolean {
    // return document.body.clientWidth < 1440
    return navigator.userAgent.indexOf('iPad') !== -1
  }

  private get locale(): any {
    return this.$store.state.locale
  }

  private get userName(): string {
    return this.$store.state.username || ''
  }

  private checkSystemStatus(): void {
    MessageController.sysMessageNotice().then(res => {
      let { mesId, message, enMessage } = res.data
      let systemMesId = localStorage.getItem('systemMesId')
      if (mesId === null || systemMesId === mesId?.toString()) return
      if (this.mesInfo) this.mesInfo()
      let systemUpdate = this.$createElement(SystemUpdate, {
        props: {
          tips: message,
          enTips: enMessage,
        },
        on: {
          close: () => {
            localStorage.setItem('systemMesId', mesId.toString())
            this.mesInfo()
            this.mesInfo = null
          },
        },
      })
      this.mesInfo = this.$message.warning(systemUpdate, 0)
    })
  }

  private created(): void {
    this.getUserInfo()
    this.getSchoolList()
    this.getCurrentSchoolYear()
    this.getQRcode()
    const locale = getLocale()
    this.$store.commit('changeLocale', locale)
  }

  private getUserInfo(): void {
    LoginController.getUserInfo({ headers: { 'X-Request-For': '2006' } })
      .then(res => {
        this.$store.commit('setUserInfo', {
          username: (res.data.enName + ' ' + res.data.name).trim(),
          memberId: res.data.teacherId,
          avatar: res.data.avatarUrl,
          userAuths: res.data.dataPermission || {},
        })
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        if (this.$store.state.refresh) {
          this.$store.commit('cleanRefresh')
          this.$router.replace({ name: 'main' }).catch(err => {})
        }
      })
  }

  private getMessageStatus(): void {
    Promise.all([MessageController.hasUnread(), MessageController.unreadSystemMessage()])
      .then(res => {
        this.$store.commit('setUnreadStatus', res[0].data)
        this.$store.commit('setSystemUnreadNum', res[1].data)
      })
      .catch(err => {
        console.error(err)
      })
  }

  private getCurrentSchoolYear(): void {
    SemesterController.getCurrentSchoolYear()
      .then(res => {
        let currentSchoolYear = {
          ...res.data,
          startTime: moment(res.data.startTime),
          endTime: moment(res.data.endTime),
        }
        this.$store.commit('setCurrentSchoolYear', currentSchoolYear)
      })
      .catch(err => console.error(err))
  }

  private getSchoolList(): void {
    this.selectedSchool = getSchoolInfo()
    LoginController.getSchools()
      .then(res => {
        this.schoolList = res.data
        this.selectedSchool = res.data.filter(
          school => school.schoolId === this.selectedSchool.schoolId
        )[0]
      })
      .catch(err => {
        console.error(err)
      })
  }

  private getSystemMessage(): void {
    MessageController.hasUnreadSystem()
      .then(res => {
        let systemMessage = {
          approval: res.data.Approval,
          favor: res.data.Favor,
          notice: res.data.System,
        }
        let count = 0
        for (let k in systemMessage) {
          count += systemMessage[k]
        }
        this.$store.commit('setSystemMessageNum', systemMessage)
        this.sMessageNum = count
      })
      .catch(err => console.log(err))
  }

  private getNewMessageNum(): void {
    MessageController.unReadMessageCount(this.newMesInterval).then(res => {
      const newMes = res.data as any
      let flag = false
      Object.keys(newMes).forEach(key => {
        if (newMes[key]) flag = true
      })
      if (flag) {
        this.$notification.info({
          message: this.$tc('message.newMessageNotice'),
          description:
            this.$t('message.receivedMessage') +
            (newMes.system
              ? '\n' + this.$tc('message.systemMessage', newMes.system, { num: newMes.system })
              : '') +
            (newMes.normal
              ? '\n' + this.$tc('message.normalMessage', newMes.normal, { num: newMes.normal })
              : ''),
          style: {
            whiteSpace: 'pre',
          },
          class: 'new-mes-info',
          duration: 30,
          onClick: () => {
            this.toInbox()
          },
        })
      }
    })
  }

  private viewSystemMessage(): void {
    this.$router.push({ name: 'systemMessage' }).catch(err => {})
  }

  private openPersonal(): void {
    this.$router.push({ name: 'personal' }).catch(err => {})
  }

  private changeLocale(lan): void {
    const currentLocale = getLocale()
    // if (currentLocale === 'zh') {
    //   this.$i18n.locale = 'en'
    //   this.$store.commit('changeLocale', 'en')
    //   setLocale('en')
    // } else if (currentLocale === 'en') {
    //   this.$i18n.locale = 'enGB'
    //   this.$store.commit('changeLocale', 'enGB')
    //   setLocale('enGB')
    // } else {
    //   this.$i18n.locale = 'zh'
    //   this.$store.commit('changeLocale', 'zh')
    //   setLocale('zh')
    // }
    if (currentLocale === lan) return
    this.$store.commit('changeLocale', lan)
    setLocale(lan)
  }

  private selectSchool({ key }): void {
    const schoolList = this.schoolList
    const selectedSchool = schoolList.filter(school => school.schoolId === key)[0]
    this.selectedSchool = selectedSchool
    this.$store.commit('refresh')
    this.$store.commit('changeForceBack', true)
    this.$store.dispatch('refreshRoutes')
    setSchoolInfo(selectedSchool || {})
    // const router = this.$router as any
    // router.$addRoutes()
    // this.reloadPage()
  }

  private clickTopMenu({ key }: { key: string }): void {
    this.$router.push({ name: key }).catch(err => {})
  }

  private logout(): void {
    removeToken()
    removeSchoolInfo()
    removeQRcode()
    this.$store.commit('changeForceBack', true)
    this.$store.dispatch('refreshRoutes')
    this.$router.push({ name: 'login' }).catch(err => {})
  }

  private redirectPortal(): void {
    removeToken()
    ssoUtils.redirectPortal()
  }

  private get loginSource(): string {
    return ssoUtils.getLoginSource() || ''
  }

  private get schoolId(): number {
    return (getSchoolInfo() || {}).schoolId
  }

  private get schoolName(): number {
    return (getSchoolInfo() || {}).name
  }

  private existQRcode(): any {
    return getQRcode()
  }

  private expired(): any {
    const today = moment().valueOf()
    return today > this.existQRcode()?.expirationTime
  }

  private generateQRcode(rand, teacherId): any {
    let env = process.env.VUE_APP_ENV as string
    let schoolId = getSchoolInfo().schoolId

    let domainList = {
      DEV: 'http://192.168.60.100:3012',
      QA: 'http://192.168.60.100:3012',
      UAT: 'http://122.96.107.58:4011',
      PRODUCTION: 'https://mobile.intschool.cn',
    }

    let targetUrl = `${domainList[env]}/login/${rand}/${schoolId}`
    qrCode
      .toDataURL(targetUrl)
      .then(imgurl => {
        // this.loading = false
        this.qrcodeImg = imgurl
      })
      .catch(err => {
        console.error(err)
      })
  }

  private addQRcode(): any {
    this.loading = true
    LoginController.addQRCode()
      .then(res => {
        setQRcode(res.data)
        this.generateQRcode(res.data.rand, res.data.teacherId)
      })
      .catch(err => console.log(err))
      .finally(() => (this.loading = false))
  }

  private downloadQRcode(): any {
    this.baidu.event(this.schoolName, '下载二维码', this.userName)
    const dom = document.querySelector('.qr-container')
    html2canvas(dom as HTMLElement).then(canvas => {
      const imgURL = canvas.toDataURL()
      let a = document.createElement('a')
      a.href = imgURL
      a.download = this.$tc('qrcode.qrcodeTitle')
      a.click()
      a.remove()
    })
  }

  private toHelp(): void {
    const url = this.schoolId === 8 ? staticData.helpDocUrlCD : staticData.helpDocUrl
    window.open(url)
  }

  private getQRcode(): any {
    LoginController.getQRCode()
      .then(res => {
        if (res.data.rand) {
          setQRcode(res.data)
        } else {
          removeQRcode()
        }
      })
      .catch(err => removeQRcode())
  }

  private mounted() {
    this.resizeEvent = debounce(this.onResize, 100)
    let ratio = window.innerWidth / 1920
    this.$store.commit('setRatio', ratio)
    window.addEventListener('resize', this.resizeEvent)

    // 定时轮询消息状态
    let timer = setInterval(() => {
      this.getMessageStatus()
    }, this.newMesInterval * 60 * 1000)

    // 定时轮询新消息
    let newMesTimer = setInterval(() => {
      this.getNewMessageNum()
    }, this.newMesInterval * 60 * 1000)

    this.checkSystemStatus()
    let checkTimer = setInterval(() => {
      this.checkSystemStatus()
    }, 60 * 60 * 1000)

    this.$once('hook:beforeDestroy', () => {
      if (this.mesInfo) this.mesInfo()
      clearInterval(timer)
      clearInterval(checkTimer)
      clearInterval(newMesTimer)
      window.removeEventListener('resize', this.resizeEvent)
    })
    // versionUpdateTip(this.$createElement)
    if (this.existQRcode()) {
      this.generateQRcode(this.existQRcode()?.rand, this.existQRcode()?.teacherId)
    }
  }

  private onResize(): void {
    let ratio = window.innerWidth / 1920
    this.$store.commit('setRatio', ratio)
  }

  private toInbox(): void {
    if (this.$route.matched.find(item => item.name === 'inbox')) return
    this.$router.push({ name: 'inbox', params: { mesType: 'normal' } })
  }
}
