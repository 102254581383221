import { fn } from 'moment'

export default {
  platformName: '教师平台',
  changeLocale: 'Switch Language',
  applyAll: '应用到全部报告期',
  isApplyAll: '确认应用到全部报告期？',

  staticCode: {
    1201: '未开始',
    1202: '进行中',
    1203: '已结束',
    1204: '上学期',
    1205: '下学期',
    1208: '未开启',
    1209: '进行中',
    1210: '已毕业',
    //---- CCA状态码start -----//
    1001: '未开始',
    1002: '报名中',
    1003: '报名结束',
    1004: '已发布',
    1011: '每周重复',
    1012: '隔周重复',
    1013: '只一次',
    //---- CCA状态码end -----//

    // 学生状态
    1014: '在校生',
    1015: '已毕业',
    1016: '已转学',
    1017: '待入学',
    1018: '转学中',
  },
  relation: {
    father: '爸爸',
    mother: '妈妈',
    patGrandPa: '爷爷',
    patGrandMa: '奶奶',
    matGrandPa: '外公',
    matGrandMa: '外婆',
    other: '其他',
  },
  router: {
    studentHomeworkDetail: '学生任务',
    homeworkDetail: '任务详情',
    publishHomework: '发布任务',
    homework: '任务',
    studentExamPoints: '学生考试成绩',
    examMgmt: '考试',
    examPoints: '考试成绩',
    examRemark: '考试评语',
    diaryDetail: '日记簿详情',
    myStudent: '学生',
    myClass: '我的课堂',
    myMainClass: '主班级',
    myCourseClass: '课程班级',
    myHouse: '学院',
    studentDetail: '学生详情',
    attendance: '考勤',
    studentAttendance: '学生考勤',
    dailyAttendance: '每日考勤',
    subjectClassAttendance: '课堂考勤',
    CCAClassAttendance: '延展课程考勤',
    classAttendance: '班级考勤',
    attendanceStat: '考勤统计',
    dormitoryAtten: '宿舍考勤',
    studyRoomAtten: '自习室考勤',
    sectionStat: '年级出勤率',
    houseStat: '学院出勤率',
    courseClassStat: '课堂出勤率',
    classPeriodStat: '课节出勤率',
    dormitoryStat: '宿舍出勤率',
    classStat: '班级出勤率',
    selfStudyStat: '自习室出勤率',
    diary: '日记簿',
    addDiary: '新增日记簿',
    editDiary: '编辑日记簿',
    curriculum: '课表',
    myCurriculum: '我的课表',
    myAdjustCurriculum: '我的调课',
    calendar: '校历',
    message: '消息通知',
    inbox: '收件箱',
    outbox: '发件箱',
    sendMessage: '发消息',
    sentDetail: '消息详情',
    replyMessage: '回复消息',
    receivedDetail: '消息详情',
    personal: '个人中心',
    systemMessage: '系统消息',
    adjustCurriculum: '调课管理',
    adjustApproval: '调课审批',
    teacherCurriculum: '教师课表',
    classroomCurriculum: '教室课表',
    otherApp: '其他应用',
    CCAClass: '延展课程',
    CCAClassDetail: '查看延展课程',
    parentsMeeting: '家长会',
    parentsMeetingDetail: '查看家长会',
    courseMaterial: '课程资料',
    leaveApproval: '请假审批',
    addMaterial: '新增课程资料',
    editMaterial: '编辑课程资料',
    dataForm: '数据报表',
    attendanceReport: '考勤报表',
    lifeBlockReport: '延展课程报表',
    eGradeReport: '小学评估报表',
    gradeReport: '中学成绩报表',
    reportType: {
      abnormal: '异常考勤报表',
      semester: '课期考勤报表',
      course: '课堂考勤报表',
      subjectClass: '课程班级考勤报表',
      absent: '缺考勤报表',
      cca: '延展课程考勤报表',
      selfStudy: '自习室考勤报表',
      dormitory: '宿舍考勤报表',
    },
    advisory: '学院',
    subjectClass: '课程班级',
    mainClass: '主班级',
    moment: '班级圈',
    editMoment: '编辑班级圈',
    addMoment: '新增班级圈',
    gradeStat: '年级出勤率',
    advisoryStat: '学院出勤率',
    subjectClassStat: '课堂出勤率',
    assignment: '任务',
    addAssignment: '新增任务',
    editAssignment: '编辑任务',
    assignmentDetail: '任务详情',
    grading: '成绩',
    scoreSheet: '成绩册',
    report: '报告',
    editSen: '编辑Sen',
    addSen: '新增Sen',
    sen: 'SEN',
    kindergartenAtten: '幼儿考勤',
    materialInCourse: '班级课程资料详情',
    reportMgmt: '报告管理',
    eGrade: '小学评估',
    comment: '评语',
    headTeacherRemark: '班主任评语',
    advisorRemark: 'Advisor评语',
    subjectRemark: '科目教师评语',
    LBRemark: '延展课程评语',
    lifeBlockRemark: '延展课程评语',
    class: '班级',
    studentList: '学生列表',
    noAuthAttendance: '无权限',
    noAuth: '无权限',
    addLeave: '新增请假',
    teaching: '教学',
    classPeriod: '课节出勤率',
    addressBook: '校园通讯录',
    noticeManage: '公告管理',
    contentManagement: '内容管理',
    noticeEdit: '编辑通知',
    noticeAdd: '新增通知',
    noticeView: '通知详情',
    departmentManagement: '部门管理',
    labelManagement: '标签管理',
    manageExternalStaff: '管理外部人员',
    staffDetail: '人员详情',
    recycleBin: '回收站',
    home: '首页',
    schoolNotice: '校园公告',
    noticeList: '公告列表',
    monthlyReport: '报告管理',
    assessment: '评估',
    monthlyComment: '评语',
    report2: '报告',
    conduct: '品行',
    academicStanding: '学术水平',
    academicStandingDetail: '学术水平详情',
    gradeBook: '成绩册',
    deputyHeadRemark: '副校长评语',
    graduation: '高中学业管理',
    studentInfo: '学生信息',
  },
  login: {
    userLogin: '用户登录',
    account: '账号',
    password: '密码',
    enterAccount: '请输入账号',
    enterPassword: '请输入密码',
    loginAuto: '7天内自动登录',
    forgetPassword: '忘记密码',
    login: '登录',
    retrievePassword: '重置密码',
    next: '下一步',
    backToLogin: '返回登录',
    tips:
      '推荐使用谷歌浏览器及最新版Edge。兼容Safari。不推荐使用分辨率低于1366*768的电脑及手机查看。不兼容IE浏览器。',
  },
  common: {
    finish: '完成',
    batchOperate: '批量操作',
    enterCont: '请输入内容',
    integral: '整数',
    operation: '操作',
    textLimit100: '限制100字以内',
    textLimit256: '限制256字以内',
    textLimit50: '限制50字以内',
    textLimit2000: '限制2000字以内',
    textLimit500: '限制500字以内',
    selected: '已选择 {total} 条',
    submit: '提交',
    unsaveConfirm: '内容未保存,确认退出?',
    clear: '清空',
    seq: '序号',
    operations: '操作',
    total: '共 {total} 条记录',
    pageTotal: '共 {total} 条',
    totalStudent: '共 {total} 名学生',
    add: '新增',
    edit: '编辑',
    delete: '删除',
    reset: '重置',
    resetPwd: '重置密码',
    enter: '请输入',
    select: '请选择',
    confirm: '确定',
    cancel: '取消',
    true: '是',
    false: '否',
    gender: {
      male: '男',
      female: '女',
    },
    saveSuccess: '保存成功',
    deleteSuccess: '删除成功',
    resetSuccess: '重置成功',
    ignoreSuccess: '忽略成功',
    save: '保存',
    all: '全部',
    saveAndAdd: '保存并新增',
    submitSuccess: '提交成功',
    terminateSuccess: '终止成功',
    adjustSuccess: '调课成功',
    attendanceSuccess: '考勤成功',
    view: '查看',
    enterContent: '请输入内容',
    send: '发送',
    sendSuccess: '发送成功',
    blankOptions: '请检查所有必填项',
    addSuccess: '添加成功',
    setSuccess: '设置成功',
    batchSelectError: '请先选择学生，再进行批量操作!',
    publish: '发布',
    saveAndPublish: '保存并发布',
    pass: '通过',
    reject: '拒绝',
    status: '状态',
    export: '导出',
    am: '上午',
    pm: '下午',
    downloadResource: '下载课程资料',
    homeroom: '主班级',
    grade: '年级',
    advisory: '学院',
    house: '学院',
    subjectClass: '课程班级',
    subject: '科目',
    teacher: '教师',
    operateSuccess: '操作成功',
    deleteConfirm: '确定要删除吗?',
    uploadSuccess: '上传成功',
    search: '查询',
    headTeacher: '班主任',
    others: '其他',
    other: '其他',
    subjectTeacher: '科目教师',
    date: '日期',
    share: '分享',
    parent: '家长',
    student: '学生',
    studentId: '学号',
    unshared: '未分享',
    CCAClass: '延展课程',
    description: '描述',
    time: '时间',
    loadMore: '加载更多...',
    noMore: '没有更多数据了',
    images: '图片',
    backToHome: '返回首页',
    logout: '退出登录',
    name: '名称',
    personName: '姓名',
    type: '类型',
    term: '学期',
    schoolYear: '学年',
    level: '等级',
    score: '成绩',
    comment: '评语',
    mark: '标记',
    color: '颜色',
    percentage: '百分比',
    backToPortal: '回到首页',
    cancelEdit: '取消编辑',
    next: '下一个',
    last: '上一个',
    download: '下载',
    tutor: '导师',
    rejectReason: '拒绝原因',
    content: '内容',
    attachment: '附件',
    campus: '学部',
    createTime: '创建时间',
    exportStudent: '导出学生',
    exportCurriculum: '导出课表',
    studentName: '学生姓名',
    fold: '折叠',
    unfold: '展开',
    creator: '创建人',
    surname: '姓',
    givenName: '名',
    enName: '英文名',
    cnName: '中文名',
    preview: '预览',
    academicAchieve: '学术成就',
    learningEfforts: '学习品行',
    class: '班级',
    unset: '未设置',
    success: '成功',
    fail: '失败',
    sent: '已发送',
    completed: '已完成',
    quickEntry: '快捷入口',
    dormitory: '宿舍',
    studyRoom: '自习室',
    classroom: '教室',
    remark: '备注',
    startTime: '开始时间',
    endTime: '结束时间',
    reason: '原因',
    replyDemo: '回复模板',
    copy: '复制',
    tbe: '待入学',
    customGroup: '自定义分组',
    lifeBlock: '延展课程',
    copySuccess: '复制成功',
    birthday: '生日',
    average: '平均分',
    operator: '操作人',
    operationTime: '操作时间',
    credit: '学分',
    filter: '筛选',
    moreOperations: '更多',
    withdrawPass: '撤销通过',
  },
  main: {
    quit: '退出',
    helpDoc: '帮助文档',
    feedback: '意见反馈',
    feedbackTips: '使用过程中若遇到技术问题， 请发送邮件到学校邮箱：',
  },
  myClass: {
    normal: '在校生',
    WFE: '待入学',
    1017: '待入学,入学日期：{date}',
    1018: '转学中,最后上课日期：{date}',
    transfer: '校友',
    lastDate: '最后上课日期',
    transferDate: '转学时间',
    enrollment: '入学时间',
    transfered: '已转学',
    transfering: '转学中',
    mainClass: '主班级',
    courseClass: '课程班级',
    house: '学院',
    CCAClass: '延展课程',
    section: '年级',
    subject: '科目',
    sendMessage: '发消息',
    name: '姓名',
    studentId: '学号',
    selectSection: '请先选择年级',
    selectSubject: '请先选择科目',
    addDiary: '新增日记',
    studentName: '学生姓名',
    clear: '清空',
    todayCourse: '今日课程',
    allCourse: '全部班级',
    student: {
      points: '成绩',
      studentInfo: '学生信息',
      parentInfo: '家长信息',
      curriculumInfo: '课表信息',
      teacherInfo: '教师信息',
      classInfo: '班级信息',
      subjectInfo: '课程信息',
      diary: '日记簿',
      attendanceInfo: '考勤信息',
      weeklyAttendance: '每周考勤',
      attendanceAnalysis: '个人考勤统计',
      instruction: '说明',
      chineseName: '姓名（中文）',
      englishName: '英文名',
      firstName: '名（拼音）',
      lastName: '姓（拼音）',
      gender: '性别',
      birthday: '出生日期',
      idType: '证件类型',
      idNumber: '证件号码',
      nationality: '国籍（以护照为准）',
      email: '邮箱',
      studentEmail: '学生邮箱',
      parentEmail: '家长邮箱',
      CopyStudentEmail: '复制学生邮箱',
      CopyParentEmail: '复制家长邮箱',
      residentialAddress: '居住地址',
      address: '详细地址',
      section: '年级',
      class: '班级',
      studentId: '学号',
      house: '学院',
      startYear: '入学年份',
      departureTime: '离校时间',
      schoolBus: '校车',
      route: '路线',
      placeToMeet: '迎接地点',
      accommodation: '住宿',
      platformLoginRight: '平台登录权限',
      loginAccount: '登录账号',
      password: '密码',
      resetPassword: '重置密码',
      siblingsInfo: '兄弟姐妹信息',
      childName: '孩子姓名',
      firstParent: '主家长信息',
      secondParent: '第二家长信息',
      parentChineseName: '家长/监护人姓名',
      parentEnglishName: '家长/监护人英文名',
      mobile: '移动电话',
      relation: '与学生关系',
      employer: '工作单位',
      position: '职位',
      subject: '科目',
      courseClass: '课程班级',
      teacher: '教师',
      teacherType: '教师类型',
      teacherName: '教师姓名',
      medical: '医疗',
      diet: '饮食',
      national: '民族',
      scholarship: '奖学金',
      media: '媒体',
      totalCourse: '本周{total}节课',
      point: '分数',
      identification: '身份证',
      passport: '护照',
      other: '其他',
      idTypeList: {
        '1260': '@:(myClass.student.identification)',
        '1261': '@:(myClass.student.passport)',
        '1262': '@:(myClass.student.other)',
      },
      grading: '成绩',
      showLabel: '显示标签',
      admissionTime: '入学时间',
      report: '报告',
      finalScore: '最终成绩',
      reportName: '报告名称',
      gradePeriod: '报告期',
      sent: {
        true: '已发送',
        false: '未发送',
      },
      gradeBook: '成绩册',
      eveningStudyRoom: '自习室',
      dormitory: '宿舍',
      courseAttenStat: '课程班级考勤统计',
      reportType: '报告类型',
      reportTypeList: {
        midTerm: '期中报告',
        endTerm: '期末报告',
        fullSchoolYear: '学年报告',
        monthly: '月度报告',
        longTerm: '长报告',
        exam: '考试结果报告',
      },
      curriculum: '课程',
      classType: '班级类型',
      className: '班级名称',
      classTypeEnum: {
        '1251': '主班级',
        '1253': '学院',
        '1256': '自习室',
        '1257': '宿舍',
      },
      outClass: '已退班',
    },
    selectWeek: '请选择需要导出的课表日期',
    highestQlfctn: '最高学历',
    graduationInstitution: '毕业院校',
    weChat: '微信号',
  },
  adjustCurriculum: {
    teacher: '教师',
    classroom: '教室',
    subject: '科目',
    teacherName: '姓名',
    findFreeTeacher: '查找空闲教师',
    viewCourse: '查看课节',
    teacherTotal: '本周{total}节课，代课{substitute}节',
    classroomTotal: '本周{total}节课',
    findFreeClassroom: '查找空闲教室',
    find: '查找',
    time: '时间',
    freePerWeek: '每周空闲时间',
    selectTime: '请选择时间',
    day: '{day}天',
    hour: '{hour}小时',
    min: '{min}分钟',
    applyTime: '申请时间',
    status: '状态',
    addAdjust: '新增调课',
    createTime: '创建时间',
    adjust: '调课',
    terminate: '终止',
    statusList: {
      waitingForAudit: '待审批',
      rearranged: '已调课',
      terminated: '已终止',
      cancelled: '已取消',
      expired: '已失效',
    },
    applyAdjust: '申请调课',
    courseTotal: '包含 {total} 课节',
    courseTime: '课程时间',
    courseClass: '课程班级',
    section: '年级',
    submitApplication: '提交申请',
    substituteTeacher: '代课教师',
    applyTeacher: '申请教师',
    addTeacher: '添加教师',
    addSubstituteTeacher: '添加代课教师',
    selectTeacher: '请给所有课节选择代课教师',
    selected: '已选',
    noCourse: '所选时间没有课程，请重新选择',
    adjustDetail: '调课详情',
    editAdjust: '编辑调课',
    duplicateTime: '您已提交{period}的调课申请，与本次申请时间重叠，请修改后重新提交',
    noRecords: '当前选择的时间段内没有课程班级，请修改后重试',
    uploadResource: '上传代课资料',
    upload: '上传',
    rejectConfirmTitle: '拒绝调课后,课程将继续由原来教师授课,确认终止?',
    cancelConfirm: '确认取消调课?',
    cancel: '取消调课',
    noAdjustCourse: '无可调课课程',
  },
  attendance: {
    attendance: '出勤',
    dailyAttendance: '每日考勤',
    classAttendance: '班级考勤',
    subjectClassAttendance: '课堂考勤',
    CCAClassAttendance: '延展课程考勤',
    dormitoryAttendance: '宿舍考勤',
    studyRoomAttendance: '自习室考勤',
    CCAClass: '延展课程',
    sectionStat: '年级出勤率',
    houseStat: '学院出勤率',
    courseClassStat: '课堂出勤率',
    date: '日期',
    section: '年级',
    mainClass: '主班级',
    name: '姓名',
    studentId: '学号',
    amAttendance: '上午出勤',
    amRemark: '上午备注',
    pmAttendance: '下午出勤',
    pmRemark: '下午备注',
    house: '学院',
    period: '课节',
    courseClass: '课程班级',
    class: '班级名称',
    lastAttendance: '上次考勤',
    currentAttendance: '本次考勤',
    remark: '备注',
    onTime: '出席',
    selectSection: '请先选择年级',
    statusList: {
      intime: '出席',
      late: '迟到',
      excusedLate: '迟到(可谅解)',
      absent: '缺席',
      examAbsent: '缺考',
      personal: '缺席(可谅解)',
      illness: '病假',
      holiday: '假期',
      exams: '考试',
      others: '其他',
      weekendHoliday: '假期',
      noRecords: '未考勤',
      online: '在线',
    },
    shortStatus: {
      intime: 'P',
      late: 'L',
      absent: 'A',
      personal: 'EA',
      examAbsent: 'MA',
      illness: 'S',
      holiday: 'V',
      exams: 'E',
      others: 'O',
      noRecords: '--',
      online: 'OL',
    },
    rate: '出勤率',
    statName: '名称',
    total: '总计',
    subject: '科目',
    addRemark: '添加备注',
    remarkLimit: '备注字数超出限制',
    detailTitle: '{name}的考勤',
    statDetailTitle: '{key}详情',
    batchTip: '确认将未考勤学生设置为出席?',
    detailTip1:
      '出席：P，在线：OL，迟到：L，迟到（可谅解）：EL，缺席：A，缺席（可谅解）：EA，缺考：MA，病假：S，假期：V，考试：E，其他：O',
    detailTip2: '将鼠标移到图标上查看详细信息',
    dailyPeriod: '课期',
    classPeriod: '课堂',
    editAttendance: '编辑考勤状态',
    weekViewer: '查看周视图',
    dayViewer: '查看日视图',
    session: '课期',
    course: '课堂',
  },
  diary: {
    selectReceiver: '请选择收件人',
    selectTime: '请选择时间！',
    createTime: '创建时间',
    selectStudents: '请选择学生!',
    pleaseSelect: '请选择类型',
    student: '学生',
    parent: '家长',
    teacher: '老师',
    deleteConfirm: '确定要删除吗?',
    description: '描述',
    addTeacher: '添加教师',
    addIntroduction: '添加描述',
    injuredpartion: '受伤部位',
    uploadtext: '上传文件 ({total}/10)',
    uploadWaring: '上传附件限制100M以内，最多添加10个附件',
    inputText: '请输入通知内容',
    receipt: '收件人',
    sendEmail: '发送站内信',
    shareParent: '分享家长平台',
    shareStudent: '分享学生平台',
    accessory: '附件',
    addStudent: '添加学生',
    relationstudent: '关联学生',
    introduction: '描述',
    place: '地点',
    time: '时间',
    unFold: '展开',
    fold: '折叠',
    achievement: '成就',
    attendance: '考勤异常',
    className: '主班级',
    consultation: '顾问咨询',
    countNum: '数量',
    detention: '罚时',
    dormitory: '宿舍',
    incident: '事件',
    medical: '护士来访',
    points: '分数',
    studentNName: '姓名',
    diaryType: '日记簿类型',
    childType: '子类型',
    score: '分数',
    section: '年级',
    class: '班级',
    house: '学院',
    studentName: '学生姓名',
    reset: '重置',
    open: '展开',
    shareHeadTeacher: '通知班主任',
    shareTutor: '通知导师',
    housePoint: '学院分',
    conductPoint: '行为分',
    exportDiary: '导出日记簿',
    recordTime: '发生时间',
    schoolYear: '学年',
    modifyRecord: '修改记录',
    emailNoticeTeacher: '邮件通知教师',
    modifyRecordText: {
      recordDesc: '@:(diary.description)',
      recordPoint: '@:(diary.points)',
      recordPrimaryType: '@:(diary.diaryType)',
      recordTime: '@:(diary.time)',
      recordType: '@:(diary.childType)',
      changeTo: '更改【{type}】为【{value}】',
      createDiary:
        '{user} 创建了一条日记簿，类型：{type}，发生时间：{time}，描述：{desc}，分数:{point}分',
    },
    sendedTeachers: '已发送教师',
    waitForSendTeachers: '待发送教师',
  },
  message: {
    title: '标题',
    recipient: '收件人 | {prefix}收件人',
    sender: '发件人',
    sendTime: '发件时间',
    sendMessage: '发消息',
    content: '内容',
    readStatus: '已读情况',
    attachments: '附件',
    emailNotice: '邮件通知',
    importantNotice: '重要通知',
    uploadAttachments: '上传附件',
    attachmentTip1: '单个附件限制100M以内,',
    attachmentTip2: '最多添加10个附件',
    attachmentTip3: '最多添加5个附件',
    enterTitle: '请输入标题',
    titleLimit: '标题不能超过100字符',
    enterContent: '请输入内容',
    addTeachers: '添加教师',
    addParents: '添加家长',
    addStudents: '添加学生',
    invalidFileSize: '单个附件大小不能超过100M',
    invalidFileFormat: '仅接受后缀名为doc, docx, xls, xlsx, ppt, pptx, pdf, jpg, jpeg或png的文件',
    receivedTime: '收件时间',
    reply: '回复',
    history: '历史消息',
    recipientError: '请选择收件人',
    read: '已读',
    unread: '未读',
    selectSendMail: '请选择是否发送邮件',
    notifiedByEmail: '邮件通知',
    notNotifiedByEmail: '未邮件通知',
    sendTips: '收件人中存在待入学学生，确认将消息发送给学生/家长？',
    normalMes: '普通消息',
    senderType: '发件人类型',
    messageType: '消息类型',
    messageTypes: {
      takeLeave: '请假通知',
      cancelLeave: '撤销请假通知',
      noRecord: '未考勤通知',
      missNightStudy: '晚自习缺席通知',
      miss: '缺席通知',
      changeClass: '换班通知',
      studentLog: '日记簿通知',
    },
    forward: '转发',
    setRecGroup: '收件人分组设置',
    allRead: '全部已读',
    allReadConfirm: '全部已读{num}条消息?',
    addGroup: '添加分组',
    recGroup: '收件人分组 | {prefix}收件人分组',
    shareParent: '通知家长',
    readDetail: '已读详情',
    recall: '撤回',
    recalled: '已撤回',
    recallSuccess: '撤回成功',
    recallFailed: '撤回失败,收件人已读消息',
    recipientType: '收件人类型',
    times: '时间段',
    newMessageNotice: '新消息提醒',
    receivedMessage: `您收到了新的消息: `,
    systemMessage: '{num}条系统消息',
    normalMessage: '{num}条普通消息',
  },
  homework: {
    contentNotEmptyTip: '输入内容不能为空',
    unlawScoreTip: '分数范围0-100',
    confirmCompleteMarkTip: '请确认完成评分',
    homeworkTitleName: '任务情况',
    uploadImgTip: '图片上载中...',
    suffixHomeWorkName: '的任务',
    publishScoreConfirm: '发布成功后分数不可修改，确认发布?',
    publishScoreSuccess: '发布成功',
    batchMark: '批量评分',
    finishMark: '完成评分',
    insertFailed: '插入失败',
    uploadImgs: '上传图片',
    scoreStatus: '得分状态',
    view: '查看',
    mark: '评分',
    scorePublished: {
      true: '已发布',
      false: '未发布',
    },
    studentName: '学生姓名',
    handInStatus: '完成状态',
    handInTime: '提交时间',
    score: '分数',
    comments: '评语',
    tipTwo: '超过截止日期{days}天',
    tipOne: '距离截止日期还有{days}天',
    publishPoints: '发布得分',
    accessory: '附件',
    remindStudent: '提醒学生',
    remindSuccess: '已发送通知提醒学生交任务',
    complete: '已完成',
    incomplete: '未完成',
    gradeTypeList: {
      1031: '不评分',
      1032: '百分制',
      1033: '等级制',
    },
    selectHandInOnline: '请选择是否线上交任务',
    selectGradeType: '请选择评分方式',
    selectCourse: '请选择课程班级',
    enterHomeworkName: '输入家庭任务名称',
    enterHomeworkContent: '输入任务内容',
    gradeType: '评分方式',
    handInOnline: '线上交任务',
    publishHomework: '发布任务',
    deadlineTime: '截止时间',
    statusList: {
      1011: '未完成',
      1012: '按时完成',
      1013: '逾期完成',
      1021: '进行中',
      1022: '已结束',
    },
    homeContent: '任务内容',
    name: '任务名称',
    sectionName: '年级',
    subjectName: '科目',
    courseName: '课程班级',
    completeNum: '完成情况',
    creatorName: '发布人',
    publishTime: '发布时间',
    CCAClass: '延展课程',
    courseClass: '课程班级',
    CCAClassHomework: '延展课程任务',
    courseClassHomework: '课程班级任务',
    handInAfterDead: '截止后可提交',
    allow: '是',
    disallow: '否',
    scored: '已评分',
  },
  exam: {
    commentTip: '评语不能为空',
    add: '添加',
    batchAdd: '批量添加',
    addCommentBatch: '批量添加评语',
    batchSelectError: '请先选择学生，再进行批量操作!',
    finishAndNext: '完成并到下一个',
    template: '模板',
    commentNotNull: '评语不能为空',
    points: '成绩',
    inputNumberOutRange: '分数不符合要求',
    downloadTranscript: '下载报告',
    writeComment: '写评语',
    house: '学院',
    teachers: '老师',
    courseClass: '课程班级',
    subject: '科目',
    teacherComment: '教师评语',
    scholasticAttainment: '学业情况',
    addComment: '添加评语',
    name: '姓名',
    code: '学号',
    className: '主班级',
    gradings: '考试成绩',
    comment: '评语',
    tipt: '考试已结束,只可查看',
    tip: '考试已锁定，无法编辑，若要编辑，请联系管理员',
    year: '学年',
    exam: '考试',
    section: '年级',
  },
  calendar: {
    addSchedule: '新增日程',
    editSchedule: '编辑日程',
    setDate: '设置工作日/假期',
    parentView: '进入家长/学生视图',
    closeParentView: '退出家长/学生视图',
    remark: '备注',
    location: '地点',
    time: '时间',
    date: '日期',
    workingDay: '工作日',
    holiday: '假期',
    summerHoliday: '暑假',
    winterHoliday: '寒假',
    visibleToParent: '在家长端和学生端显示',
    invisibleToParent: '在家长端和学生端不显示',
    holidayPlaceholder: '输入假期名称',
    deleteConfirm: '确定要删除这条日程吗？',
    semesterStartDate: '学期开始日',
    semesterEndDate: '学期结束日',
    allDay: '全天',
    status: '状态',
    viewSchedule: '查看日程',
    name: '名称',
  },
  addressBook: {
    name: '姓名',
    displayName: '显示名称',
    type: '类型',
    gender: '性别',
    position: '职务',
    email: '邮箱',
    switchDisplay: '显示在家长和学生端',
    editDepartment: '编辑部门',
    editExternalStaff: '编辑外部人员',
    addExternalStaff: '添加外部人员',
    addDepartment: '新增部门',
    editDepartmetn: '编辑部门',
    deleteDepartment: '删除部门',
    confirmDeleteDepartment: '确认删除该部门？',
    confirmDeleteStaff: '确认删除该员工？',
    contactNumber: '联系电话',
    contactEmail: '联系邮箱',
    introduction: '简介',
    enterName: '请输入姓名',
    manageDepartmentMember: '管理部门人员',
    externalStaff: '外部人员',
    manageExternalStaff: '管理外部人员',
    staff: '校内人员',
    addTeacher: '添加教师',
    deleteConfirm: '确认删除？',
    trrigerError: '保存失败请重试',
    enterDepName: '请输入部门名称',
    enterlegalEmail: '请输入合法邮箱',
    enterEmail: '请输入邮箱',
    teachInfo: '教学信息',
    teacherType: '教师类型',
  },
  personal: {
    passwordUnLaw: '密码为8-20个字符,必须包含数字和字母',
    nameValidateTip: '中英文名二选一必填',
    enterPosition: '请输入职务',
    enterSex: '请选择性别',
    textLimit50: '限制50字以内',
    enterName: '请输入中文名',
    enterenName: '请输入英文名',
    title: '个人信息',
    edit: '编辑',
    name: '中文名',
    enName: '英文名',
    sex: '性别',
    position: '职务',
    secret: '密码',
    email: '登录邮箱',
    modifySecret: '修改密码',
    nowSecret: '当前密码',
    newSecret: '新密码',
    confirmSecret: '确认密码',
    inputSecret: '请输入登录密码',
    inputNewSecret: '请输入新密码',
    inputConfirmSecret: '请确认密码',
    wrongPassword: '两次密码不同',
    passwordLimit: '密码错误或格式不正确',
  },
  transfer: {
    listTitle: '{title}列表',
    selectedNum: '已选择{title}{num}人',
    deleteAll: '全部删除',
    selectAll: '全选',
    enterName: '请输入{title}姓名',
    ungrouped: '未分组',
    undivided: '未分班',
  },
  weekday: {
    monday: '周一',
    tuesday: '周二',
    wednesday: '周三',
    thursday: '周四',
    friday: '周五',
    0: '周日',
    1: '周一',
    2: '周二',
    3: '周三',
    4: '周四',
    5: '周五',
    6: '周六',
    7: '周日',
  },
  CCAClass: {
    class: '课程',
    status: '状态',
    credits: '学分',
    enrolledNum: '已报名',
    enrollTime: '报名时间',
    classroom: '教室',
    teacherNames: '教师',
    enrollRange: '允许报名范围',
    classTime: '时间',
    name: '课程名称',
    selectSchoolYear: '请选择学年',
    schoolYear: '学年',
    classDescription: '课程描述',
    cycle: '周期',
    minEnrollNum: '最低报名数',
    maxEnrollNum: '最大报名数',
    enterDescriptionText: '请输入课程描述内容',
    selectEnrollTime: '请选择报名时段',
    selectCycle: '请选择周期',
    selectClassTime: '请选择时间',
    addTeacher: '添加教师',
    selectClassroom: '请选择教室',
    selectColleges: '请选择学院',
    selectGrades: '请选择年级',
    college: '学院',
    grade: '年级',
    enterMinEnrollNum: '请输入最低报名数',
    enterMaxEnrollNum: '请输入最大报名数',
    enterName: '请输入教程名称',
    conflictContext: '与现有课表的教室、教师存在冲突,请调整后再保存',
    got: '知道了',
    conflictType: '冲突类型',
    conflictTime: '冲突时间',
    conflictDateList: '冲突日期',
    conflictName: '冲突字段',
    selectAtLeastOne: '年级和学院至少选择其中一个',
    addTime: '添加时间',
    age: '年龄',
    studentId: '学号',
    studentName: '姓名',
    mainClass: '主班级',
    selectTeachers: '至少选择一个老师',
    enrolled: '已报名',
    publishConfirm: '是否确认发布',
    arrangeEnroll: '报名管理',
    reject: '拒绝',
    recallReject: '撤回拒绝',
    publishSuccess: '发布成功',
    conflictWithCurriculum: '时间与课表冲突',
    rejectConfirm: '是否确认拒绝',
    recallConfirm: '是否确认撤回',
    remain: '剩余',
    isCharge: '是否收费',
    chargeQuote: '费用报价',
    yes: '是',
    no: '否',
    noClassroom: '暂无教室',
    courseName: '课程名称',
    studentExcel: 'Homeroom报名表',
    courseExcel: '课程报名表',
    schoolCourse: '学校课程',
    outOfSchool: '供应商课程',
    courseType: '课程类型',
    unenrolledExcel: '未报名学生表',
    studentCourseExcel: '学生课程表',
    isCourse: '是否课程班级',
    teachingType: '教学类型',
    enrolledDetail: '报名详情',
    enrollSet: '报名设置',
    course: '课程设置',
    interval: '间隔',
    forSelection: '自主报名',
    numMin: '最低报名数',
    numMax: '最大报名数',
    basicSetting: '基础设置',
    courseFlag: '是否为课程班级',
    selectWeek: '请选择需要导出的课表日期',
    exportAllCourse: '课程信息表',
  },
  parentsMeeting: {
    meetingName: '家长会名称',
    status: '状态',
    meetingTime: '时间',
    meetingDate: '日期',
    reserveBeginTime: '预约开始时间',
    reservedNum: '预约数',
    duration: '时长',
    interval: '间隔',
    reservableTime: '可预约时间',
    classroom: '教室',
    selectClassroom: '请选择教室',
    reservableStudents: '可预约学生',
    reservedPerson: '预约人',
    reservedSituation: '预约情况',
    unpublished: '未发布',
    published: '已发布',
    teacher: '教师',
    ended: '已结束',
    started: '预约中',
    DNS: '未开始',
    reserveTime: '预约时间',
    apptEnded: '预约结束',
    onlineLink: '线上会议链接',
  },
  courseMaterial: {
    name: '资料名称',
    courseTime: '课程日期',
    course: '课程',
    instruction: '说明',
    attachment: '附件',
    courseClassMaterial: '课程班级课程资料',
    CCAClassMaterial: '延展课程课程资料',
    subjectClass: '课程班级',
    CCAClass: '延展课程',
    subject: '科目',
    creator: '发布人',
    createTime: '发布时间',
    enterName: '请输入课程资料名称',
    selectCourseTime: '请选择课程时间',
    uploadMaterial: '请上传资料',
    materialDetail: '课程资料详情',
    subMaterialDetail: '代课资料详情',
    updater: '更新人',
    updateTime: '更新时间',
    fileNum: '文件数',
  },
  leaveApproval: {
    student: '姓名',
    studentId: '学号',
    class: '主班级',
    college: '学院',
    leaveTime: '请假时间',
    leaveType: '请假类型',
    leaveDuration: '请假时长',
    approving: '待审批',
    approved: '已通过',
    approvedLabel: '已审批',
    pending: '待审批',
    retrieved: '已撤销',
    auditRetrieved: '已撤销',
    declined: '已拒绝',
    leavePerson: '请假人',
    leaveStatus: '请假状态',
    leaveReason: '请假事由',
    holiday: '假期',
    illness: '病假',
    passConfirmTips: '请假通过后会自动同步考勤状态，确认通过请假审批',
    rejectConfirm: '拒绝确认',
    passConfirm: '通过确认',
    days: '天',
    rejectReason: '拒绝原因',
    rejectConfirmTitle: '确认拒绝家长的请假申请?',
    passConfirmTitle: '确认通过家长的请假申请?',
    exams: '考试',
    others: '其他',
    personal: '缺席(可谅解)',
    replyDemo: '审批回复模板',
    cancel: '撤销',
    cancelConfirm: '确认撤销?',
    startLaterThanEnd: '开始时间不能晚于结束时间',
    endEarlierThanStart: '结束时间不能早于开始时间',
    apply: '回复',
    modifyTime: '修改时间',
    schoolBus: '@:(myClass.student.schoolBus)',
    accommodation: '@:(myClass.student.accommodation)',
    dormitory: '@:(myClass.student.dormitory)',
  },
  attendanceReport: {
    semester: '课期',
    attendanceStatus: '考勤状态',
    semesterAttendance: '课期考勤',
    classAttendance: '课堂考勤',
    type: '类型',
    period: '时段',
  },
  moment: {
    studentName: '学生姓名',
    drafts: '草稿箱',
    cancelShare: {
      students: '取消分享学生',
      parents: '取消分享家长',
    },
    share: {
      parents: '分享家长',
      students: '分享学生',
    },
    copyEdit: '复制&编辑',
    expandContent: '展开全文',
    collapseContent: '折叠全文',
    moreFilter: '更多筛选',
    totalLike: '共{total}人觉得很赞',
    saveDraft: '存草稿',
    translation: '翻译',
    img: '图片',
    video: '视频',
  },
  sen: {
    learningSpecialty: 'Learning Specialties | Learning Specialties',
    accommodationsAndInterventions: 'Accommodations And Interventions',
    meetingTime: '会谈时间',
    relativeStudents: '关联学生',
    relativeTeachers: '关联教师',
    senDetail: 'SEN详情',
  },
  kindergartenAtten: {
    allOnTime: '全部出席',
    signIn: '打卡',
  },
  tips: {
    invalidImg: '请上传正确格式的照片(.jpg, .jpeg, .png)',
    selectStudents: '请选择学生',
    selectTeachers: '请选择老师',
    inputContent: '请填写描述',
    momentDeleted: '该班级圈已被删除',
    noCourseArrange: '所选时间范围内无课程安排',
    selectSubjectClass: '请选择课程班级',
    selectLifeBlock: '请选择延展课程',
    selectType: '请选择类型',
    selectSubject: '请选择科目',
    enterDescription: '请输入描述',
    enterTopScore: '请输入最高分',
    selectOnline: '请选择是否任务类型',
    selectHandInOverDeadline: '请选择截止时间后是否可提交',
    selectTotalIncluded: '请选择是否计入总分',
    selectOperateStudent: '请选择学生后进行操作',
    selectAssignmentType: '请选择任务类型',
    updateScoreSuccess: '修改成绩信息成功',
    saveSuccess: '保存成功',
    updateRulesSuccess: '修改规则成功',
    generateReportSuccess: '生成报告成功',
    sendSuccess: '发送成功',
    updateSuccess: '修改成功',
    enterRejectReason: '请输入拒绝原因',
    selectGenerateStudent: '请选择已生成报告的学生',
    timeStartAfterEnd: '开始时间不能晚于结束时间',
    timeEndBeforeStart: '结束时间不能早于开始时间',
    selectTime: '请选择时间',
    selectDate: '请选择日期',
    enterName: '请输入名称',
    selectGrade: '请选择年级',
    deleteSuccess: '删除成功',
    selectLearningSpecialties: '请选择Learning Specialties',
    selectAccommodationsAndInterventions: '请选择accommodations and interventions',
    clockInSuccess: '打卡成功',
    selectStatus: '请选择状态',
    attachmentTip: '单个附件限制{size}以内, 最多添加{num}个附件',
    textLimit: '限制{num}字以内',
    textLimitZh: '限制{num}字以内(中文占2单位字符)',
    allPunctualConfirm: '是否将未考勤学生考勤设置成出席?',
    acceptFileTips: '仅接受后缀名为{tips}的文件',
    acceptSizeTips: '单个附件大小不能超过{size}',
    rejectFileTips: '不可上传后缀名为{tips}的文件',
    notFoundClass: '找不到该课程班级.',
    uploadFailed: '上传失败',
    select: '请选择',
    selectAssessStudent: '请选择已完成成绩评定的学生',
    sendReportFailed: '报告期未结束或未满足发送条件，无法发送',
    pleaseEnterReason: '请输入原因',
    selectLeaveType: '请选择请假类型',
    studentTransferError: '该学生已转学',
    recallConfirm: '是否确认撤回?',
    gradeSaved: '分数已保存',
    needNum: '请输入数字',
    positiveOnly: '请输入正数',
    negativeOnly: '请输入负数',
    overSize: '限制{min}-{max}以内',
  },
  systemMessage: {
    favorTips: '给班级圈点赞',
    favor: '点赞',
    approval: '审批',
    notice: '系统提醒',
    momentDetail: '班级圈详情',
    auditSuccess: '调课成功',
    auditFail: '调课失败',
    content: {
      1609: '您的调课申请({time})已通过,请及时上传代课资料',
      1626: '您的调课申请({time})已被审批人终止',
      1620: '发起一条调课申请, 时间: {time}',
      1622: '发起一条请假申请, 时间: {time}',
    },
  },
  errorCode: {
    403: '抱歉，你无权访问该页面',
    404: '抱歉，您访问的页面不存在。',
    585: '未满足发送条件，无法发送',
    586: '系统处理报告数据中，请稍后重试',
    589: '报告期未结束，无法发送',
    1010: '账号或密码错误',
    1039: '验证码错误',
    1041: '账号不存在',
    4006: '暂无系统登录权限',
    594: '报告期不在进行中，保存失败',
  },
  placeholder: {
    enterDescription: '请输入描述',
    select: '请选择',
    enterName: '请输入名称',
    enter: '请输入',
  },
  assignment: {
    deadline: '截止时间',
    totalIncluded: '计入总分',
    online1: '线上任务',
    online2: '线上任务(学生需线上提交任务)',
    offline: '线下任务',
    assignmentType: '任务类型',
    creator: '发布人',
    createTime: '创建时间',
    topScore: '最高分',
    handInAfterDead: '截止后可提交',
    allow: '是',
    disallow: '否',
    submitStatus: '提交状态',
    submitTime: '提交时间',
    Submit: '已提交',
    notSubmit: '未提交',
    assignmentDetail: '{name}的任务详情',
    submitList: '提交列表',
    typeCode: {
      1001: 'Prep',
      1002: 'Lab',
      1003: 'Quiz',
      1004: 'Lab',
      1005: 'Mid Exam',
      1006: 'End Exam',
      1007: 'Test',
      1008: 'Field Observation',
    },
    courseExistType: '{courses}已存在{type},此类型任务每学期只可添加一个,不能重复添加',
    deleteConfirm: '任务删除后会将学生的任务和得分同时删除,确认删除?',
    scoreAllow: '是否评分',
    publicScore: '公开分数',
    description: '任务描述',
    allowTips: '选择允许后，可以在成绩册进行评分',
    startDate: '开启时间',
    deadlineError: '任务截止时间需要晚于开始时间',
    startDateError: '任务开始时间需要早于截止时间',
    resubmitTip: '学生已重新上传作业内容，请核查',
    tip: '提示',
    ignore: '忽略',
  },
  grading: {
    scoreSheet: {
      rules: '规则',
      FSRules: '最终分数规则',
      warningTips: '百分比相加需达到100%',
      mark: {
        missing: '缺交',
        incomplete: '未完成',
        late: '迟交',
      },
      finalScore: '最终分数',
      addType: '添加类型',
      export: '导出成绩册',
      maxScore: '最高分: {score}',
      level: '等级: {score}',
      notIncludeTips: '不计入最终成绩',
      hideTips: '成绩不显示给学生',
      systemScore: '系统成绩',
      customScore: '编辑成绩',
      studentVisible: '学生可见',
      studentInvisible: '学生不可见',
      foldStudent: '收起退班学生',
      unfoldStudent: '展开退班学生',
      scoreIn: '录入成绩',
      finishScore: '完成录入',
      last: '上一个',
    },
    report: {
      generateReport: '生成报告',
      sendReport: '发送报告',
      regenerateReport: '重新生成报告',
      downloadReport: '下载报告',
      notGenerated: '未生成',
      unpublished: '未发送',
      published: '已发送',
      reportName: '报告名称',
      generateTime: '生成时间',
      editComment: '编辑评语',
      sendConfirm: '报告发送后不可再修改,确认发送?',
      regenerateConfirm: '确认重新生成报告？',
      sendResult: '发送结果',
      exportComments: '导出评语表',
      headteacherComments: '班主任评语',
      advisorComments: 'Advisor 评语',
      subjectTeacherComments: '科目教师评语',
      academicAchieve: '学术成就表',
      commentProgress: '{type}完成度',
      completeStatus: '{type}完成情况',
      incompleteAssess: '未完成的评定',
    },
  },
  studentDetail: {
    honor: {
      honorTime: '荣誉时间',
      edit: '编辑Honor',
      add: '添加Honor',
      honorDetail: 'Honor详情',
    },
    leadership: {
      edit: '编辑Leadership',
      add: '添加Leadership',
      tenure: '任职时间',
      leaderDetail: 'Leadership详情',
    },
    label: {
      honor: 'Honors',
      leadership: 'Leadership',
      report: '报告',
      points: '成绩',
      studentInfo: '学生信息',
      parentInfo: '家长信息',
      curriculumInfo: '课表信息',
      teacherInfo: '教师信息',
      classInfo: '班级信息',
      subjectInfo: '课程信息',
      diary: '日记簿',
      attendanceInfo: '考勤信息',
      gradeBook: '成绩册',
      instruction: '说明',
      transferInfo: '转学信息',
    },
    cropTip: '鼠标滚轮缩放控制图片显示大小，拖拽调整图片位置',
    preview: '预览',
    reupload: '重新上传',
    uploadAvatar: '上传头像',
    wrongAvatar: '错误图片类型',
    wrongBrowser: '浏览器错误',
    score: '得分',
  },
  eGrade: {
    reportTime: '报告期',
    gradeAssessment: '成绩评定',
    headteacherRemark: '班主任评语',
    subjectTeacherRemark: '科目教师评语',
    LBRemark: 'LB 评语',
    advisorRemark: 'Advisor评语',
    assessmentTips: '已完成评分的学术成就条目/全部学科学术成就条目',
    headteacherTips: '已填写评语的人数/全部需要填写的人数',
    subjectTeacherTips: '已填写评语的人数/全部需要填写的人数',
    commentTips: '已填写评语的人数/全部需要填写的人数',
    applyAll: '全部应用',
    reportPeriodLockedTip: '报告期已锁定，无法编辑，若要编辑，请联系管理员',
    reportPeriodEndedTip: '报告期已结束，只可观看',
    reportPeriodNotEndTip: '当前报告期未结束，需要管理员结束后可将报告发送给学生和家长',
    reportPeriodNotLockTip: '当前报告期未锁定，需要管理员锁定后可生成报告并发送',
    reportPeriodNotLockTipCD: '当前报告期未锁定，需要管理员锁定后可生成报告',
    reportPeriodProgress: '报告生成中，全部完成后可发送和下载报告，当前进度：{progress}%',
    reportPeriodProgressCD: '报告生成中，全部完成后可下载报告，当前进度：{progress}%',
    graded: '已评',
    levelOfMastery: '熟练程度',
    descriptionOfPerformance: '表现',
    viewStandard: '查看标准',
    remark: '评语',
    complete: '完成',
    academicAchievements: '学术成就',
    learningEfforts: '学习品行',
    subject: '课程班级',
    headTeacher: '主班级',
    advisor: '学院',
    lifeBlock: '延展课程',
    batchApplyConfirm: '确认全部应用吗?',
    termReport: '学期报告',
    yearlyReport: '学年报告',
    progress: '评估进度',
    completed: '已完成：{num}人',
    incompleted: '未完成：{num}人',
    academicAchievementsStandard: '学术成就评分标准',
    learningEffortsStandard: '学习品行评分标准',
    exportAssessmentSheet: '导出评估表',
    editFirst: '请点击编辑按钮编辑成绩',
  },
  dormitory: {
    floor: '楼层',
    room: '宿舍',
    bed: '床位',
    bedName: '宿舍/床位',
    studyRoom: '自习室',
    selfStudy: '自习',
  },
  teaching: {
    assignment: '任务',
    resource: '资料',
    inTotal: '计入总分',
    notStart: '未开启',
    started: '已开启',
    online: '线上任务',
    startDate: '开启时间',
    downloadAttach: '下载学生附件',
    downloadAllAttach: '下载全部附件',
    subjectClass: '课程班级',
    CCAClass: '延展课程',
    taskAndData: '任务&资料',
    scoreSheet: '成绩册',
    schoolYear: '学年',
    name: '名称',
    type: '类型',
    taskType: '任务类型',
    status: '状态',
    scoreAllow: '是否评分',
  },
  LBReport: {
    schoolDay: '上课日',
    export: '导出{item}',
    xlsxName: '学生LB课程表{date}',
    tableName: '学生LB周课表{date}',
    courseForm: '学生LB课程表',
    weekForm: '学生LB周课表',
    week: '周',
  },
  shortWeekday: {
    monday: '周一',
    tuesday: '周二',
    wednesday: '周三',
    thursday: '周四',
    friday: '周五',
    saturday: '周六',
    Sunday: '周日',
    0: '周日',
    1: '周一',
    2: '周二',
    3: '周三',
    4: '周四',
    5: '周五',
    6: '周六',
    7: '周日',
  },
  notice: {
    title: '标题',
    department: '部门',
    date: '日期',
    status: '状态',
    creater: '创建人',
    publishers: '发布人',
    releaseTime: '发布时间',
    operations: '操作',
    recycleBin: '回收站',
    announcement: '公告',
    sticky: '置顶',
    cancelSticky: '取消置顶',
    moveToRecycle: '移入回收站',
    confirmCancelSticky: '确认将通知取消置顶？',
    confirmRecycle: '确认将通知移入回收站？',
    confirmSticky: '确认将消息置顶？',
    confirmDeleteDep: '确认删除该部门？',
    confirmDeleteTag: '确认删除该标签？',
    published: '已发布',
    notPublished: '未发布',
    endingDate: '结束日期',
    enterEngingDate: '请输入结束日期',
    enterName: '请输入名称',
    publishStatus: {
      published: '已发布',
      unpublish: '未发布',
    },
    addDepartment: '新增部门',
    editDepartment: '编辑部门',
    addStaffs: '添加人员',
    tagName: '标签名称',
    applyType: '应用类型',
    types: {
      1: '通知',
      2: '新闻',
      3: '活动',
    },
    addTag: '新增标签',
    editTag: '编辑标签',
    chineseNameLimit: '中文名不能超过100字符',
    englishNameLimit: '英文名不能超过100字符',
    color: '颜色',
    selectTypes: '请选择类型',
    duplicatedName: '名称不能重复',
    selectColor: '请选择颜色',
    restore: '恢复内容',
    delete: '彻底删除',
    confirmRestore: '确认将通知恢复？',
    confirmDeleteNotice: '确认将通知彻底删除？',
    enterTitle: '请输入标题',
    enterDepName: '请输入部门名称',
    selectTags: '请选择标签',
    selectTime: '请选择时间',
    selectScope: '请选择范围',
    selectDepartment: '请选择部门',
    enterContent: '请填写通知内容',
    attachmentTip1: '单个附件限制100M以内,',
    attachmentTip2: '最多添加10个附件',
    attachmentTip3: '最多添加5个附件',
    tag: '标签',
    content: '内容',
    attachment: '附件',
    publishTiming: '定时发布',
    stickyEndingTime: '置顶结束时间',
    recipientScope: '发布范围',
    editTip: '公告编辑提示',
    notifiedByEmail: '邮件通知',
    editInstruction: '公告编辑说明',
    admin: '管理端',
    teacher: '教师端',
    parent: '家长端',
    student: '学生端',
    sections: '年级',
    boarders: '住宿生',
    dayStudents: '走读生',
    emailNotice: '邮件通知',
    send: '发送',
    uploadAttachments: '上传附件',
    publishTips: '(若选择不定时发布，保存后会直接发布)',
    sendPreview: '发送预览邮件',
  },
  announcement: {
    all: '全部',
    campusActivity: '校园活动',
    campusPolicy: '校园政策',
    attention: '注意事项',
    search: '搜索',
    edit: '通知在{time}曾被编辑',
    department: '部门',
    time: '时间',
    status: '状态',
    publisher: '发布人',
    isTop: '是否置顶',
    topEndTime: '置顶结束时间',
    published: '已发布',
    unpublished: '未发布',
    yes: '是',
    no: '否',
    keyword: '关键字',
    date: '日期',
    tag: '标签',
  },
  monthlyMgm: {
    courseClass: '课程班级',
    setAll: '给全部学生设置等级',
    selectGrade: '请选择等级',
    tutor: 'Tutor评语',
    progress: '评估进度',
    progressTip: '已完成评估的课程数/全部需要评估的课程',
    title: {
      assess: '评估进度',
      tutor: '导师评语完成情况',
      headerTeacher: '班主任评语完成情况',
      deputyHead: '副校长评语完成情况',
      courseTeacher: '科目评语完成情况',
      percentage: '评分进度',
    },
    singleColumn: {
      tutor: '学院',
      headerTeacher: '主班级',
      deputyHead: '学部',
      courseTeacher: '课程班级',
      percentage: '课程班级',
    },
    status: {
      assess: '评估完成情况',
      other: '评语完成情况',
    },
    comments: '评语',
    housePoint: '学院分',
    houseAchievement: '优异的学院表现',
    conductPoint: '行规扣分',
    behaviourEvent: '违规事件记录',
    point: '分数',
    edithouse: '编辑House Points',
    editconduct: '编辑Conduct Points',
    advisorRemark: '导师评语',
    export: '导出评估数据',
    month: '月份',
    selectMonth: '请选择月份',
    deputyHead: '副校长评语',
    courseTeacher: '科目评语',
    percentage: '评分进度',
    name: '姓名',
    grade: '年级',
    house: '学院',
    exportConduct: '导出品行表',
    schoolYearHousePoint: '学年累计学院分',
    schoolYearConductPoint: '学年累计行为分',
  },
  academic: {
    modifiedRecord: '修改记录',
    modified: '已修改',
    unmodified: '未修改',
    tag: '状态',
    extraTag: '额外状态',
    reportStatus: '报告状态',
    updatable: '可更新',
    updated: '已更新',
    additionalStatus: '额外状态',
    exportAcademicStanding: '导出学术水平',
    exportBelowStandard: '导出未达标课程',
    noLabel: '无状态',
    // standing: '良好',
    // warning: '学术警告',
    // probation: '学术察看',
    // review: '学术审查',
    standing: 'GS',
    warning: 'AW',
    probation: 'AP',
    review: 'AR',
    scoreSheet: '查看成绩册',
    updateReport: '更新报告',
    updateReportConfirm: '确认更新报告？',
    updateLabel: '修改状态',
    systemLabel: '系统状态',
    label: '状态',
    editRecord: '修改记录',
    editDetail: '将状态{old}修改为{new}',
    timeEditDetail: '{time} {person},将状态{old}修改为{new}',
    statement: '状态说明',
    systemStatus: '系统状态',
    curStatus: '当前状态',
    point: '分数',
    statementDetail: '状态详情',
    warningCourses: '60-70分的课程',
    probationCourses: '<60分的课程',
    newWarningCourse: '<=66分的课程',
    average: '报告平均分',
    belowStandard: '未达标课程',
    isUnpdated: '是否同步报告',
    normal: '无异常',
  },
  allLabel: {
    standing: '良好',
    warning: '学术警告',
    probation: '学术察看',
    review: '学术审查',
    noLabel: '无状态',
  },
  campus: {
    '1231': '小学',
    '1232': '初中',
    '1233': '高中',
  },
  graduation: {
    requirementSetting: '毕业标准设置',
    graduationRqm: '毕业标准',
    totalCredit: '总学分',
    creationDate: '创建日期',
    setAsDefault: '设置为默认',
    setConfirm: '确定设置为默认吗？',
    deleteConfirm: '确认删除吗？',
    requirements: '条件设置',
    instruction: '说明',
    COMPULSORY: '必修',
    compulsory: '必修课程',
    compulsoryCredit: '必修学分',
    compulsoryLabel: '必修课程 (学分: {credit})',
    ELECTIVE: '选修',
    NONE: '无',
    NONE_COUNTED: '无',
    elective: '选修课程',
    electiveCredit: '选修学分',
    electiveLabel: '选修课程 (学分: {credit})',
    courseAdmission: '课程条件{index}',
    mark: '需要学分',
    courseGroup: '课程组{index}',
    course: '课程',
    maxRecognizedCreitds: '最大承认学分',
    title: '标题',
    selectCourseGroup: '请选择课程',
    enterPostive: '请输入正整数',
    validateCredit: '课程组总分和需要学分应该相等,请调整后保存',
    addCourse: '添加课程',
    addCourseGroup: '添加课程组',
    addCourseAdmission: '添加课程条件',
    changeStandard: '修改标准',
    progress: '毕业进度',
    schoolCredits: '校内课程学分',
    outOfSchoolCredits: '额外课程学分',
    addOut: '添加额外课程',
    editOut: '编辑额外课程',
    calcType: '计分类型',
    associate: '关联校内课程',
    associatedCourse: '关联课程',
    finished: '已修课程',
    inProgress: '进行中课程',
    viewRequirements: '查看毕业标准',
    changeRequirements: '修改毕业标准',
    selectStandard: '请选择毕业标准',
    studies: '学业信息',
    completed: '已完成',
    complete: '已完成',
    ongoing: '进行中',
    courseType: '课程类型',
    add: '添加',
    courseName: '课程名称',
    passConfirm: '确定通过该课程吗？',
    withdrawPassConfirm: '确定撤销通过该课程吗？',
    creditOverflow: '已超过必修最大学分，计入选修学分',
    noneTip: '已重修相同课程，不计入学分',
    gotCredits: '获得学分',
    completedProgress: '完成进度',
    finishedCredit: '已修学分',
    // ongoingCourse: '进行中的课程',
    EXTRA: '额外课程',
    OVERFLOW: '超出必修条件，计入选修',
    category: '科目',
    level: '等级',
    exportAcademicProgressReport: '导出学业进度报告',
    courseStatus: '课程状态',
    outOfStandardTip: '课程不在毕业标准内',
  },
  qrcode: {
    download: '下载二维码',
    scanToLogin: '扫一扫登录手机端',
    deadline: '有效期截止到：{time}',
    generateQRCode: '生成二维码',
    tip1: '二维码有效期为',
    tip2: '过期后请重新生成',
    day: '天',
    update: '立即更新',
    expired: '二维码已过期',
    qrcodeTitle: '二维码',
  },
}
