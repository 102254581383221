/* eslint-disable */
import fetch from '../utils/fetch';
import * as M from './__model';
import {AxiosRequestConfig} from 'axios';
export class TaskController {

    /**
      * 新增任务 
      */ 
    public static add(request: M.TaskRequest, config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/task/add`,request, config);
    }

    /**
      * 校验该类型任务是否已存在 
      */ 
    public static checkExist(request: M.TaskCheckRequest, config?: AxiosRequestConfig ) {
      return fetch.get<string[]>(`/task/checkExist`, { params:request,...config});
    }

    /**
      * 清楚重新提交标志 
      */ 
    public static clearReSubmit(taskStudentId: number, config?: AxiosRequestConfig ) {
      return fetch.delete<void>(`/task/${taskStudentId}/re-submit`, { params:{},...config});
    }

    /**
      * 删除任务 
      */ 
    public static delete(taskId: number, config?: AxiosRequestConfig ) {
      return fetch.delete<void>(`/task/delete`, { params:{taskId},...config});
    }

    /**
      * 获取作业详情 
      */ 
    public static detail(taskId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.TaskResponse>(`/task/detail`, { params:{taskId},...config});
    }

    /**
      * 获取cca课程列表 
      */ 
    public static getCcaCourses(request: M.TeachingCourseRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/task/ccaCourses`, { params:request,...config});
    }

    /**
      * 课程班级联下拉列表,  
      */ 
    public static getCoursesCascadeGroupBySubject(request: M.TeachingCourseRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.CascadeResponse[]>(`/task/cascadeGroupBySubject`, { params:request,...config});
    }

    /**
      * 获取任务列表 
      */ 
    public static getList(request: M.TaskPageRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.TaskPageResponse>>(`/task/list`, { params:request,...config});
    }

    /**
      * 获取教学课程信息列表 
      */ 
    public static getMergeList(request: M.TeachingPageRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.TeachingPageResponse>>(`/task/mergeList`, { params:request,...config});
    }

    /**
      * 获取作业完成情况 
      */ 
    public static getPerformance(taskId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.TaskPerformanceResponse[]>(`/task/performance`, { params:{taskId},...config});
    }

    /**
      * 获取学生任务详情 
      */ 
    public static getStudentTaskDetail(taskStudentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.TaskStudentResponse>(`/task/student/detail`, { params:{taskStudentId},...config});
    }

    /**
      * 获取作业已读未读详情 
      */ 
    public static readDetail(taskId: number, config?: AxiosRequestConfig ) {
      return fetch.get<any>(`/task/read/detail`, { params:{taskId},...config});
    }

    /**
      * 拆分任务 
      */ 
    public static splitTask(config?: AxiosRequestConfig ) {
      return fetch.get<void>(`/task/splitTask`,config);
    }

    /**
      * 编辑任务 
      */ 
    public static update(request: M.TaskRequest, config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/task/update`,request, config);
    }

    /**
      * 更新评分 
      */ 
    public static updateScore(request: M.GradeUpdateSimpleRequest, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/task/updateScore`,request, config);
    }


}
export class DropDownController {

    /**
      * 消息教师下拉,查询有权限的教师 
      */ 
    public static authTeachersForMessage(config?: AxiosRequestConfig ) {
      return fetch.get<M.TeacherResponse[]>(`/dropDown/authTeachersForMessage`,config);
    }

    /**
      * 消息教师下拉,查询有权限的教师 
      */ 
    public static authTeachersForNyClass(config?: AxiosRequestConfig ) {
      return fetch.get<M.TeacherResponse[]>(`/dropDown/authTeachersForMyClass`,config);
    }

    /**
      * 获取考勤类型下拉 
      */ 
    public static getAttendanceKinds(config?: AxiosRequestConfig ) {
      return fetch.get<M.ExtendDropDownResponse[]>(`/dropDown/attendance-kinds`,config);
    }

    /**
      * 查询有权限的学生列表，根据课程分组 
      */ 
    public static getAuthStudentGroupByCourse(config?: AxiosRequestConfig ) {
      return fetch.get<M.SectionCourseStudentResponse[]>(`/dropDown/authStudentsGroupByCourse`,config);
    }

    /**
      * 查询有权限的学生家长列表，根据课程分组 
      */ 
    public static getAuthStudentParentsGroupByCourse(config?: AxiosRequestConfig ) {
      return fetch.get<M.SectionCourseParentResponse[]>(`/dropDown/authStudentParentsGroupByCourse`,config);
    }

    /**
      * 查询有权限的学生家长列表，根据学院分组 
      */ 
    public static getAuthStudentParentsGroupByHouse(config?: AxiosRequestConfig ) {
      return fetch.get<M.HouseStudentParentResponse[]>(`/dropDown/authStudentParentsGroupByHouse`,config);
    }

    /**
      * 查询有权限的学生家长列表，根据年级、班级分组 
      */ 
    public static getAuthStudentParentsGroupBySectionClass(config?: AxiosRequestConfig ) {
      return fetch.get<M.SectionClassStuParentResponse[]>(`/dropDown/authStudentParentsGroupBySectionClass`,config);
    }

    /**
      * 查询有权限的学生列表，根据班级分组 
      */ 
    public static getAuthStudentsGroupByClass(statuses?: string[], config?: AxiosRequestConfig ) {
      return fetch.get<M.ClassStudentResponse[]>(`/dropDown/authStudentsGroupByClass`, { params:{statuses},...config});
    }

    /**
      * 查询有权限的学生列表，根据学院分组 
      */ 
    public static getAuthStudentsGroupByHouse(statuses?: string[], config?: AxiosRequestConfig ) {
      return fetch.get<M.HouseStudentResponse[]>(`/dropDown/authStudentsGroupByHouse`, { params:{statuses},...config});
    }

    /**
      * 查询有数据权限的学生，按年级分组 
      */ 
    public static getAuthStudentsGroupBySection(includeWFE?: boolean, config?: AxiosRequestConfig ) {
      return fetch.get<M.CascadeResponse[]>(`/dropDown/authStudentsGroupBySection`, { params:{includeWFE},...config});
    }

    /**
      * 查询有权限的学生列表，根据班级分组 
      */ 
    public static getAuthStudentsGroupBySectionClass(statuses?: string[], config?: AxiosRequestConfig ) {
      return fetch.get<M.SectionClassStudentResponse[]>(`/dropDown/authStudentsGroupBySectionClass`, { params:{statuses},...config});
    }

    /**
      * 学部下拉 
      */ 
    public static getCampusList(config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/dropDown/campusList`,config);
    }

    /**
      * 获取cca课程安排级联 
      */ 
    public static getCcaArrangeCascadeOnce(date: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.CascadeResponse[]>(`/dropDown/ccaArrangeCascadeOnce`, { params:{date},...config});
    }

    /**
      * 根据报告期查询定义为课程的cca课程班级 
      */ 
    public static getCcaByPeriod(periodId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/dropDown/ccaByPeriod`, { params:{periodId},...config});
    }

    /**
      * 获取cca课程列表 
      */ 
    public static getCcaCourse(config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/dropDown/ccaCourses`,config);
    }

    /**
      * 获取cca课程列表 
      */ 
    public static getCcaCourseBySchoolYear(schoolYearId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/dropDown/ccaCoursesBySchoolYear`, { params:{schoolYearId},...config});
    }

    /**
      * 根据学部获取主班级 
      */ 
    public static getClassByCampus(campusType: string, config?: AxiosRequestConfig ) {
      return fetch.get<M.CascadeResponse[]>(`/dropDown/class/campus-type/${campusType}`, { params:{},...config});
    }

    /**
      * 根据报告期查询主班级 
      */ 
    public static getClassByMonthlyPeriod(gradePeriodId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/dropDown/monthly-period/${gradePeriodId}/class`, { params:{},...config});
    }

    /**
      * 根据评估时段查询班级下拉 
      */ 
    public static getClassByPeriod(gradePeriodId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.CascadeResponse[]>(`/dropDown/classByPeriod`, { params:{gradePeriodId},...config});
    }

    /**
      * 获取班级列表 
      */ 
    public static getClassList(config?: AxiosRequestConfig ) {
      return fetch.get<M.CascadeResponse[]>(`/dropDown/classList`,config);
    }

    /**
      * 获取所有班级列表 
      */ 
    public static getClassListAll(config?: AxiosRequestConfig ) {
      return fetch.get<M.CascadeResponse[]>(`/dropDown/classListAll`,config);
    }

    /**
      * 教室级联下拉 
      */ 
    public static getClassRoomCascade(config?: AxiosRequestConfig ) {
      return fetch.get<M.CascadeResponse[]>(`/dropDown/classRoomCascade`,config);
    }

    /**
      * 获取教室列表 
      */ 
    public static getClassroomList(config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/dropDown/classroomList`,config);
    }

    /**
      * 获取课程安排级联 
      */ 
    public static getCourseArrangeCascadeOnce(date: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.CascadeResponse[]>(`/dropDown/courseArrangeCascadeOnce`, { params:{date},...config});
    }

    /**
      * 根据学年查询定义为课程的cca课程班级, 不传学年查询当前学年 
      */ 
    public static getCourseCcaBySchoolYear(schoolYearId?: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/dropDown/courseCcaBySchoolYear`, { params:{schoolYearId},...config});
    }

    /**
      * 根据学期获取课程班级级联下拉框 
      */ 
    public static getCourseOfSemester(semesterId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.CascadeResponse[]>(`/dropDown/course/by-semester`, { params:{semesterId},...config});
    }

    /**
      * 获取课程班级老师数据接口 
      */ 
    public static getCourseTeachers(config?: AxiosRequestConfig ) {
      return fetch.get<M.RelationTeacherGroupResponse[]>(`/dropDown/course-teacher`,config);
    }

    /**
      * 根据报告期查询课程班级 
      */ 
    public static getCoursesCascadeByPeriod(periodId: number, tableNeed: boolean, config?: AxiosRequestConfig ) {
      return fetch.get<M.CascadeResponse[]>(`/dropDown/cascadeByPeriod`, { params:{periodId,tableNeed},...config});
    }

    /**
      * 获取宿舍级联下拉列表 
      */ 
    public static getDormitoryCascadeList(gender?: string, config?: AxiosRequestConfig ) {
      return fetch.get<M.CascadeResponse[]>(`/dropDown/dormitory/cascade-list`, { params:{gender},...config});
    }

    /**
      * 获取宿舍列表 
      */ 
    public static getDormitoryList(floorId?: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/dropDown/dormitoryList`, { params:{floorId},...config});
    }

    /**
      * 获取楼宿舍床位列表 
      */ 
    public static getFloorAndDormitoryAndBedList(gender?: string, config?: AxiosRequestConfig ) {
      return fetch.get<M.CascadeResponse[]>(`/dropDown/floorAndDormitoryAndBedList`, { params:{gender},...config});
    }

    /**
      * 获取楼层和宿舍列表 
      */ 
    public static getFloorAndDormitoryList(gender?: string, config?: AxiosRequestConfig ) {
      return fetch.get<M.CascadeResponse[]>(`/dropDown/floorAndDormitoryList`, { params:{gender},...config});
    }

    /**
      * 获取楼层和宿舍列表 
      */ 
    public static getFloorAndDormitoryListAll(gender?: string, config?: AxiosRequestConfig ) {
      return fetch.get<M.CascadeResponse[]>(`/dropDown/floorAndDormitoryListAll`, { params:{gender},...config});
    }

    /**
      * 获取楼层列表 
      */ 
    public static getFloorList(gender?: string, config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/dropDown/floorList`, { params:{gender},...config});
    }

    /**
      * 根据学年查询小学报告期 
      */ 
    public static getGradePeriods(schoolYearId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/dropDown/gradePeriods`, { params:{schoolYearId},...config});
    }

    /**
      * 获取班主任 
      */ 
    public static getHeadTeachers(config?: AxiosRequestConfig ) {
      return fetch.get<M.RelationTeacherGroupResponse[]>(`/dropDown/head-teachers`,config);
    }

    /**
      * 获取学院分组列表 
      */ 
    public static getHouseGroupList(config?: AxiosRequestConfig ) {
      return fetch.get<M.HouseDropDownResponse[]>(`/dropDown/houseGroupList`,config);
    }

    /**
      * 获取所有学院分组列表 
      */ 
    public static getHouseGroupListAll(config?: AxiosRequestConfig ) {
      return fetch.get<M.HouseDropDownResponse[]>(`/dropDown/houseGroupListAll`,config);
    }

    /**
      * 查询报告期下拉 
      */ 
    public static getPeriodCascade(schoolYearId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.CascadeResponse[]>(`/dropDown/gradePeriodCascade`, { params:{schoolYearId},...config});
    }

    /**
      * 根据评语类型查询报告期下拉 
      */ 
    public static getPeriodCascadeByType(schoolYearId: number, commentType: string, config?: AxiosRequestConfig ) {
      return fetch.get<M.CascadeResponse[]>(`/dropDown/gradePeriodCascadeByType`, { params:{schoolYearId,commentType},...config});
    }

    /**
      * 查询报告期下拉 
      */ 
    public static getPeriodCascadeForReport(schoolYearId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.CascadeResponse[]>(`/dropDown/gradePeriodCascade/forReport`, { params:{schoolYearId},...config});
    }

    /**
      * 获取全部学年列表 
      */ 
    public static getSchoolYearList(config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/dropDown/schoolYearList`,config);
    }

    /**
      * 获取全部学年列表按 当前-未来-历史 
      */ 
    public static getSchoolYearRuleList(full?: boolean, config?: AxiosRequestConfig ) {
      return fetch.get<M.SchoolYearDropDownResponse[]>(`/dropDown/schoolYearRuleList`, { params:{full},...config});
    }

    /**
      * 获取年级列表 
      */ 
    public static getSectionCascade(config?: AxiosRequestConfig ) {
      return fetch.get<M.CascadeResponse[]>(`/dropDown/sectionCascade`,config);
    }

    /**
      * 获取年级列表 
      */ 
    public static getSectionList(config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/dropDown/sectionList`,config);
    }

    /**
      * 获取年级列表 
      */ 
    public static getSectionListForClassPeriodStatistics(config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/dropDown/sectionList/cpAttendance`,config);
    }

    /**
      * 根据学部类型获取年级列表 
      */ 
    public static getSectionsByType(type: string, config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/dropDown/sectionsByType`, { params:{type},...config});
    }

    /**
      * 学期级联下拉框 
      */ 
    public static getSemesterCascade(config?: AxiosRequestConfig ) {
      return fetch.get<M.CascadeResponse[]>(`/dropDown/semester/cascade`,config);
    }

    /**
      * 学期下拉框 
      */ 
    public static getSemesterDropDown(schoolYearId?: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.SemesterResponse[]>(`/dropDown/semester`, { params:{schoolYearId},...config});
    }

    /**
      * sen类型下拉 
      */ 
    public static getSenTypes(type: string, config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/dropDown/senTypes`, { params:{type},...config});
    }

    /**
      * 获取自习室列表 
      */ 
    public static getSsRoomList(config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/dropDown/ssRoomList`,config);
    }

    /**
      * 获取自习室列表 
      */ 
    public static getSsRoomListAll(config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/dropDown/ssRoomListAll`,config);
    }

    /**
      * 获取我的学生班级列表 
      */ 
    public static getStudentClass(config?: AxiosRequestConfig ) {
      return fetch.get<M.CascadeResponse[]>(`/dropDown/studentClass`,config);
    }

    /**
      * 获取学院分组列表 
      */ 
    public static getStudentHouseGroup(config?: AxiosRequestConfig ) {
      return fetch.get<M.HouseDropDownResponse[]>(`/dropDown/studentHouseGroup`,config);
    }

    /**
      * 获取关联学生选择 
      */ 
    public static getStudents(config?: AxiosRequestConfig ) {
      return fetch.get<M.CascadeResponse[]>(`/dropDown/students`,config);
    }

    /**
      * 查询有数据权限的学生，按年级分组 
      */ 
    public static getStudentsGroupByClass(includeWFE?: boolean, config?: AxiosRequestConfig ) {
      return fetch.get<M.CascadeResponse[]>(`/dropDown/studentsGroupByClass`, { params:{includeWFE},...config});
    }

    /**
      * 根据年级id列表 获取科目列表（并集） 
      */ 
    public static getSubjectBySectionsUnion(request: M.SectionIdsRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/dropDown/subjectsBySectionUnion`, { params:request,...config});
    }

    /**
      * 获取科目列表 
      */ 
    public static getSubjectList(campusTypes?: string[], sectionIds?: number[], config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/dropDown/subjectList`, { params:{campusTypes,sectionIds},...config});
    }

    /**
      * 获取科目列表 
      */ 
    public static getSubjectListForAttendance(config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/dropDown/subjectListForAttendance`,config);
    }

    /**
      * 获取任务类型 
      */ 
    public static getTaskType(config?: AxiosRequestConfig ) {
      return fetch.get<M.TypeListResponse[]>(`/dropDown/taskType`,config);
    }

    /**
      * 获取教授cca课程列表 
      */ 
    public static getTeachCcaCourse(config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/dropDown/teach/ccaCourses`,config);
    }

    /**
      * 获取教授cca课程列表 
      */ 
    public static getTeachCourse(config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/dropDown/teach/courses`,config);
    }

    /**
      * 获取tutor 
      */ 
    public static getTutors(config?: AxiosRequestConfig ) {
      return fetch.get<M.RelationTeacherGroupResponse[]>(`/dropDown/tutors`,config);
    }

    /**
      * 获取当前和未来学年列表 
      */ 
    public static getUnPassedSchoolYearList(config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/dropDown/unPassedSchoolYearList`,config);
    }


}
export class ExamController {

    /**
      * 获取考试评语评语设定 
      */ 
    public static getCommentSettings(examId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.TeacherCommentResponse[]>(`/exam/comment/settings`, { params:{examId},...config});
    }

    /**
      * 考试课程班级下拉框 
      */ 
    public static getCourseCascade(examId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.CascadeResponse[]>(`/exam/course/cascade`, { params:{examId},...config});
    }

    /**
      * 获取考试下拉框 
      */ 
    public static getExamDropdown(schoolYearId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/exam/dropdown`, { params:{schoolYearId},...config});
    }

    /**
      * 获取评语考试下拉框 
      */ 
    public static getExamDropdownByExamComment(schoolYearId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/exam/comment/exam`, { params:{schoolYearId},...config});
    }

    /**
      * 获取学生考试下拉框 
      */ 
    public static getExamDropdownByStudent(schoolYearId: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/exam/dropdown/student`, { params:{schoolYearId,studentId},...config});
    }

    /**
      * 获取考试年级下拉框 
      */ 
    public static getExamSections(examId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/exam/sections`, { params:{examId},...config});
    }

    /**
      * 获取学生列表 
      */ 
    public static getExamStudents(examStudentQuery: M.ExamStudentQuery, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.ExamStudentResponse>>(`/exam/students`, { params:examStudentQuery,...config});
    }

    /**
      * 获取考试评语年级下拉框 
      */ 
    public static getSectionDropDownByExamComment(examId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/exam/comment/section`, { params:{examId},...config});
    }

    /**
      * 获取学生评语列表 
      */ 
    public static getStudentsComments(examId: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.TeacherCommentResponse[]>(`/exam/comment`, { params:{examId,studentId},...config});
    }

    /**
      * 获取学生成绩列表 
      */ 
    public static getStudentsResults(examId: number, courseId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.ClassResultResponse>(`/exam/result`, { params:{examId,courseId},...config});
    }

    /**
      * 获取成绩单详情 
      */ 
    public static getTranscript(examId: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentTranscriptResponse>(`/exam/transcript`, { params:{examId,studentId},...config});
    }

    /**
      * 保存学生评语 
      */ 
    public static saveStudentComments(request: M.TeacherCommentRequest, config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/exam/comment`,request, config);
    }

    /**
      * 保存学生成绩 
      */ 
    public static saveStudentResults(request: M.ClassResultRequest, config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/exam/result`,request, config);
    }


}
export class NoticeController {

    /**
      * 删除公告 
      */ 
    public static delete(noticeId: number, config?: AxiosRequestConfig ) {
      return fetch.delete<void>(`/notice/${noticeId}`, { params:{},...config});
    }

    /**
      * 部门下拉框数据 
      */ 
    public static departDropDown(config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/notice/depart`,config);
    }

    /**
      * 部门下拉框数据 
      */ 
    public static departsAllDropDown(config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/notice/depart/all`,config);
    }

    /**
      * 获取公告详情 
      */ 
    public static getNotice(noticeId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.NoticeResponse>(`/notice/${noticeId}`, { params:{},...config});
    }

    /**
      * 个人公告列表接口 
      */ 
    public static getNoticePersonal(query: M.NoticeQueryRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.ReadInfoNoticeItemResponse>>(`/notice/personal`, { params:query,...config});
    }

    /**
      * 获取公告列表(学校) 
      */ 
    public static getNotices(query: M.NoticeQueryRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.NoticeItemResponse>>(`/notice`, { params:query,...config});
    }

    /**
      * 获取回收站公告列表(学校) 
      */ 
    public static getRecycledNotices(query: M.NoticeQueryRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.NoticeItemResponse>>(`/notice/recycled`, { params:query,...config});
    }

    /**
      * 更新回收标记 
      */ 
    public static recycle(noticeId: number, recycled: boolean, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/notice/${noticeId}/recycle/${recycled}`, {}, { params:{},...config});
    }

    /**
      * 保存公告 
      */ 
    public static saveNotice(request: M.NoticeRequest, config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/notice`,request, config);
    }

    /**
      * 发送测试邮件 
      */ 
    public static sendNoticeDemo(request: M.NoticeRequest, config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/notice/sendDemo`,request, config);
    }

    /**
      * 设置已读 
      */ 
    public static setRead(noticeId: number, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/notice/${noticeId}/read`, {}, { params:{},...config});
    }

    /**
      * 更新置顶状态 
      */ 
    public static sticky(noticeId: number, sticky: boolean, stickyOverTime?: number, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/notice/${noticeId}/sticky/${sticky}`, {}, { params:{stickyOverTime, },...config});
    }

    /**
      * tag下拉狂 
      */ 
    public static tagDropdown(config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/notice/tag`,config);
    }


}
export class HomeworkController {

    /**
      * 新增作业 
      */ 
    public static add(request: M.HomeworkRequest, config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/homework/add`,request, config);
    }

    /**
      * 删除作业 
      */ 
    public static delete(homeworkId: number, config?: AxiosRequestConfig ) {
      return fetch.delete<void>(`/homework/delete`, { params:{homeworkId},...config});
    }

    /**
      * 获取作业详情 
      */ 
    public static detail(homeworkId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.HomeworkResponse>(`/homework/detail`, { params:{homeworkId},...config});
    }

    /**
      * 获取作业列表 
      */ 
    public static getList(request: M.HomeworkPageRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.HomeworkPageResponse>>(`/homework/list`, { params:request,...config});
    }

    /**
      * 获取作业完成情况 
      */ 
    public static getPerformance(homeworkId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.HomeworkPerformanceResponse>(`/homework/performance`, { params:{homeworkId},...config});
    }

    /**
      * 获取作业详情 
      */ 
    public static getStudentHomeworkDetail(homeworkStudentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentHomeworkResponse>(`/homework/student/detail`, { params:{homeworkStudentId},...config});
    }

    /**
      * 发布得分 
      */ 
    public static publishScore(homeworkId: number, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/homework/publishScore`, {}, { params:{homeworkId, },...config});
    }

    /**
      * 发送作业消息提醒 
      */ 
    public static sendMessage(homeworkId: number, config?: AxiosRequestConfig ) {
      return fetch.get<void>(`/homework/sendMessage`, { params:{homeworkId},...config});
    }

    /**
      * 作业评分 
      */ 
    public static setScore(request: M.ScoreRequest, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/homework/setScore`,request, config);
    }

    /**
      * 作业批量评分 
      */ 
    public static setScoreBatch(request: M.ScoreBatchRequest, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/homework/setScoreBatch`,request, config);
    }


}
export class DormitoryAttendanceController {

    /**
      * 批量修改考勤状态 
      */ 
    public static batchUpdate(request: M.DormitoryAttendanceBatchRequest, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/attendance/dormitory/batch`,request, config);
    }

    /**
      * 获取宿舍日考勤分页 
      */ 
    public static getDailyAttendances(request: M.DormitoryAttendancePageRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.DailyDormitoryAttendanceResponse>>(`/attendance/dormitory/daily`, { params:request,...config});
    }

    /**
      * 获取宿舍考勤周考勤分页 
      */ 
    public static getWeeklyAttendances(request: M.DormitoryAttendancePageRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.WeeklyDormitoryAttendanceBodyResponse>(`/attendance/dormitory/weekly`, { params:request,...config});
    }

    /**
      * 添加或修改考勤 
      */ 
    public static update(request: M.DormitoryAttendanceRequest, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/attendance/dormitory/update`,request, config);
    }


}
export class TeacherController {

    /**
      * 查询全部教师 
      */ 
    public static getListAll(config?: AxiosRequestConfig ) {
      return fetch.get<M.TeacherResponse[]>(`/teacher/listAll`,config);
    }

    /**
      * 查询全部教师并按首字母分组 
      */ 
    public static getListAllGroup(config?: AxiosRequestConfig ) {
      return fetch.get<any>(`/teacher/listAllGroup`,config);
    }

    /**
      * 获取用户详情 
      */ 
    public static getTeacherDetail(config?: AxiosRequestConfig ) {
      return fetch.get<M.TeacherDetailResponse>(`/teacher/userDetail`,config);
    }

    /**
      * 修改教师个人信息 
      */ 
    public static update(request: M.TeacherRequest, config?: AxiosRequestConfig ) {
      return fetch.put<M.ResultResponse>(`/teacher/update`,request, config);
    }

    /**
      * 修改密码 
      */ 
    public static updatePassword(request: M.PasswordRequest, config?: AxiosRequestConfig ) {
      return fetch.put<M.ResultResponse>(`/teacher/updatePassword`,request, config);
    }


}
export class ClassPeriodController {

    /**
      * 获取课节下拉框 
      */ 
    public static getClassPeriodDropDown(sectionId: number, schoolYearId?: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/class-period-setting/dropdown`, { params:{sectionId,schoolYearId},...config});
    }

    /**
      * 获取课节下拉框 
      */ 
    public static getClassPeriods(courseId: number, date: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/class-period-setting/dropdown-by-course`, { params:{courseId,date},...config});
    }


}
export class AcademicLevelController {

    /**
      * 获取学术水平数据 
      */ 
    public static getAcademicLevel(pageCurrent: number, pageSize: number, studentId?: number, studentName?: string, studentNum?: string, houseGroupId?: number, sectionIds?: number[], semesterIds?: number[], modified?: boolean, status?: string, addStatus?: string, updatable?: boolean, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.SemesterAcademicLevelResponse>>(`/grade/academic-level`, { params:{pageCurrent,pageSize,studentId,studentName,studentNum,houseGroupId,sectionIds,semesterIds,modified,status,addStatus,updatable},...config});
    }

    /**
      * 学术水平标数据(导出) 
      */ 
    public static getAcademicLevelExport(studentId?: number, studentName?: string, studentNum?: string, houseGroupId?: number, sectionIds?: number[], semesterIds?: number[], modified?: boolean, status?: string, addStatus?: string, updatable?: boolean, config?: AxiosRequestConfig ) {
      return fetch.get<M.AcademicLevelExportResponse[]>(`/grade/academic-level/export`, { params:{studentId,studentName,studentNum,houseGroupId,sectionIds,semesterIds,modified,status,addStatus,updatable},...config});
    }

    /**
      * 查询修改记录 
      */ 
    public static getEditLogs(semesterId: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.AcademicEditLogResponse[]>(`/grade/academic-level/${semesterId}/${studentId}/edit-log`, { params:{},...config});
    }

    /**
      * 未达标数据(导出) 
      */ 
    public static getNotStandingAcademicLevelExport(studentId?: number, studentName?: string, studentNum?: string, houseGroupId?: number, sectionIds?: number[], semesterIds?: number[], modified?: boolean, status?: string, addStatus?: string, updatable?: boolean, config?: AxiosRequestConfig ) {
      return fetch.get<M.AcademicLevelExportResponse[]>(`/grade/academic-level/not-standing/export`, { params:{studentId,studentName,studentNum,houseGroupId,sectionIds,semesterIds,modified,status,addStatus,updatable},...config});
    }

    /**
      * 获取review说明 
      */ 
    public static getReviewDetail(semesterId: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.ReviewStatusDetailResponse>(`/grade/academic-level/${semesterId}/${studentId}/review-detail`, { params:{},...config});
    }

    /**
      * 查询状态说明 
      */ 
    public static getStatusDetail(semesterId: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.AcademicStatusDetailResponse>(`/grade/academic-level/${semesterId}/${studentId}/status-detail`, { params:{},...config});
    }

    /**
      * 更新学术水平 
      */ 
    public static updateAcademicLevel(type: string, semesterId: number, studentId: number, label?: string, config?: AxiosRequestConfig ) {
      return fetch.put<M.ObjectResponse>(`/grade/academic-level/${type}/${studentId}/${semesterId}/`, {}, { params:{label, },...config});
    }

    /**
      * 更新报告成绩 
      */ 
    public static updateReportStatus(semesterId: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/grade/academic-level/${semesterId}/${studentId}/report`, {}, { params:{},...config});
    }


}
export class SsRoomAttendanceController {

    /**
      * 批量修改考勤状态 
      */ 
    public static batchUpdate(request: M.SsRoomAttendanceBatchRequest, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/attendance/ssRoom/batch`,request, config);
    }

    /**
      * 获取自习室日考勤分页 
      */ 
    public static getDailyAttendances(request: M.SsRoomAttendancePageRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.DailySsRoomAttendanceResponse>>(`/attendance/ssRoom/daily`, { params:request,...config});
    }

    /**
      * 获取自习室考勤周考勤分页 
      */ 
    public static getWeeklyAttendances(request: M.SsRoomAttendancePageRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.WeeklySsRoomAttendanceBodyResponse>(`/attendance/ssRoom/weekly`, { params:request,...config});
    }

    /**
      * 当日所有晚自习缺勤学生 
      */ 
    public static nightStudyAbsent(schoolId: number, date: number, config?: AxiosRequestConfig ) {
      return fetch.get<void>(`/attendance/ssRoom/absent`, { params:{schoolId,date},...config});
    }

    /**
      * 添加或修改考勤 
      */ 
    public static update(request: M.SsRoomAttendanceRequest, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/attendance/ssRoom/update`,request, config);
    }


}
export class AddressBookController {

    /**
      * 分页根据部门查询通讯录列表 
      */ 
    public static addressBookList(request: M.AddressBookRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.DepPersonResponse>>(`/address-book/dep-person`, { params:request,...config});
    }

    /**
      * 根据部门id查询部门信息 
      */ 
    public static departmentByDepId(depId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.DepDetailResponse>(`/address-book/department/byDepId`, { params:{depId},...config});
    }

    /**
      * 查询部门列表 
      */ 
    public static departmentList(request: M.DepQueryRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.DepartmentResponse[]>(`/address-book/department`, { params:request,...config});
    }

    /**
      * 获取校内/校外人员详情 
      */ 
    public static detail(personId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.PeronDetailResponse>(`/address-book/dep-person/detail`, { params:{personId},...config});
    }


}
export class SemesterController {

    /**
      * 获取当前学年 
      */ 
    public static getCurrentSchoolYear(config?: AxiosRequestConfig ) {
      return fetch.get<M.SchoolYearResponse>(`/semester/currentSchoolYear`,config);
    }


}
export class TaskGradeController {

    /**
      * 批量编辑评分表 
      */ 
    public static batchUpdateScore(request: M.BatchUpdateScoreRequest[], config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/task-grade/batch-update`,request, config);
    }

    /**
      * 获取学生评分表(life block) 
      */ 
    public static getCCAStudentTaskGrades(schoolYearId: number, courseId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentGradesResult>(`/task-grade/lb`, { params:{schoolYearId,courseId},...config});
    }

    /**
      * 获取规则报告期 
      */ 
    public static getGradePeriodForRule(courseId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.GradePeriodResponse[]>(`/task-grade/gradePeriodForRule`, { params:{courseId},...config});
    }

    /**
      * 获取学生有评分报告发送的学期 
      */ 
    public static getGradingSemesters(studentId: number, schoolYearId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.SemesterResponse[]>(`/task-grade/semester`, { params:{studentId,schoolYearId},...config});
    }

    /**
      * 获取报告详情 
      */ 
    public static getReportDetail(reportId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.ReportDetailResponse>(`/task-grade/report/detail`, { params:{reportId},...config});
    }

    /**
      * 获取课程班级当前学年评分总分计算规则列表 
      */ 
    public static getRoleEntryList(courseId: number, schoolYearId?: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.GradePeriodRuleResponse[]>(`/task-grade/rule`, { params:{courseId,schoolYearId},...config});
    }

    /**
      * 获取评分报告列表 
      */ 
    public static getScoreReports(semesterId: number, pageCurrent: number, pageSize: number, classId?: number, houseId?: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.ScoreReportResponse>>(`/task-grade/report`, { params:{semesterId,pageCurrent,pageSize,classId,houseId},...config});
    }

    /**
      * 获取学生成绩册 
      */ 
    public static getStudentGradeBook(schoolYearId: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.GradeBookResponse>(`/task-grade/grade-book`, { params:{schoolYearId,studentId},...config});
    }

    /**
      * 获取评分表详情 
      */ 
    public static getStudentGradeDetail(taskStudentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentGradeDetail>(`/task-grade/detail`, { params:{taskStudentId},...config});
    }

    /**
      * 获取中学成绩报表 
      */ 
    public static getStudentGradeFinal(query: M.GradeFinalQuery, config?: AxiosRequestConfig ) {
      return fetch.get<M.GradeFinalResponse>(`/task-grade/grade/report`, { params:query,...config});
    }

    /**
      * 学生详情成绩 
      */ 
    public static getStudentGradeSummary(semesterId: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentGradeSummaryResponse>(`/task-grade/student`, { params:{semesterId,studentId},...config});
    }

    /**
      * 获取学生评分表 
      */ 
    public static getStudentTaskGrades(schoolYearId: number, courseId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentGradesResult>(`/task-grade`, { params:{schoolYearId,courseId},...config});
    }

    /**
      * 查询最终成绩计算规则 
      */ 
    public static queryFinalScoreRule(courseId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.TaskFinalScoreRuleResponse>(`/task-grade/final-score-rule`, { params:{courseId},...config});
    }

    /**
      * 保存最终成绩计算规则 
      */ 
    public static saveFinalScore(request: M.TaskFinalScoreRuleRequest, config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/task-grade/final-score-rule`,request, config);
    }

    /**
      * 更新最终成绩评语 
      */ 
    public static updateFinalComment(request: M.FinalCommentRequest, config?: AxiosRequestConfig ) {
      return fetch.put<M.ValueResponse>(`/task-grade/final-comment`,request, config);
    }

    /**
      * 更新最终成绩 
      */ 
    public static updateFinalScore(request: M.FinalScoreRequest, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/task-grade/final-score`,request, config);
    }

    /**
      * 设定所选课程班级当前学年评分总分计算规则 
      */ 
    public static updateGradeRules(request: M.GradeRuleRequest, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/task-grade/rule`,request, config);
    }

    /**
      * 编辑评分表 
      */ 
    public static updateScore(request: M.GradeUpdateRequest, config?: AxiosRequestConfig ) {
      return fetch.put<M.GradeResponse>(`/task-grade`,request, config);
    }


}
export class MessageController {

    /**
      * 新增人员分组 
      */ 
    public static addMemberGroup(request: M.MemberGroupRequest, config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/message/addMemberGroup`,request, config);
    }

    /**
      * 删除发件消息 
      */ 
    public static deleteFrom(messageMasterId: number, config?: AxiosRequestConfig ) {
      return fetch.delete<void>(`/message/deleteFrom`, { params:{messageMasterId},...config});
    }

    /**
      * 删除人员分组 
      */ 
    public static deleteMemberGroup(groupId: number, config?: AxiosRequestConfig ) {
      return fetch.delete<void>(`/message/deleteMemberGroup`, { params:{groupId},...config});
    }

    /**
      * 删除收件消息 
      */ 
    public static deleteTo(messageId: number, config?: AxiosRequestConfig ) {
      return fetch.delete<void>(`/message/deleteTo`, { params:{messageId},...config});
    }

    /**
      * 获取发送消息详情 
      */ 
    public static getFromDetail(messageMasterId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.MessageDetailResponse[]>(`/message/fromDetail`, { params:{messageMasterId},...config});
    }

    /**
      * 获取发件箱列表 
      */ 
    public static getFromList(request: M.MessagePageRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.FromListResponse>>(`/message/fromList`, { params:request,...config});
    }

    /**
      * 获取发件箱id列表 
      */ 
    public static getFromListIds(request: M.MessagePageRequest, config?: AxiosRequestConfig ) {
      return fetch.get<number[]>(`/message/fromListIds`, { params:request,...config});
    }

    /**
      * 查询读取情况 
      */ 
    public static getReadInfo(messageMasterId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.MessageReadResponse>(`/message/readInfo`, { params:{messageMasterId},...config});
    }

    /**
      * 手机端获取收件箱列表 
      */ 
    public static getToAppList(request: M.MessagePageRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.ToListResponse>>(`/message/appToList`, { params:request,...config});
    }

    /**
      * 获取接收消息详情 
      */ 
    public static getToDetail(messageId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.MessageDetailResponse[]>(`/message/toDetail`, { params:{messageId},...config});
    }

    /**
      * 获取收件箱列表 
      */ 
    public static getToList(request: M.MessagePageRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.ToListResponse>>(`/message/toList`, { params:request,...config});
    }

    /**
      * 获取收件箱id列表 
      */ 
    public static getToListIds(request: M.MessagePageRequest, config?: AxiosRequestConfig ) {
      return fetch.get<number[]>(`/message/toListIds`, { params:request,...config});
    }

    /**
      * 查询是否有未读消息 
      */ 
    public static hasUnread(config?: AxiosRequestConfig ) {
      return fetch.get<boolean>(`/message/hasUnread`,config);
    }

    /**
      * 查询系统通知未读数量 
      */ 
    public static hasUnreadSystem(config?: AxiosRequestConfig ) {
      return fetch.get<any>(`/message/hasUnreadSystem`,config);
    }

    /**
      * 查询人员分组详情 
      */ 
    public static memberGroupDetail(groupId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.MemberGroupResponse>(`/message/memberGroupDetail`, { params:{groupId},...config});
    }

    /**
      * 查询人员分组列表 
      */ 
    public static memberGroupList(type: string, config?: AxiosRequestConfig ) {
      return fetch.get<M.MemberGroupResponse[]>(`/message/memberGroupList`, { params:{type},...config});
    }

    /**
      * 发送 
      */ 
    public static sendMessage(request: M.MessageSaveRequest, config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/message/send`,request, config);
    }

    /**
      * 设置消息已读 
      */ 
    public static setRead(messageId: number, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/message/setRead`, {}, { params:{messageId, },...config});
    }

    /**
      * 设置系统消息已读 
      */ 
    public static setReadBatch(config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/message/setReadBatch`,config);
    }

    /**
      * 条件查询消息列表（按是否已读排序） 
      */ 
    public static sortMessageList(request: M.MessagePageRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.MessageInfoResponse>(`/message/sortMessageList`, { params:request,...config});
    }

    /**
      * 查询最新系统通知 
      */ 
    public static sysMessageNotice(config?: AxiosRequestConfig ) {
      return fetch.get<M.SysMessageNoticeResponse>(`/message/sys-message-notice`,config);
    }

    /**
      * 查询指定时间段最新未读消息 
      */ 
    public static unReadMessageCount(minute: number, config?: AxiosRequestConfig ) {
      return fetch.get<any>(`/message/unread-message-count`, { params:{minute},...config});
    }

    /**
      * 查询系统消息未读数量 
      */ 
    public static unreadSystemMessage(config?: AxiosRequestConfig ) {
      return fetch.get<number>(`/message/unreadSystemMessage`,config);
    }

    /**
      * 修改人员分组 
      */ 
    public static updateMemberGroup(request: M.MemberGroupRequest, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/message/updateMemberGroup`,request, config);
    }

    /**
      * 撤回 
      */ 
    public static withdraw(messageMasterId: number, config?: AxiosRequestConfig ) {
      return fetch.put<boolean>(`/message/withdraw`, {}, { params:{messageMasterId, },...config});
    }


}
export class LoginController {

    /**
      * 扫码登陆 
      */ 
    public static LoginByQRCode(rand: string, config?: AxiosRequestConfig ) {
      return fetch.post<M.LoginResponse>(`/login/QRCodeLogin`, {}, { params:{rand, },...config});
    }

    /**
      * 生成二维码 
      */ 
    public static addQRCode(config?: AxiosRequestConfig ) {
      return fetch.post<M.TeacherCodeResponse>(`/login/addQRCode`,config);
    }

    /**
      * 显示二维码 
      */ 
    public static getQRCode(config?: AxiosRequestConfig ) {
      return fetch.get<M.TeacherCodeResponse>(`/login/getQRCode`,config);
    }

    /**
      * 查询所在学校列表 
      */ 
    public static getSchools(config?: AxiosRequestConfig ) {
      return fetch.get<M.SchoolResponse[]>(`/login/schools`,config);
    }

    /**
      * 获取用户信息 
      */ 
    public static getUserInfo(config?: AxiosRequestConfig ) {
      return fetch.get<M.TeacherResponse>(`/login/userInfo`,config);
    }

    /**
      * 登录接口 
      */ 
    public static login(request: M.LoginRequest, config?: AxiosRequestConfig ) {
      return fetch.post<M.LoginResponse>(`/login/`,request, config);
    }

    /**
      * 使用大平台token换取系统token 
      */ 
    public static switchToken(accessToken: string, config?: AxiosRequestConfig ) {
      return fetch.get<M.LoginResponse>(`/login/switchToken`, { params:{accessToken},...config});
    }


}
export class AttendanceController {

    /**
      * 获取当日未考勤的主班级/学院/课程班级/LB 
      */ 
    public static absentStudentOfCurrentDay(schoolId: number, date: number, config?: AxiosRequestConfig ) {
      return fetch.get<void>(`/attendance/absentStudentOfCurrentDay`, { params:{schoolId,date},...config});
    }

    /**
      * 审批通过 
      */ 
    public static approve(request: M.LeaveApplicationAuditRequest, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/attendance/leave-application/approve`,request, config);
    }

    /**
      * 拒绝请假申请 
      */ 
    public static declineApplication(request: M.LeaveApplicationAuditRequest, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/attendance/leave-application/decline`,request, config);
    }

    /**
      * 获取已审批请假申请列表 
      */ 
    public static getApproved(pageCurrent: number, pageSize: number, classId: number, houseId: number, name: string, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.LeaveApplicationItemResponse>>(`/attendance/leave-application/approved`, { params:{pageCurrent,pageSize,classId,houseId,name},...config});
    }

    /**
      * 获取cca课堂考勤列表 
      */ 
    public static getCcaClassAttendances(date: number, courseId: number, classPeriodId: number, pageCurrent: number, pageSize: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.ClassAttendanceResponse>>(`/attendance/class/cca`, { params:{date,courseId,classPeriodId,pageCurrent,pageSize},...config});
    }

    /**
      * 班级考勤统计详情 
      */ 
    public static getClassAttendanceDetail(pageCurrent: number, pageSize: number, start: number, end: number, status: string, id?: number, campusId?: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.SectionAttendanceDetailResponse>>(`/attendance/statistic/class/detail`, { params:{pageCurrent,pageSize,start,end,status,id,campusId},...config});
    }

    /**
      * 获取课堂考勤列表 
      */ 
    public static getClassAttendances(date: number, courseId: number, classPeriodId: number, pageCurrent: number, pageSize: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.ClassAttendanceDetailResponse>(`/attendance/class`, { params:{date,courseId,classPeriodId,pageCurrent,pageSize},...config});
    }

    /**
      * 获取中小学班级列表 
      */ 
    public static getClassList(config?: AxiosRequestConfig ) {
      return fetch.get<M.CascadeResponse[]>(`/attendance/classList`,config);
    }

    /**
      * 课节考勤详情 
      */ 
    public static getClassPeriodAttendanceDetail(pageCurrent: number, pageSize: number, start: number, end: number, sectionId: number, status: string, id?: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.ClassPeriodAttendanceDetailResponse>>(`/attendance/statistic/class-period/detail`, { params:{pageCurrent,pageSize,start,end,sectionId,status,id},...config});
    }

    /**
      * 课节考勤统计 
      */ 
    public static getClassPeriodStatistics(start: number, end: number, sectionId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StatisticInfoResponse>(`/attendance/statistic/class-period`, { params:{start,end,sectionId},...config});
    }

    /**
      * 班级考勤统计 
      */ 
    public static getClassStatistics(start: number, end: number, campusId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StatisticInfoResponse>(`/attendance/statistic/class`, { params:{start,end,campusId},...config});
    }

    /**
      * 获取备注模板 
      */ 
    public static getCommentTemplate(config?: AxiosRequestConfig ) {
      return fetch.get<M.StringResponse>(`/attendance/comment-template`,config);
    }

    /**
      * 课堂考勤统计 
      */ 
    public static getCourseStatistics(date: number, subjectId?: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StatisticInfoResponse>(`/attendance/statistic/course`, { params:{date,subjectId},...config});
    }

    /**
      * 课期考勤报表 
      */ 
    public static getDailyAttendanceStatistics(query: M.DailyAttdStatisticsQuery, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.DailyAttendanceStatisticsResponse>>(`/attendance/daily-attendance/statistics`, { params:query,...config});
    }

    /**
      * 获取日考勤列表 
      */ 
    public static getDailyAttendances(date: number, pageCurrent: number, pageSize: number, houseId?: number, classId?: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.DailyAttendanceResponse>>(`/attendance/daily`, { params:{date,pageCurrent,pageSize,houseId,classId},...config});
    }

    /**
      * 自习室考勤统计详情 
      */ 
    public static getDormitoryAttendanceDetail(pageCurrent: number, pageSize: number, start: number, end: number, status: string, id?: number, type?: string, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.DormitoryAttendanceDetailResponse>>(`/attendance/statistic/dormitory/detail`, { params:{pageCurrent,pageSize,start,end,status,id,type},...config});
    }

    /**
      * 宿舍考勤统计 
      */ 
    public static getDormitoryStatistics(start: number, end: number, session: string, config?: AxiosRequestConfig ) {
      return fetch.get<M.StatisticInfoResponse>(`/attendance/statistic/dormitory`, { params:{start,end,session},...config});
    }

    /**
      * 获取日考勤可以编辑开始时间 
      */ 
    public static getEditStart(config?: AxiosRequestConfig ) {
      return fetch.get<number>(`/attendance/daily/edit`,config);
    }

    /**
      * 学生个人考勤(小学) 
      */ 
    public static getEleStudentStatistics(schoolYearId: number, start: number, end: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.EleDailyAttendanceResponse[]>(`/attendance/statistic/student/ele/${schoolYearId}`, { params:{start,end,studentId},...config});
    }

    /**
      * 学院考勤统计详情 
      */ 
    public static getHouseAttendanceDetail(pageCurrent: number, pageSize: number, start: number, end: number, status: string, id?: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.SectionAttendanceDetailResponse>>(`/attendance/statistic/house/detail`, { params:{pageCurrent,pageSize,start,end,status,id},...config});
    }

    /**
      * 学院考勤统计 
      */ 
    public static getHouseStatistics(start: number, end: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StatisticInfoResponse>(`/attendance/statistic/house`, { params:{start,end},...config});
    }

    /**
      * 幼儿园获取班级考勤数据 
      */ 
    public static getKGClassAttendance(date: number, classId?: number, houseId?: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.KGStudentAttendanceResponse[]>(`/attendance/kg`, { params:{date,classId,houseId},...config});
    }

    /**
      * 获取幼儿园班级列表 
      */ 
    public static getKGClassList(config?: AxiosRequestConfig ) {
      return fetch.get<M.CascadeResponse[]>(`/attendance/kg/classList`,config);
    }

    /**
      * 幼儿园获取学生每月考勤数据 
      */ 
    public static getKGStudentAttendance(studentId: number, date: number, config?: AxiosRequestConfig ) {
      return fetch.get<any>(`/attendance/kg/student`, { params:{studentId,date},...config});
    }

    /**
      * 获取学院考勤周考勤列表 
      */ 
    public static getKGWeeklyAttendances(date: number, pageCurrent: number, pageSize: number, houseId?: number, classId?: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.WeeklyHouseAttendanceResponse>(`/attendance/kg/weekly`, { params:{date,pageCurrent,pageSize,houseId,classId},...config});
    }

    /**
      * 获取待审批请假申请列表 
      */ 
    public static getPending(pageCurrent: number, pageSize: number, classId: number, houseId: number, name: string, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.LeaveApplicationItemResponse>>(`/attendance/leave-application/pending`, { params:{pageCurrent,pageSize,classId,houseId,name},...config});
    }

    /**
      * 自习室考勤统计详情 
      */ 
    public static getSSRAttendanceDetail(pageCurrent: number, pageSize: number, start: number, end: number, status: string, id?: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.SSrAttendanceDetailResponse>>(`/attendance/statistic/ssr/detail`, { params:{pageCurrent,pageSize,start,end,status,id},...config});
    }

    /**
      * 自习室考勤统计 
      */ 
    public static getSSRStatistics(start: number, end: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StatisticInfoResponse>(`/attendance/statistic/ssr`, { params:{start,end},...config});
    }

    /**
      * 年级考勤统计详情 
      */ 
    public static getSectionAttendanceDetail(pageCurrent: number, pageSize: number, start: number, end: number, status: string, id?: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.SectionAttendanceDetailResponse>>(`/attendance/statistic/section/detail`, { params:{pageCurrent,pageSize,start,end,status,id},...config});
    }

    /**
      * 年级考勤统计 
      */ 
    public static getSectionStatistics(start: number, end: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StatisticInfoResponse>(`/attendance/statistic/section`, { params:{start,end},...config});
    }

    /**
      * 获取系统时间以及是否迟到判断 
      */ 
    public static getServerTime(config?: AxiosRequestConfig ) {
      return fetch.get<M.ServerTimeResponse>(`/attendance/server-time`,config);
    }

    /**
      * 课节考勤统计 
      */ 
    public static getStudentCourseStatistics(start: number, end: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StatisticInfoResponse>(`/attendance/statistic/course/student`, { params:{start,end,studentId},...config});
    }

    /**
      * 学生个人考勤 
      */ 
    public static getStudentStatistics(schoolYearId: number, studentId: number, start: number, end: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentDailyAttendanceResponse[]>(`/attendance/statistic/student/${schoolYearId}`, { params:{studentId,start,end},...config});
    }

    /**
      * 学生个人考勤详情 
      */ 
    public static getStudentStatisticsDetail(schoolYearId: number, start: number, end: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentAttendanceStatisticsResponse>(`/attendance/statistic/student/detail/${schoolYearId}`, { params:{start,end,studentId},...config});
    }

    /**
      * 获取学院考勤周考勤列表 
      */ 
    public static getWeeklyAttendances(date: number, pageCurrent: number, pageSize: number, houseId?: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.WeeklyHouseAttendanceResponse>(`/attendance/weekly`, { params:{date,pageCurrent,pageSize,houseId},...config});
    }

    /**
      * 获取课堂考勤周考勤列表 
      */ 
    public static getWeeklyClassAttendances(date: number, courseId: number, classPeriodId: number, pageCurrent: number, pageSize: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.WeeklyClassAttendanceResponse>(`/attendance/weeklyAttendance/class`, { params:{date,courseId,classPeriodId,pageCurrent,pageSize},...config});
    }

    /**
      * 幼儿园考勤打卡 
      */ 
    public static punch(studentId: number, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/attendance/kg/punch`, {}, { params:{studentId, },...config});
    }

    /**
      * 撤销申请 
      */ 
    public static retrieveApplication(leaveApplicationId: number, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/attendance/leave-application/retrieve`, {}, { params:{leaveApplicationId, },...config});
    }

    /**
      * 保存备注模板 
      */ 
    public static saveCommentTemplate(template?: string, config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/attendance/comment-template`,template, config);
    }

    /**
      * 批量创建新申请 
      */ 
    public static saveLeaveApplication(request: M.LeaveApplicationRequest, config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/attendance/leave-application`,request, config);
    }

    /**
      * 幼儿园设置考勤 
      */ 
    public static setAttendanceKG(request: M.PreKGAttendanceUpdateRequest, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/attendance/kg`,request, config);
    }

    /**
      * 幼儿园批量准时 
      */ 
    public static setInTimeBatchKG(date: number, classId?: number, houseId?: number, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/attendance/kg/in-time`, {}, { params:{date, classId, houseId, },...config});
    }

    /**
      * 获取缺席（可谅解/无理由）的学生考勤列表 
      */ 
    public static studentAbsent(schoolId: number, date: number, flag: boolean, config?: AxiosRequestConfig ) {
      return fetch.get<void>(`/attendance/studentAbsent`, { params:{schoolId,date,flag},...config});
    }

    /**
      * 课堂考勤 
      */ 
    public static updateClassAttendance(request: M.StudentClassAttendanceRequest, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/attendance/class`,request, config);
    }

    /**
      * 课堂考勤 
      */ 
    public static updateClassAttendanceBatch(request: M.ClassAttendanceRequest, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/attendance/class/batch`,request, config);
    }

    /**
      * 学生单日考勤 
      */ 
    public static updateDailyAttendance(request: M.StudentDailyAttendanceRequest, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/attendance/daily`,request, config);
    }

    /**
      * 日考勤 
      */ 
    public static updateDailyAttendanceBatch(request: M.AttendanceRequest, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/attendance/daily/batch`,request, config);
    }

    /**
      * 更新请假时间 
      */ 
    public static updateLeaveTime(leaveApplicationId: number, startTime?: number, endTime?: number, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/attendance/${leaveApplicationId}/leaveTime`, {}, { params:{startTime, endTime, },...config});
    }


}
export class MonthlyGradeController {

    /**
      * 报告 
      */ 
    public static genReport(gradePeriodId: number, onlySearch: boolean, config?: AxiosRequestConfig ) {
      return fetch.post<M.GenerateReportResponse>(`/monthly-grade/genReport`, {}, { params:{gradePeriodId, onlySearch, },...config});
    }

    /**
      * 生成报告 
      */ 
    public static genReportForStudent(gradePeriodId: number, studentIds: number[], config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/monthly-grade/genReportForStudent`, {}, { params:{gradePeriodId, studentIds, },...config});
    }

    /**
      * 获取品行表数据 
      */ 
    public static getBehaviorTable(gradePeriodId: number, houseGroupId?: number, classId?: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentBehaviorResponse[]>(`/monthly-grade/behavior-table`, { params:{gradePeriodId,houseGroupId,classId},...config});
    }

    /**
      * 获取评语完成情况 
      */ 
    public static getCommentProgress(type: string, gradePeriodId: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.MonthlyCommentProgressResponse>(`/monthly-grade/comment-progress/${type}`, { params:{gradePeriodId,studentId},...config});
    }

    /**
      * 副校长评语 
      */ 
    public static getDeputyHeadComments(gradePeriodId: number, classId?: number, houseGroupId?: number, studentName?: string, studentNum?: string, config?: AxiosRequestConfig ) {
      return fetch.get<M.MonthlyCommentResponse>(`/monthly-grade/comment/${gradePeriodId}/deputy-head/`, { params:{classId,houseGroupId,studentName,studentNum},...config});
    }

    /**
      * 获取评估等级 
      */ 
    public static getGradeLevels(type: string, config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/monthly-grade/level/${type}`, { params:{},...config});
    }

    /**
      * 获取报告期 
      */ 
    public static getGradePeriods(schoolYearId: number, teacherType?: string, gradePeriodType?: string, config?: AxiosRequestConfig ) {
      return fetch.get<M.CascadeResponse[]>(`/monthly-grade/grade-period`, { params:{schoolYearId,teacherType,gradePeriodType},...config});
    }

    /**
      * 获取报告期 
      */ 
    public static getGradePeriodsByCourseId(schoolYearId: number, courseId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/monthly-grade/grade-period/${courseId}`, { params:{schoolYearId},...config});
    }

    /**
      * 获取导出数据 
      */ 
    public static getMonthlyCommentDetails(gradePeriodId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.MonthlyCommentDetailResponse[]>(`/monthly-grade/detail/${gradePeriodId}`, { params:{},...config});
    }

    /**
      * 评估表 
      */ 
    public static getMonthlyGradeByGPId(monthlyGradePeriodId: number, courseId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentMonthlyGradeResponse[]>(`/monthly-grade/gradeTable/${monthlyGradePeriodId}/${courseId}`, { params:{},...config});
    }

    /**
      * 获取导出数据 
      */ 
    public static getMonthlyGradeExport(gradePeriodId: number, config?: AxiosRequestConfig ) {
      return fetch.get<any>(`/monthly-grade/grade/export`, { params:{gradePeriodId},...config});
    }

    /**
      * 获取评估进度 
      */ 
    public static getMonthlyGradeProgress(gradePeriodId: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.MonthlyGradeProgressResponse>(`/monthly-grade/grade-progress`, { params:{gradePeriodId,studentId},...config});
    }

    /**
      * 评估表 
      */ 
    public static getMonthlyGradeTable(schoolYearId: number, courseId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.MonthlyGradeTableResponse>(`/monthly-grade/gradeTable`, { params:{schoolYearId,courseId},...config});
    }

    /**
      * 获取报告详情 
      */ 
    public static getMonthlyReport(gradePeriodId: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.MonthlyReportResponse>(`/monthly-grade/report/${gradePeriodId}/${studentId}`, { params:{},...config});
    }

    /**
      * 获取报告学生id列表 
      */ 
    public static getMonthlyReportStudentIds(query: M.MonthlyReportSelectAllQuery, config?: AxiosRequestConfig ) {
      return fetch.get<M.MonthlyReportSelectAllResponse[]>(`/monthly-grade/report/student-id`, { params:query,...config});
    }

    /**
      * 获取报告列表 
      */ 
    public static getMonthlyReports(query: M.MonthlyReportQuery, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.MonthlyReportItemResponse>>(`/monthly-grade/report`, { params:query,...config});
    }

    /**
      * 获取报告 
      */ 
    public static getMonthlyReportsByStudent(schoolYearId: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.MonthlyGradeReportItemResponse[]>(`/monthly-grade/monthly-report/by-student`, { params:{schoolYearId,studentId},...config});
    }

    /**
      * 获取评估评语 
      */ 
    public static getStudentComments(gradePeriodId: number, type: string, classId?: number, houseGroupId?: number, courseId?: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.MonthlyCommentResponse>(`/monthly-grade/comment/${gradePeriodId}/${type}`, { params:{classId,houseGroupId,courseId},...config});
    }

    /**
      * 修改报告状态 
      */ 
    public static sendBatch(studentId: number[], gradePeriodId: number, type: string, config?: AxiosRequestConfig ) {
      return fetch.put<M.StudentStatefulResponse[]>(`/monthly-grade/report/sendBatch`, {}, { params:{studentId, gradePeriodId, type, },...config});
    }

    /**
      * 修改品行记录 
      */ 
    public static updateBehaviorRecord(request: M.BehaviorRecordRequest, config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/monthly-grade/behaviorRecord`,request, config);
    }

    /**
      * 更新评语 
      */ 
    public static updateComment(request: M.MonthlyCommentRequest, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/monthly-grade/comment`,request, config);
    }

    /**
      * 更新评估表 
      */ 
    public static updateGrade(courseId: number, gradePeriodId: number, studentId: number, type: string, level?: number, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/monthly-grade/${courseId}/${gradePeriodId}/${studentId}/${type}`, {}, { params:{level, },...config});
    }

    /**
      * 批量更新成绩等级 
      */ 
    public static updateGradeByCourse(courseId: number, gradePeriodId: number, type: string, studentIds: number[], level?: number, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/monthly-grade/${courseId}/${gradePeriodId}/${type}`, {}, { params:{studentIds, level, },...config});
    }

    /**
      * 更新评估表 
      */ 
    public static updateStudentGrade(request: M.StudentGradeRequest, config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/monthly-grade/`,request, config);
    }


}
export class StudentController {

    /**
      * 班级信息 
      */ 
    public static classInfo(schoolYearId: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.ClassInfoResponse[]>(`/student/class-info`, { params:{schoolYearId,studentId},...config});
    }

    /**
      * 查询学生详细信息 
      */ 
    public static detail(studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentDetailResponse>(`/student/detail`, { params:{studentId},...config});
    }

    /**
      * 获取班级和学院列表 
      */ 
    public static getClassAndHouse(teacherId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.ClassAndHouseResponse>(`/student/classAndHouse`, { params:{teacherId},...config});
    }

    /**
      * 查询学生课程信息信息 
      */ 
    public static getCourseInfo(schoolYearId: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentCourseResponse[]>(`/student/course-info`, { params:{schoolYearId,studentId},...config});
    }

    /**
      * 获取导出列表 
      */ 
    public static getExportList(type: number, condition: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.ExportResponse>(`/student/exportList`, { params:{type,condition},...config});
    }

    /**
      * 获取学生列表 
      */ 
    public static getList(request: M.StudentPageRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.StudentPageResponse>>(`/student/list`, { params:request,...config});
    }

    /**
      * 获取我的学生id列表 
      */ 
    public static getMyStudentId(request: M.MyStudentRequest, config?: AxiosRequestConfig ) {
      return fetch.post<M.StudentIdResponse[]>(`/student/student-id`,request, config);
    }

    /**
      * 获取我的学生列表 
      */ 
    public static getMyStudents(request: M.MyStudentRequest, config?: AxiosRequestConfig ) {
      return fetch.post<M.PagedList<M.StudentInfoResponse>>(`/student/my-student`,request, config);
    }

    /**
      * 查询学生家长账户信息 
      */ 
    public static getParents(studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.ParentResponse[]>(`/student/getParents`, { params:{studentId},...config});
    }

    /**
      * 查询学生说明信息 
      */ 
    public static getRemarks(studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentRemarkResponse>(`/student/getRemarks`, { params:{studentId},...config});
    }

    /**
      * 获取学生简要信息 
      */ 
    public static getStudentBriefInfo(studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentBriefDetailResponse>(`/student/studentBriefInfo`, { params:{studentId},...config});
    }

    /**
      * 获取学生id列表 
      */ 
    public static getStudentIds(type: number, condition: number, config?: AxiosRequestConfig ) {
      return fetch.get<number[]>(`/student/studentIds`, { params:{type,condition},...config});
    }

    /**
      * 获取转学信息 
      */ 
    public static getStudentTransInfo(studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentTransInfoResponse>(`/student/trans-info/${studentId}`, { params:{},...config});
    }

    /**
      * 获取转学信息(课程) 
      */ 
    public static getStudentTransOutCourseInfo(studentId: number, schoolYearId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentTransOutCourseInfoResponse[]>(`/student/trans-info/${studentId}/course/${schoolYearId}`, { params:{},...config});
    }

    /**
      * 查询学生教师信息 
      */ 
    public static getTeachersByStudent(studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.TeachersAndTypeResponse[]>(`/student/teachers`, { params:{studentId},...config});
    }

    /**
      * 更新学生头像 
      */ 
    public static updateAvatarUrl(request: M.StudentAvatarRequest, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/student/updateAvatarUrl`,request, config);
    }

    /**
      * 修改学生说明信息 
      */ 
    public static updateStudentRemark(request: M.StudentRemarkRequest, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/student/updateRemark`,request, config);
    }


}
export class MeetingsController {

    /**
      * 获取家长会详情 
      */ 
    public static getMeeting(meetingId: number, teacherId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.MeetingResponse>(`/meetings`, { params:{meetingId,teacherId},...config});
    }

    /**
      * 获取家长会列表 
      */ 
    public static getMeetings(pageCurrent: number, pageSize: number, name: string, teacherId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.MeetingItemResponse>>(`/meetings/list`, { params:{pageCurrent,pageSize,name,teacherId},...config});
    }

    /**
      * 更新可预约时段 
      */ 
    public static updateAppointments(appointmentRequest: M.AppointmentRequest, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/meetings/appointment`,appointmentRequest, config);
    }


}
export class FeatureController {

    /**
      * 获取功能列表 
      */ 
    public static getFeatureList(config?: AxiosRequestConfig ) {
      return fetch.get<M.FeatureResponse>(`/feature/featureList`,config);
    }

    /**
      * 获取功能列表 
      */ 
    public static getFeatureListByEmail(email: string, schoolId: number, config?: AxiosRequestConfig ) {
      return fetch.get<string[]>(`/feature/featureListByEmail`, { params:{email,schoolId},...config});
    }

    /**
      * 是否有指定学生的权限 
      */ 
    public static hasPermission(studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.BooleanResponse>(`/feature/hasPermission`, { params:{studentId},...config});
    }


}
export class SenController {

    /**
      * 新增 
      */ 
    public static add(request: M.SenRequest, config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/sen/add`,request, config);
    }

    /**
      * 删除 
      */ 
    public static delete(senId: number, config?: AxiosRequestConfig ) {
      return fetch.delete<void>(`/sen/delete`, { params:{senId},...config});
    }

    /**
      * 获取sen详情 
      */ 
    public static detail(senId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.SenDetailResponse>(`/sen/detail`, { params:{senId},...config});
    }

    /**
      * 获取作业列表 
      */ 
    public static getList(request: M.SenPageRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.SenPageResponse>>(`/sen/list`, { params:request,...config});
    }

    /**
      * 修改 
      */ 
    public static update(request: M.SenRequest, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/sen/update`,request, config);
    }


}
export class GradeController {

    /**
      * 生成报告 
      */ 
    public static generateReport(onlySearch: boolean, gradePeriodId: number, studentIds?: number[], config?: AxiosRequestConfig ) {
      return fetch.post<M.GenerateReportResponse>(`/grade/grade-report/generate`, {}, { params:{onlySearch, gradePeriodId, studentIds, },...config});
    }

    /**
      * 学年报告评语评语导出 
      */ 
    public static getAnnualReportExport(gradePeriodId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.AnnualCommentExportResponse>(`/grade/comment/annual-report-export`, { params:{gradePeriodId},...config});
    }

    /**
      * 获取学生评估数据列表 
      */ 
    public static getCommentCompleteness(gradePeriodId: number, studentId: number, type: string, config?: AxiosRequestConfig ) {
      return fetch.get<M.CommentProgressResponse>(`/grade/grade-report/comment-completeness`, { params:{gradePeriodId,studentId,type},...config});
    }

    /**
      * 查询评语详情 
      */ 
    public static getCommentDetail(request: M.CommentListRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.CommentDetailResponse>(`/grade/comment/detail`, { params:request,...config});
    }

    /**
      * 评语导出 
      */ 
    public static getCommentExport(gradePeriodId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.CommentExportResponse>(`/grade/comment/export`, { params:{gradePeriodId},...config});
    }

    /**
      * 查询评分详情 
      */ 
    public static getGradeDetail(request: M.GradeDetailRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.GradeDetailResponse>(`/grade/detail`, { params:request,...config});
    }

    /**
      * 获取导出信息 
      */ 
    public static getGradeExport(gradePeriodId: number, courseId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.GradeExportResponse>(`/grade/detail/${gradePeriodId}/${courseId}`, { params:{},...config});
    }

    /**
      * 查询评分标准详情 
      */ 
    public static getGradeLevelDetail(type: string, config?: AxiosRequestConfig ) {
      return fetch.get<M.GradeLevelResponse[]>(`/grade/gradeLevel/detail`, { params:{type},...config});
    }

    /**
      * 获取评估报告详情 
      */ 
    public static getGradeReportDetail(studentId: number, gradePeriodId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.GradeReportResponse>(`/grade/grade-report`, { params:{studentId,gradePeriodId},...config});
    }

    /**
      * 获取评估报告学生id列表 
      */ 
    public static getGradeReportStudentIds(schoolYearId: number, gradePeriodId: number, classId?: number, houseId?: number, name?: string, config?: AxiosRequestConfig ) {
      return fetch.get<M.GradeReportSelectAllResponse[]>(`/grade/grade-report/student-id`, { params:{schoolYearId,gradePeriodId,classId,houseId,name},...config});
    }

    /**
      * 获取评估报告列表 
      */ 
    public static getGradeReportSummaries(pageCurrent: number, pageSize: number, schoolYearId: number, gradePeriodId: number, classId?: number, houseId?: number, name?: string, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.GradeReportSummaryResponse>>(`/grade/grade-report/items`, { params:{pageCurrent,pageSize,schoolYearId,gradePeriodId,classId,houseId,name},...config});
    }

    /**
      * 获取学生评估数据列表 
      */ 
    public static getGradeReports(schoolYearId: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.GradeReportItemResponse[]>(`/grade/grade-report/by-student`, { params:{schoolYearId,studentId},...config});
    }

    /**
      * 获取评分学生列表 
      */ 
    public static getGradeStudents(request: M.GradeStudentRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.GradeStudentResponse[]>(`/grade/gradeStudents`, { params:request,...config});
    }

    /**
      * 初中年度报告 
      */ 
    public static getMidSchoolAnnualReport(studentId: number, gradePeriodId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.MidSchoolAnnualReportResponse>(`/grade/annual-report/mid`, { params:{studentId,gradePeriodId},...config});
    }

    /**
      * 中学报告详情 
      */ 
    public static getMidSchoolReport(gradePeriodId: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.MidSchoolReportDetailResponse>(`/grade/mid-school/grade-report/detail`, { params:{gradePeriodId,studentId},...config});
    }

    /**
      * 获取学生评估数据列表 
      */ 
    public static getNotGradedClasses(gradePeriodId: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.GradeProgressResponse>(`/grade/grade-report/not-graded`, { params:{gradePeriodId,studentId},...config});
    }

    /**
      * 根据评语类型查询报告期下拉 
      */ 
    public static getPeriodPeriodByType(schoolYearId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.GradePeriodResponse[]>(`/grade/gradePeriod/fullSchoolYear`, { params:{schoolYearId},...config});
    }

    /**
      * 获取评估报表 
      */ 
    public static getPrimaryGradeSummary(query: M.PrimaryGradeSummaryQuery, config?: AxiosRequestConfig ) {
      return fetch.post<M.PagedList<M.PrimaryGradeSummaryResponse>>(`/grade/primary-grade-summary`,query, config);
    }

    /**
      * 高中年度报告 
      */ 
    public static getUpperSchoolAnnualReport(studentId: number, gradePeriodId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.UpperSchoolAnnualReportResponse>(`/grade/annual-report/upper`, { params:{studentId,gradePeriodId},...config});
    }

    /**
      * 高中报告详情 
      */ 
    public static getUpperSchoolReport(gradePeriodId: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.UpperSchoolReportDetailResponse>(`/grade/upper-school/grade-report/detail`, { params:{gradePeriodId,studentId},...config});
    }

    /**
      * 保存评语 
      */ 
    public static saveGradeComment(request: M.GradeCommentRequest, config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/grade/saveComment`,request, config);
    }

    /**
      * 保存评分 
      */ 
    public static saveGradeRecord(request: M.GradeRecordRequest, config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/grade/saveGradeRecord`,request, config);
    }

    /**
      * 批量保存评分 
      */ 
    public static saveGradeRecordBatch(request: M.GradeRecordBatchRequest, config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/grade/saveGradeRecordBatch`,request, config);
    }

    /**
      * 发送评估报告 
      */ 
    public static sendBatch(studentId: number[], gradePeriodId: number, config?: AxiosRequestConfig ) {
      return fetch.put<M.StudentStatefulResponse[]>(`/grade/grade-report/sent/batch`, {}, { params:{studentId, gradePeriodId, },...config});
    }

    /**
      * 发送评估报告 
      */ 
    public static sendGradeReport(studentId: number, gradePeriodId: number, config?: AxiosRequestConfig ) {
      return fetch.put<M.StatefulResponse>(`/grade/grade-report/sent`, {}, { params:{studentId, gradePeriodId, },...config});
    }

    /**
      * 同步历史报告期课程班级 
      */ 
    public static syncHistory(config?: AxiosRequestConfig ) {
      return fetch.get<void>(`/grade/sync-history`,config);
    }

    /**
      * 测试复制评估表 
      */ 
    public static testSaveGradeTableHistory(gradePeriodId: number, config?: AxiosRequestConfig ) {
      return fetch.get<void>(`/grade/testSaveGradeTableHistory`, { params:{gradePeriodId},...config});
    }

    /**
      * 更新报告评语 
      */ 
    public static updateGradeComment(gradePeriodId: number, studentId: number, type: string, groupId: number, teacherId: number, content: string, config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/grade/updateComment/${gradePeriodId}/${studentId}/${type}/${groupId}/${teacherId}`,content, config);
    }


}
export class MomentsController {

    /**
      * 删除班级圈 
      */ 
    public static delete(momentId: number, config?: AxiosRequestConfig ) {
      return fetch.delete<void>(`/moments`, { params:{momentId},...config});
    }

    /**
      * 删除草稿 
      */ 
    public static deleteScratch(momentId: number, config?: AxiosRequestConfig ) {
      return fetch.delete<void>(`/moments/scratch`, { params:{momentId},...config});
    }

    /**
      * 教师端点赞 
      */ 
    public static favor(favor: boolean, momentId: number, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/moments/favor`, {}, { params:{favor, momentId, },...config});
    }

    /**
      * 获取单个班级圈(草稿) 
      */ 
    public static getMomentById(momentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.MomentResponse>(`/moments`, { params:{momentId},...config});
    }

    /**
      * 老师获取moments 
      */ 
    public static getMoments(request: M.MomentQueryRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.MomentResponse>>(`/moments/all`, { params:request,...config});
    }

    /**
      * 获取草稿箱列表 
      */ 
    public static getScratch(config?: AxiosRequestConfig ) {
      return fetch.get<M.MomentScratchResponse[]>(`/moments/scratch`,config);
    }

    /**
      * 获取可用学生 
      */ 
    public static getStudents(config?: AxiosRequestConfig ) {
      return fetch.get<M.CascadeResponse[]>(`/moments/student`,config);
    }

    /**
      * 创建新班级圈内容 
      */ 
    public static saveMoment(request: M.MomentRequest, config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/moments`,request, config);
    }

    /**
      * 设置是否分享给家长 
      */ 
    public static shareWithParent(share: boolean, momentId: number, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/moments/share/parent`, {}, { params:{share, momentId, },...config});
    }

    /**
      * 设置是否分享给学生 
      */ 
    public static shareWithStudent(share: boolean, momentId: number, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/moments/share/student`, {}, { params:{share, momentId, },...config});
    }


}
export class CourseController {

    /**
      * 获取课程和cca列表 
      */ 
    public static getCourseAndCcaList(teacherId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.CourseAndCCAResponse>(`/course/courseAndCca`, { params:{teacherId},...config});
    }

    /**
      * 根据学部课科目获取课程班级 
      */ 
    public static getCourseBySectionAndSubject(campusId: number, schoolYearId?: number, subjectId?: number[], config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/course/campus/${campusId}`, { params:{schoolYearId,subjectId},...config});
    }

    /**
      * 获取课程 
      */ 
    public static getCourseScheduleByCampus(campusType: string, config?: AxiosRequestConfig ) {
      return fetch.get<M.CascadeResponse[]>(`/course/course-schedule/${campusType}`, { params:{},...config});
    }

    /**
      * 获取课程班级学生 
      */ 
    public static getCourseStudents(courseId: number, pageCurrent: number, pageSize: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.CourseStudentResponse>>(`/course/students`, { params:{courseId,pageCurrent,pageSize},...config});
    }

    /**
      * 课程班级联下拉列表 
      */ 
    public static getCoursesCascadeBySchoolYear(schoolYearId: number, campusId?: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.CascadeResponse[]>(`/course/cascadeBySchoolYear`, { params:{schoolYearId,campusId},...config});
    }

    /**
      * 课程班级联下拉列表 
      */ 
    public static getCoursesOptionsCascade(config?: AxiosRequestConfig ) {
      return fetch.get<M.CascadeResponse[]>(`/course/cascade`,config);
    }

    /**
      * 获取考勤课程班级级联下拉框 
      */ 
    public static getCoursesOptionsCascadeForAttendance(date: number, type: string, config?: AxiosRequestConfig ) {
      return fetch.get<M.CascadeResponse[]>(`/course/cascade/attendance`, { params:{date,type},...config});
    }

    /**
      * 教授课程班级联下拉列表 
      */ 
    public static getTeachCoursesOptionsCascade(config?: AxiosRequestConfig ) {
      return fetch.get<M.CascadeResponse[]>(`/course/teach/cascade`,config);
    }


}
export class CommonController {

    /**
      * 得到七牛Token 
      */ 
    public static getQiniuEtcToken(config?: AxiosRequestConfig ) {
      return fetch.get<M.TokenResponse>(`/common/qiniuToken`,config);
    }

    /**
      * 得到七牛视频转码Token 
      */ 
    public static getQiniuForVideo(config?: AxiosRequestConfig ) {
      return fetch.get<M.TokenResponse>(`/common/qiniuTokenForVideo`,config);
    }

    /**
      * 保存资源信息 
      */ 
    public static saveResource(request: M.ResourceRequest, config?: AxiosRequestConfig ) {
      return fetch.post<number>(`/common/saveResource`,request, config);
    }

    /**
      * 通用翻译接口 
      */ 
    public static translate(src: string, config?: AxiosRequestConfig ) {
      return fetch.post<M.StringResponse>(`/common/translate`,src, config);
    }


}
export class ClassMaterialController {

    /**
      * 新增课程资料 
      */ 
    public static add(request: M.ClassMaterialRequest, config?: AxiosRequestConfig ) {
      return fetch.post<M.ResultResponse>(`/class/material/add`,request, config);
    }

    /**
      * 删除课程资料 
      */ 
    public static delete(classMaterialId: number, config?: AxiosRequestConfig ) {
      return fetch.delete<void>(`/class/material/delete`, { params:{classMaterialId},...config});
    }

    /**
      * 删除代课资料 
      */ 
    public static deleteSubstituteResource(request: M.SubstituteMaterialRequest, config?: AxiosRequestConfig ) {
      return fetch.delete<void>(`/class/material/deleteSubstituteResource`, { params:request,...config});
    }

    /**
      * 获取课程资料详情 
      */ 
    public static detail(classMaterialId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.ClassMaterialResponse>(`/class/material/detail`, { params:{classMaterialId},...config});
    }

    /**
      * 获取课程资料列表 
      */ 
    public static getDetailList(request: M.ClassMaterialDetailPageRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.ClassMaterialDetailPageResponse>>(`/class/material/detailList`, { params:request,...config});
    }

    /**
      * 获取课程资料列表 
      */ 
    public static getList(request: M.ClassMaterialPageRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.ClassMaterialPageResponse>>(`/class/material/list`, { params:request,...config});
    }

    /**
      * 获取代课资料 
      */ 
    public static getSubstituteResource(classArrangeId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.ResourceResponse[]>(`/class/material/substituteResource`, { params:{classArrangeId},...config});
    }

    /**
      * 获取课程资料已读未读详情 
      */ 
    public static readDetail(classMaterialId: number, config?: AxiosRequestConfig ) {
      return fetch.get<any>(`/class/material/read/detail`, { params:{classMaterialId},...config});
    }

    /**
      * 新增代课资料 
      */ 
    public static saveSubstituteResource(request: M.SubstituteMaterialRequest, config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/class/material/saveSubstituteResource`,request, config);
    }

    /**
      * 修改课程资料 
      */ 
    public static update(request: M.ClassMaterialRequest, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/class/material/update`,request, config);
    }


}
export class GraduationController {

    /**
      * 撤销手动通过 
      */ 
    public static cancelManualPass(studentId: number, courseId: number, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/graduation/cancel-manual-pass`, {}, { params:{studentId, courseId, },...config});
    }

    /**
      * 删除额外课程 
      */ 
    public static deleteExtraCourse(extraCourseId: number, config?: AxiosRequestConfig ) {
      return fetch.delete<void>(`/graduation/extra-course`, { params:{extraCourseId},...config});
    }

    /**
      * 获取学业信息 
      */ 
    public static getStudentAcademicInfo(studentId: number, status?: string, config?: AxiosRequestConfig ) {
      return fetch.get<M.AcademicInfoResponse>(`/graduation/academic-info`, { params:{studentId,status},...config});
    }

    /**
      * 获取毕业标准详情 
      */ 
    public static graduationDetail(settingId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.GraduationStandardResponse>(`/graduation/${settingId}`, { params:{},...config});
    }

    /**
      * 获取毕业标准列表 
      */ 
    public static graduationList(config?: AxiosRequestConfig ) {
      return fetch.get<M.GraduationSettingResponse[]>(`/graduation`,config);
    }

    /**
      * 毕业进度 
      */ 
    public static graduationSchedule(studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.ScheduleResponse>(`/graduation/schedule`, { params:{studentId},...config});
    }

    /**
      * 设置学业信息数据 
      */ 
    public static setAcademicData(config?: AxiosRequestConfig ) {
      return fetch.get<void>(`/graduation/academic-data`,config);
    }

    /**
      * 编辑添加额外课程 
      */ 
    public static setExtraCourse(request: M.GraduationExtraCourseRequest, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/graduation/extra-course`,request, config);
    }

    /**
      * 手动通过 
      */ 
    public static setPassed(studentId: number, courseId: number, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/graduation/mobile-passed`, {}, { params:{studentId, courseId, },...config});
    }

    /**
      * 高中学业管理学生列表 
      */ 
    public static studentAcademics(academicQuery: M.AcademicQuery, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.StudentAcademicResponse>>(`/graduation/academic`, { params:academicQuery,...config});
    }

    /**
      * 获取所有高中学业管理学生id 
      */ 
    public static studentIds(academicQuery: M.AcademicQuery, config?: AxiosRequestConfig ) {
      return fetch.get<number[]>(`/graduation/academic-studentId`, { params:academicQuery,...config});
    }

    /**
      * 查询学生毕业标准信息 
      */ 
    public static studentStandard(studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentStandardInfoResponse>(`/graduation/standard/${studentId}`, { params:{},...config});
    }

    /**
      * 修改学生毕业标准 
      */ 
    public static updateStudentStandard(studentIds: number[], graduationSettingId: number, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/graduation/standard/update`, {}, { params:{studentIds, graduationSettingId, },...config});
    }


}
export class CurriculumController {

    /**
      * 获取空闲教室 
      */ 
    public static getAvailableClassRooms(start: number, end: number, sectionIds?: number[], config?: AxiosRequestConfig ) {
      return fetch.get<M.AvailableClassRoomResponse[]>(`/curriculum/class-room/available`, { params:{start,end,sectionIds},...config});
    }

    /**
      * 获取空闲老师 
      */ 
    public static getAvailableTeachers(request: M.AvailableTeacherRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.AvailableTeacherResponse[]>(`/curriculum/teacher/available`, { params:request,...config});
    }

    /**
      * 获取周课表(教室) 
      */ 
    public static getClassArrangesByClassRoomAndWeek(classRoomId: number, start: number, end: number, config?: AxiosRequestConfig ) {
      return fetch.get<any>(`/curriculum/room`, { params:{classRoomId,start,end},...config});
    }

    /**
      * 获取周课表(学生) 
      */ 
    public static getClassArrangesByStudentAndWeek(schoolYearId: number, studentId: number, start: number, end: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentWeeklyCurriculumResponse>(`/curriculum/student/${schoolYearId}`, { params:{studentId,start,end},...config});
    }

    /**
      * 获取周课表(老师) 
      */ 
    public static getClassArrangesByTeacherAndWeek(teacherId: number, start: number, end: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.PersonalCurriculumResponse>(`/curriculum/teacher`, { params:{teacherId,start,end},...config});
    }

    /**
      * 获取课节时段详情 
      */ 
    public static getClassPeriod(classPeriodSettingId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.ClassPeriodSettingResponse>(`/curriculum/period-detail`, { params:{classPeriodSettingId},...config});
    }

    /**
      * 获取课节时段列表 
      */ 
    public static getClassPeriodSettingList(config?: AxiosRequestConfig ) {
      return fetch.get<M.ClassPeriodSettingItemResponse[]>(`/curriculum/period-list`,config);
    }

    /**
      * 获取个人课表 
      */ 
    public static getPersonalCurriculum(start: number, end: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.PersonalCurriculumResponse>(`/curriculum/teacher/personal`, { params:{start,end},...config});
    }

    /**
      * 获取调课数据（新增调课） 
      */ 
    public static getRearrange(start: number, end: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.RearrangeResponse>(`/curriculum/rearrange/detail`, { params:{start,end},...config});
    }

    /**
      * 获取调课数据（新增调课） 
      */ 
    public static getRearrangeAdmin(userId: number, start: number, end: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.RearrangeResponse>(`/curriculum/admin/rearrange`, { params:{userId,start,end},...config});
    }

    /**
      * 获取单个调课申请详情 
      */ 
    public static getRearrangeById(modificationId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.RearrangeResponse>(`/curriculum/rearrange`, { params:{modificationId},...config});
    }

    /**
      * 管理员获取调课申请列表 
      */ 
    public static getRearrangeLists(request: M.RearrangeQueryRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.RearrangeItemResponse>>(`/curriculum/rearrange/all`, { params:request,...config});
    }

    /**
      * 管理员获取调课老师列表 
      */ 
    public static getTeacherForArrange(config?: AxiosRequestConfig ) {
      return fetch.get<M.TeacherResponse[]>(`/curriculum/rearrange/teachers`,config);
    }

    /**
      * 教师获取调课申请列表 
      */ 
    public static getTeacherRearrangeLists(request: M.RearrangeQueryRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.RearrangeItemResponse>>(`/curriculum/rearrange/all/teacher`, { params:request,...config});
    }

    /**
      * (教师)创建调课申请 
      */ 
    public static rearrange(request: M.RearrangeRequest, config?: AxiosRequestConfig ) {
      return fetch.post<M.StatefulResponse>(`/curriculum/rearrange`,request, config);
    }

    /**
      * (管理员)创建调课申请(调课审批) 
      */ 
    public static rearrangeAdmin(request: M.AdminRearrangeRequest, config?: AxiosRequestConfig ) {
      return fetch.post<M.StatefulResponse>(`/curriculum/rearrange/admin`,request, config);
    }

    /**
      * 调课审批 
      */ 
    public static rearrangeAudit(request: M.RearrangeAuditRequest, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/curriculum/rearrange`,request, config);
    }

    /**
      * 中止单个调课申请详情 
      */ 
    public static stopArrange(modificationId: number, reason: string, config?: AxiosRequestConfig ) {
      return fetch.delete<void>(`/curriculum/rearrange`, { params:{modificationId,reason},...config});
    }


}
export class HonorsAndLeaderShipController {

    /**
      * 删除honor 
      */ 
    public static deleteHonor(honorId: number, config?: AxiosRequestConfig ) {
      return fetch.delete<void>(`/honors-and-leadership/honor`, { params:{honorId},...config});
    }

    /**
      * 删除leadership 
      */ 
    public static deleteLeaderShip(leadershipId: number, config?: AxiosRequestConfig ) {
      return fetch.delete<void>(`/honors-and-leadership/leadership`, { params:{leadershipId},...config});
    }

    /**
      * 获取honor详情 
      */ 
    public static getHonor(honorId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.HonorResponse>(`/honors-and-leadership/honor`, { params:{honorId},...config});
    }

    /**
      * 获取honor类型下拉 
      */ 
    public static getHonorTypes(config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/honors-and-leadership/honor/type`,config);
    }

    /**
      * 获取honor列表 
      */ 
    public static getHonors(studentId: number, pageCurrent: number, pageSize: number, honorTypeId?: number, sectionId?: number, start?: number, end?: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.HonorItemResponse>>(`/honors-and-leadership/honors`, { params:{studentId,pageCurrent,pageSize,honorTypeId,sectionId,start,end},...config});
    }

    /**
      * 获取leadership详情 
      */ 
    public static getLeaderShip(leadershipId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.LeaderShipResponse>(`/honors-and-leadership/leadership`, { params:{leadershipId},...config});
    }

    /**
      * 获取leadership列表 
      */ 
    public static getLeaderShipByStudent(studentId: number, pageCurrent: number, pageSize: number, type?: number, start?: number, end?: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.LeaderShipItemResponse>>(`/honors-and-leadership/leaderships`, { params:{studentId,pageCurrent,pageSize,type,start,end},...config});
    }

    /**
      * 获取leadership类型 
      */ 
    public static getLeadershipTypes(config?: AxiosRequestConfig ) {
      return fetch.get<M.CascadeResponse[]>(`/honors-and-leadership/leadership/type`,config);
    }

    /**
      * 新增honor 
      */ 
    public static saveHonor(request: M.HonorRequest, config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/honors-and-leadership/honor`,request, config);
    }

    /**
      * 新增修改leadership 
      */ 
    public static saveLeadership(request: M.LeadershipRequest, config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/honors-and-leadership/leadership`,request, config);
    }


}
export class CCACurriculumController {

    /**
      * 查询课程详情 
      */ 
    public static detail(courseId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.CCACurriculumResponse>(`/cca/curriculum/detail`, { params:{courseId},...config});
    }

    /**
      * 查询报名信息 
      */ 
    public static getApplyInfo(courseId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.ApplyInfoResponse[]>(`/cca/curriculum/applyInfo`, { params:{courseId},...config});
    }

    /**
      * 条件获取学生LB周课表 
      */ 
    public static getClassArrangesByStudentAndWeek(request: M.CCACurriculumWeeklyRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.CCACurriculumWeeklyResponse>>(`/cca/curriculum/ccaWeekly`, { params:request,...config});
    }

    /**
      * 查询导出信息 
      */ 
    public static getExportInfo(type: string, start?: number, end?: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.CCAExportResponse[]>(`/cca/curriculum/exportInfo`, { params:{type,start,end},...config});
    }

    /**
      * 查询导出学生课程表信息 
      */ 
    public static getExportStudentInfo(start?: number, end?: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.CcaCampusExportResponse[]>(`/cca/curriculum/exportStudentInfo`, { params:{start,end},...config});
    }

    /**
      * 查询导出信息 
      */ 
    public static getUnEnrolled(start?: number, end?: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.CCAExportDayResponse[]>(`/cca/curriculum/unEnrolled`, { params:{start,end},...config});
    }

    /**
      * 列表查询 
      */ 
    public static list(request: M.CCACurriculumPageRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.CCACurriculumPageResponse>>(`/cca/curriculum/list`, { params:request,...config});
    }

    /**
      * 条件查询报表 
      */ 
    public static reportList(request: M.CCACurriculumReportPageRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.CCACurriculumReportPageResponse>>(`/cca/curriculum/reportList`, { params:request,...config});
    }

    /**
      * 查询cca课程班级学生 
      */ 
    public static students(request: M.CCAStudentRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.StudentPageResponse>>(`/cca/curriculum/students`, { params:request,...config});
    }


}
export class CalendarController {

    /**
      * 添加事件 
      */ 
    public static addEvent(request: M.EventRequest, config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/calendar/addEvent`,request, config);
    }

    /**
      * 删除事件 
      */ 
    public static deleteEvent(eventId: number, config?: AxiosRequestConfig ) {
      return fetch.delete<void>(`/calendar/deleteEvent`, { params:{eventId},...config});
    }

    /**
      * 根据月份查询校历 
      */ 
    public static getCalendarByMonth(dateStart: number, dateEnd: number, date: number, forParent: boolean, campusId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.CalendarResponse>(`/calendar/byMonth`, { params:{dateStart,dateEnd,date,forParent,campusId},...config});
    }

    /**
      * 查询事件信息 
      */ 
    public static getEventInfo(eventId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.EventResponse>(`/calendar/getEventInfo`, { params:{eventId},...config});
    }

    /**
      * 设置工作日/假期 
      */ 
    public static setDayType(request: M.HolidaySettingRequest, config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/calendar/setDayType`,request, config);
    }

    /**
      * 修改事件 
      */ 
    public static updateEvent(request: M.EventRequest, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/calendar/updateEvent`,request, config);
    }


}
export class DiaryController {

    /**
      * 删除日记簿 
      */ 
    public static deleteDiary(diaryEntryId: number, studentId?: number, config?: AxiosRequestConfig ) {
      return fetch.delete<void>(`/diary/`, { params:{diaryEntryId,studentId},...config});
    }

    /**
      * 获取日记簿记录详情 
      */ 
    public static getDiaryEntry(diaryEntryId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.DiaryEntryResponse>(`/diary/`, { params:{diaryEntryId},...config});
    }

    /**
      * 根据教师id获取前十条日记簿记录 
      */ 
    public static getDiaryEntryList(config?: AxiosRequestConfig ) {
      return fetch.get<M.DiaryResponse[]>(`/diary/diaryListByTeacherId`,config);
    }

    /**
      * 获取学生日记簿详情 
      */ 
    public static getDiaryEntrySummary(pageCurrent: number, pageSize: number, studentId: number, primaryTypeId?: number, diaryEntryTypeId?: number, start?: number, end?: number, recordTimeStart?: number, recordTimeEnd?: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.DiaryEntryCollectResponse>(`/diary/by-student`, { params:{pageCurrent,pageSize,studentId,primaryTypeId,diaryEntryTypeId,start,end,recordTimeStart,recordTimeEnd},...config});
    }

    /**
      * 获取日记簿子类型 
      */ 
    public static getDiaryEntryTypes(primaryTypeId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.DiaryTypeDropDownResponse[]>(`/diary/entry-type`, { params:{primaryTypeId},...config});
    }

    /**
      * 获取日记簿导出 
      */ 
    public static getDiaryExport(schoolYearId: number, sectionId?: number[], classId?: number, houseId?: number, name?: string, gender?: string, selfStudyRoomId?: number, dormitoryId?: number, primaryTypeId?: number, diaryEntryTypeId?: number, start?: number, end?: number, recordTimeStart?: number, recordTimeEnd?: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.DiaryEntryExportResponse[]>(`/diary/export`, { params:{schoolYearId,sectionId,classId,houseId,name,gender,selfStudyRoomId,dormitoryId,primaryTypeId,diaryEntryTypeId,start,end,recordTimeStart,recordTimeEnd},...config});
    }

    /**
      * 获取日记簿记录类型 
      */ 
    public static getPrimaryTypes(containsHidden?: boolean, allType?: boolean, config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/diary/primary-type`, { params:{containsHidden,allType},...config});
    }

    /**
      * 获取日记簿学生汇总列表 
      */ 
    public static getStudentDiaryEntries(request: M.DiaryEntryQueryRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.StudentDiaryEntryResponse>>(`/diary/entries`, { params:request,...config});
    }

    /**
      * 获取日记簿学生汇总列表 
      */ 
    public static getStudentDiaryInfo(studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentDiaryInfoResponse>(`/diary/student-info`, { params:{studentId},...config});
    }

    /**
      * 保存日记簿记录 
      */ 
    public static saveDiary(diaryEntry: M.DiaryEntryRequest, config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/diary/`,diaryEntry, config);
    }


}
