import Vue from 'vue'
import moment from 'moment-timezone'
import * as AntDes from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less'
import VueLazyLoad from 'vue-lazyload'

import '@/utils/filter'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store/index'
import { i18n } from '@/i18n/i18n'
import { getLocale, setLocale, aDownload, calcI18n, getSchoolInfo } from '@/utils/utils'

import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import config from '../package.json'

moment.tz.setDefault('Asia/Shanghai')

import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'

import 'simditor/styles/simditor.css'
import '@/components/simditor/style.less'

import xss, { escapeAttrValue } from 'xss'
import baiduAnalytics from 'vue-baidu-analytics'

Vue.prototype.xss = html =>
  xss(html, {
    onIgnoreTagAttr: function(tag, name, value, isWhiteAttr) {
      if (name === 'style') {
        // 通过内置的escapeAttrValue函数来对属性值进行转义
        return name + '="' + escapeAttrValue(value) + '"'
      }
    },
  })

// Vue.config.devtools = true
Vue.use(AntDes)

Vue.use(VueLazyLoad, {
  loading: require('@/assets/images/loading.gif'),
})


Vue.prototype.$info = AntDes.Modal.info
Vue.prototype.$error = AntDes.Modal.error
Vue.prototype.$confirm = AntDes.Modal.confirm
Vue.prototype.$message = AntDes.message
Vue.prototype.$notification = AntDes.notification
Vue.prototype.$document = document
Vue.prototype.$aDownload = aDownload

declare module 'vue/types/vue' {
  interface Vue {
    $document: typeof document
    $aDownload: any
  }
}

Vue.config.productionTip = false
Vue.use(Viewer, {
  defaultOptions: {
    title: false,
  },
})

let locale = getLocale()
if (!locale) {
  // const systemLanguage = navigator.language === 'en' ? 'en' : 'zh'
  const systemLanguage = 'en'
  setLocale(systemLanguage)
  store.commit('changeLocale', systemLanguage)
} else {
  store.commit('changeLocale', locale)
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
