






import { Vue, Component, Prop } from 'vue-property-decorator'
import TableContainer from './TableContainer.vue'
import moment from 'moment'
import { CCACurriculumController } from '@/services/request.service'

@Component({
  components: {
    TableContainer,
  },
})
export default class WeekTableForExport extends Vue {
  private studentData: any = []

  private created(): void {
    this.getData()
  }

  private getData(): void {
    this.studentData = []
    const { week, classIds, houseGroupIds, studentName, studentNum } = this.$route.params
    const condition = {
      pageCurrent: 1,
      pageSize: 0,
      startTime: moment(Number(week))
        .startOf('day')
        .valueOf(),
      endTime: moment(Number(week))
        .add(7, 'day')
        .endOf('day')
        .valueOf(),
      classIds: this.formatParam(classIds)
        ? this.formatParam(classIds)
            .split(',')
            .map(item => (item as any) * 1)
        : [],
      houseGroupIds: this.formatParam(houseGroupIds)
        ? this.formatParam(houseGroupIds)
            .split(',')
            .map(item => (item as any) * 1)
        : [],
      studentName: this.formatParam(studentName),
      studentNum: this.formatParam(studentNum),
    }
    CCACurriculumController.getClassArrangesByStudentAndWeek(condition)
      .then(res => {
        this.studentData = res.data.items
      })
      .catch(err => console.log(err))
  }

  private formatParam(val): any {
    return val === 'null' ? undefined : val
  }
}
