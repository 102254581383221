import Axios from 'axios'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import Vue from 'vue'
import _ from 'lodash'
import jspdf from 'jspdf'
import html2canvas from 'html2canvas'
import { codeByFeature, i18nBySchool } from '@/constant/constant'
import { message } from 'ant-design-vue'

export function getToken() {
  const tokenString = localStorage.getItem('token')
  if (tokenString) {
    const { token, expireTime } = JSON.parse(tokenString)
    const now = new Date().getTime()
    if (expireTime >= now) {
      return token
    } else {
      removeToken()
      return null
    }
  } else {
    const tempToken = sessionStorage.getItem('token')
    if (tempToken) {
      return tempToken
    } else {
      removeToken()
      return null
    }
  }
}

export function validateVersion(immediate = false, cb?) {
  let date = new Date().valueOf()
  const last = sessionStorage.getItem('lastValid')
  if (!last || date - Number(last) > 5 * 60 * 1000 || immediate) {
    sessionStorage.setItem('lastValid', date.valueOf().toString())
    fetch(`/version.json?t=${date}`, { method: 'GET' })
      .then(res => {
        if (!res.ok) return
        res
          .json()
          .then(res => {
            const currentVersion = localStorage.getItem('version')
            if (currentVersion !== res.version) {
              localStorage.setItem('version', res.version)
              localStorage.setItem('updateVis', 'false')
              window.location.reload()
            } else {
              cb?.()
            }
          })
          .catch(err => cb?.())
      })
      .catch(err => cb?.())
  } else {
    cb?.()
  }
}

export function setToken(token: string, remember: boolean) {
  if (remember) {
    removeToken()
    const day = 30
    const expireTime = new Date().getTime() + day * 24 * 3600 * 1000
    localStorage.setItem('token', JSON.stringify({ token, expireTime }))
  } else {
    removeToken()
    sessionStorage.setItem('token', token)
  }
}

export function setQRcode(qrcode) {
  removeQRcode()
  localStorage.setItem('qrcode', JSON.stringify(qrcode))
}

export function removeQRcode() {
  localStorage.removeItem('qrcode')
}

export function getQRcode() {
  const qrcode = localStorage.getItem('qrcode')
  if (qrcode) {
    return JSON.parse(qrcode)
  } else {
    return null
  }
}

export function removeToken() {
  localStorage.removeItem('token')
  sessionStorage.removeItem('token')
}

export function getSchoolInfo() {
  const schoolInfo = localStorage.getItem('schoolInfo')
  if (schoolInfo) {
    return JSON.parse(schoolInfo)
  } else {
    return null
  }
}

export function setSchoolInfo(schoolInfo) {
  removeSchoolInfo()
  localStorage.setItem('schoolInfo', JSON.stringify(schoolInfo))
  window.location.replace(window.location.origin)
}

export function removeSchoolInfo() {
  localStorage.removeItem('schoolInfo')
}

export function getUserAuth() {
  const userAuth = localStorage.getItem('userAuth')
  if (userAuth) {
    return JSON.parse(userAuth)
  } else {
    return null
  }
}

export function setUserAuth(userAuth) {
  removeUserAuth()
  localStorage.setItem('userAuth', JSON.stringify(userAuth))
}

export function removeUserAuth() {
  localStorage.removeItem('userAuth')
}

export function getLocale() {
  return localStorage.getItem('locale')
}

export function setLocale(locale: string) {
  return localStorage.setItem('locale', locale)
}

export function removeDuplicateTeacher(allTeacherGroup, ...singleTeacherGroup) {
  let groupList = [...singleTeacherGroup]
  let existTeacherId = [] as any
  groupList.forEach(group => {
    existTeacherId = existTeacherId.concat(group.map(teacher => teacher.id))
  })

  return allTeacherGroup.filter(teacher => !existTeacherId.includes(teacher.id))
}

export function clearEmptyArray(array) {
  return array.map(item => {
    if (item.subOptions && item.subOptions.length) {
      item.subOptions = clearEmptyArray(item.subOptions)
      return item
    } else {
      return {
        ...item,
        subOptions: undefined,
      }
    }
  })
}

export function getRefWidth(key, content) {
  console.log(content.$refs[key])
  return 1
}

export function toPage(pagination, result, cb) {
  if (!result?.length && pagination.current !== 1) {
    cb(Math.ceil(pagination.total / pagination.pageSize) || 1)
  }
  return -1
}

export function createMergeArray(newNode, newList, children = 'subOptions', splitter = '-'): any {
  if (newNode[children] && newNode[children].length) {
    newNode[children].forEach(item => {
      createMergeArray(
        {
          ...item,
          enValue: `${newNode.enValue}${splitter}${item.enValue}`,
          value: `${newNode.value}${splitter}${item.value}`,
          newKey: `${newNode.newKey || newNode.key}${splitter}${item.key}`,
          lastValue: item.value,
        },
        newList,
        children,
        splitter
      )
    })
  } else {
    newList.push(newNode)
  }
}
export async function handleBatchDownload(array, name, cb) {
  const data = array // 需要下载打包的路径, 可以是本地相对路径, 也可以是跨域的全路径
  const zip = new JSZip()
  const cache = {}
  const promises = [] as Array<any>
  const error = [] as Array<any>
  data.forEach(item => {
    const promise = getFile(item.url)
      .then(res => {
        // 下载文件, 并存成ArrayBuffer对象
        zip.file(item.name, res as any, { binary: true }) // 逐个添加文件
        cache[item.name] = data
      })
      .catch(err => {
        error.push(err)
      })
    promises.push(promise)
  })
  await Promise.allSettled(promises)
  const num = error.length
  message.error(`有${num}个文件下载失败，其余文件已下载完成`)

  zip.generateAsync({ type: 'blob' }).then(content => {
    // 生成二进制流
    saveAs(content, name) // 利用file-saver保存文件
    cb()
  })
}

let fileList: Array<Promise<void>> = []
const pendingList: Array<() => Promise<void>> = []

function checkPendingList(p: Promise<void>) {
  fileList = fileList.filter(f => f !== p)
  pendingList.pop()?.()
}

function retry(cb, time): Promise<any> {
  return new Promise((resolve, reject) => {
    const fn = function() {
      cb()
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          if (time--) {
            console.log(`重试中，剩余${time}次`)
            fn()
          } else {
            console.log('请稍后重试')
            reject(err)
          }
        }) // .catch(err => reject(err))
    }
    fn()
  })
}

export function getFile(url) {
  const fetch = function() {
    return Axios({ method: 'get', url, responseType: 'arraybuffer' })
  }
  return new Promise((resolve, reject) => {
    const func = () => {
      // const p = Axios({ method: 'get', url, responseType: 'arraybuffer' })
      const p = retry(fetch, 3)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.toString())
        })
        .finally(() => checkPendingList(p))
      fileList.push(p)
      return p
    }
    if (fileList.length < (process.env.VUE_APP_ENV === 'PRODUCTION' ? 10 : 5)) {
      func()
    } else {
      pendingList.push(func)
    }
  })
}

export function guid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export function excludeSpecial(s) {
  // 去掉转义字符
  return s.replace(/[/\\'"\b\f\n\r\t]/g, '')
}

export function judgeVnode(obj): boolean {
  let vnode = new Vue().$createElement('span', '')
  let VNode = vnode.constructor
  return obj instanceof VNode
}

export function findCascadeIndex(array, key): any {
  const keyList = transKeyList(array)
  return keyList.find(item => item[item.length - 1] === key)
}

export function transKeyList(array, list: any[] = []) {
  const result: any[] = []
  array.forEach(a => {
    if (a.subOptions && Array.isArray(a.subOptions)) {
      result.push(...transKeyList(a.subOptions, [...list, a.key]))
    } else {
      result.push([...list, a.key])
    }
  })
  return result
}

export function isBase64(str: string) {
  if (!str || str.trim() === '') {
    return false
  }
  try {
    return btoa(atob(str)) === str
  } catch (err) {
    return false
  }
}

export function aDownload(url: string, fileName: string, directDownload) {
  const suffixArray = [
    '.png',
    '.jpeg',
    '.jpg',
    '.pdf',
    '.bmp',
    '.tif',
    '.gif',
    '.pcx',
    '.tga',
    '.exif',
    '.fpx',
    '.svg',
    '.psd',
    '.cdr',
    '.pcd',
    '.dxf',
    '.webp',
  ]
  const index = fileName.lastIndexOf('.')
  const suffix = fileName.slice(index).toLowerCase()
  const enf = encodeURI(fileName)
    .replace(/&/g, '%26')
    .replace(/\+/g, '%2B')
    .replace(/\s/g, '%20')
    .replace(/#/g, '%23')
    .replace(/=/g, '%23')
  if (!directDownload) {
    window.open(suffixArray.includes(suffix) ? url : `${url}?attname=${enf || `download${suffix}`}`)
    return
  }
  var alink = document.createElement('a')
  alink.href = `${url}${directDownload ? `?attname=${enf || `download${suffix}`}` : ''}`
  alink.download = enf
  alink.style.display = 'none'
  document.body.appendChild(alink)
  alink.click()
  alink.remove()
}

export function calcBg(status) {
  const color = {
    intime: '#73d13d',
    late: '#ffc53d',
    excusedLate: '#FF6D00',
    illness: '#85a5ff',
    absent: '#ff4d4f',
    personal: '#ff9c6e',
    examAbsent: '#F759AB',
    holiday: '#b37feb',
    weekendHoliday: '#b37feb',
    noRecords: '#fff',
    exams: '#69C0FF',
    others: '#BFBFBF',
    online: '#46C29A',
  }
  return color[status] || '#fff'
}

export function calcSymbol(status) {
  const attenSym = {
    intime: 'P',
    late: 'L',
    excusedLate: 'EL',
    absent: 'A',
    personal: 'EA',
    examAbsent: 'MA',
    illness: 'S',
    holiday: 'V',
    weekendHoliday: 'V',
    exams: 'E',
    others: 'O',
    noRecords: '- -',
    online: 'OL',
  }
  return attenSym[status] || ''
}

export function calcI18n(lan) {
  const schoolId = (getSchoolInfo() || {}).schoolId?.toString()
  const i18n = Object.keys(i18nBySchool).includes(schoolId)
    ? i18nBySchool[schoolId]
    : i18nBySchool.default
  return i18n[lan]
}

export function filterData(inputValue, path, key) {
  return path.some(option =>
    option?.[key?.label]?.toLowerCase()?.includes(inputValue?.toLowerCase())
  )
}

export function Debounce(time: number = 500) {
  return (target: any, key: string, propertyDescriptor: PropertyDescriptor) => {
    const method = propertyDescriptor.value
    propertyDescriptor.value = _.debounce(method, time)
  }
}

export function html2pdf(element: HTMLElement, filename?: string, options: any = {}) {
  return new Promise(resolve => {
    html2canvas(element, {
      scale: 2, //放大一倍，使图像清晰一点
    }).then(canvas => {
      // 下面可以对生成的canvas进行一系列操作
      // 生成数据
      // const pageData = canvas.toDataURL('image/jpeg', 1.0)
      let ctx = canvas.getContext('2d') as any

      // 图片的大小
      const pdfWidth = 820,
        pdfHeight = 570
      // a5 纸大小 [418, 594]
      // a4 纸大小 [595.28, 841.89]
      const pdf = new jspdf('l', 'pt', 'a4'),
        imgHeight = Math.floor((pdfHeight * canvas.width) / pdfWidth)
      let renderedHeight = 0
      while (renderedHeight < canvas.height) {
        let page = document.createElement('canvas')
        page.width = canvas.width
        page.height = Math.min(imgHeight, canvas.height - renderedHeight) //可能内容不足一页 //用getImageData剪裁指定区域，并画到前面创建的canvas对象中
        ;(page as any)
          .getContext('2d')
          .putImageData(
            ctx.getImageData(
              0,
              renderedHeight,
              canvas.width,
              Math.min(imgHeight, canvas.height - renderedHeight)
            ),
            0,
            0
          )
        pdf.addImage(
          page.toDataURL('image/jpeg', 1.0),
          'JPEG',
          10,
          10,
          pdfWidth,
          Math.min(pdfHeight, (pdfWidth * page.height) / page.width)
        ) //添加图像到页面，保留10mm边距
        renderedHeight += imgHeight
        if (renderedHeight < canvas.height) {
          pdf.addPage() //如果后面还有内容，添加一个空页
        }
      }
      // 保存
      pdf.save(filename || 'template.pdf')
      resolve('success')
    })
  })
}

export function humpFormat(str): any {
  return str.replace(/([A-Z])/g, ' $1').toLowerCase()
}

export function isLegal(val): boolean {
  return val || val === 0
}

export function setFeature(feature): void {
  if (feature) {
    sessionStorage.setItem('currentFeature', feature)
  }
}

export function getFeature(): string {
  const feature = sessionStorage.getItem('currentFeature')
  return feature ? codeByFeature[feature] : undefined
}

export function removeFeature(): void {
  sessionStorage.removeItem('currentFeature')
}
