/*
 * @Author: Chu Yifei
 * @LastEditors: Chu Yifei
 */
export const staticData = {
  helpDocUrl: 'https://www.yuque.com/docs/share/c9e7a276-5899-474f-bf70-42490ce3e7a8?#',
  helpDocUrlCD: 'https://www.yuque.com/docs/share/2cacc902-0d93-44e2-af8d-8a29dca68221?#',
  feedbackMail: 'intschoolsupport@huaercollegiate.com',
}

export default {
  staticData,
}
