import axios, { AxiosRequestConfig, AxiosResponse, AxiosPromise } from 'axios'
import { message } from 'ant-design-vue'
import {
  getToken,
  getSchoolInfo,
  removeToken,
  removeSchoolInfo,
  getLocale,
  getFeature,
} from './utils'
import router from '@/router'
import qs from 'qs'
import { i18n } from '@/i18n/i18n'

const Axios = axios.create({
  timeout: 30000,
  baseURL: process.env.VUE_APP_REQUEST_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
  },
})

// 请求前拦截器
Axios.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    config.paramsSerializer = params => {
      return qs.stringify(params, { allowDots: true, arrayFormat: 'repeat' })
    }
    // 请求前添加token
    const token = getToken()
    const schoolInfo = getSchoolInfo()
    const feature = getFeature()
    config.headers['X-Token'] = token
    config.headers['X-Locale'] = getLocale() === 'enGB' ? 'en' : getLocale()
    if (feature) {
      config.headers['X-Request-For'] = feature
    }
    if (schoolInfo && schoolInfo.schoolId) {
      config.headers['X-SchoolId'] = schoolInfo.schoolId
    }

    return config
  },
  (error: any) => Promise.reject(error)
)

// 请求后拦截器
Axios.interceptors.response.use(
  (response: AxiosResponse) => {
    // const oldVersion = localStorage.getItem('version')
    // const newVersion = response.headers['application-version']
    // if (newVersion !== oldVersion) {
    //   localStorage.setItem('version', newVersion)
    //   top.frames.location.reload(true)
    // }
    return response
  },
  (error: any) => {
    if (!error.response && !error.message) {
      message.error('Network Error 网络错误')
      return Promise.reject(error)
    }
    if (error.response) {
      if (error.response.status === 400) {
        const result = error.response.data
        // 自定义异常
        if (result.status !== 200) {
          if (result.status === 401) {
            removeToken()
            removeSchoolInfo()
            redirectLogin()
            // router.push({name: 'login'}).catch(err => {})
            return Promise.reject(error)
          } else if (result.status === 1011) {
            message.error(result.error)
            removeToken()
            removeSchoolInfo()
            router.push({ name: 'login' }).catch(err => {})
            return Promise.reject(error)
          } else {
            const status = Object.keys(i18n.getLocaleMessage('zh').errorCode)
            status.includes(result.status + '')
              ? message.error(i18n.tc(`errorCode.${result.status}`))
              : message.error(result.error)
            return Promise.reject(error)
          }
        }
      } else if (error.response.status === 401) {
        message.error('401: Unauthorized 未授权', 3, () => {
          removeToken()
          removeSchoolInfo()
          router.push({ name: 'login' }).catch(err => {})
        })
        return Promise.reject(error)
      } else if (error.response.status === 404) {
        message.error('404: Not Found 地址不存在')
        return Promise.reject(error)
      } else {
        message.error('500: Server Exception 服务器异常')
        return Promise.reject(error)
      }
    }
    // reject
    message.error(error.message)
    return Promise.reject(error)
  }
)

const redirectLogin = () => {
  router.push({ name: 'login' })
}

declare module 'axios' {
  export interface AxiosInstance {
    delete<T = any>(url: string, config?: AxiosRequestConfig): AxiosPromise<T>
    head<T = any>(url: string, config?: AxiosRequestConfig): AxiosPromise<T>
  }
}

export default Axios
