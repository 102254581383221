

















































import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { RouteRecord } from 'vue-router'
import { getSchoolInfo } from '@/utils/utils'
import { mapState } from 'vuex'
import { whiteList } from '@/router'
import { pcMenu } from '@/constant/constant'
import cloneDeep from 'lodash/cloneDeep'

@Component
export default class PCMenu extends Vue {
  @Prop({ default: false }) private readonly collapsed!: boolean
  private menuKeys: Array<string> = []
  private menuOpenKeys: Array<string> = []

  private get subRoutes(): any {
    if (this.$store.state.routes[0]) {
      return this.$store.state.routes[0].children.filter(child => !child.hidden)
    } else {
      return []
    }
  }

  private get unreadStatus(): any {
    return this.$store.state.unreadStatus || !!this.$store.state.unreadSystemNum
  }

  private get userAuths(): any {
    return this.$store.state.routeAuths
  }

  private get routes(): Array<any> {
    const router = this.$route as any
    return router.matched.filter((item: RouteRecord) => item.meta && item.meta.routerName)
  }

  private get frameType(): string {
    return (getSchoolInfo() || {}).frameType
  }

  private get realMenus(): any {
    let allMenu = cloneDeep(
      Object.keys(pcMenu).includes(this.schoolId.toString())
        ? pcMenu[this.schoolId]
        : pcMenu.default
    )
    let menuList = allMenu.filter(
      menu =>
        this.userAuths.includes(menu.name) ||
        (this.userAuths.includes(menu.redirect) && menu.redirect) ||
        whiteList.includes(menu.name)
    )
    menuList.forEach(menu => {
      const markChildren = menu.children || []
      let specialMenu = ['message']
      if (specialMenu.includes(menu.name)) {
        switch (menu.name) {
          case 'message':
            menu.children = [
              {
                name: 'inbox',
                params: {
                  mesType: 'normal',
                },
                showDot: true,
              },
              {
                name: 'outbox',
              },
            ]
            break
        }
      } else {
        menu.children = (menu.children || []).filter(
          childMenu =>
            this.userAuths.includes(childMenu.name) ||
            (childMenu.redirect && this.userAuths.includes(childMenu.redirect))
        )
      }
      if (markChildren.length && !menu.children.length) {
        menu.noChildHidden = true
      }
    })
    return menuList.filter(item => !item.noChildHidden)
  }

  private get schoolId(): number {
    return (getSchoolInfo() || {}).schoolId
  }

  private get menus(): Array<object> {
    return this.subRoutes
  }

  private get rootMenuOpenKeys(): Array<string> {
    const routes = Object.values(this.subRoutes) as any
    return routes.map(route => route.name)
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(): void {
    const routes = this.routes
    this.menuOpenKeys = routes[2]
      ? [routes[1].name, routes[0].name]
      : routes[1]
      ? [routes[0].name]
      : []
    this.menuKeys = routes[2]
      ? [routes[2].name, routes[1].name, routes[0].name]
      : routes[1]
      ? [routes[1].name, routes[0].name]
      : [routes[0].name]
  }

  private onOpenChange(openKeys): void {
    const latestOpenKey = openKeys.find(key => this.menuOpenKeys.indexOf(key) === -1)
    if (this.rootMenuOpenKeys.indexOf(latestOpenKey) === -1) {
      this.menuOpenKeys = openKeys
    } else {
      const children = this.subRoutes.filter(route => route.name === latestOpenKey)[0].children
      const childrenArray = children.map(child => child.name)
      this.menuOpenKeys = latestOpenKey ? [latestOpenKey, ...childrenArray] : []
    }
  }

  private clickMenu(menu): void {
    this.$router.push({ name: menu.name, params: menu.params }).catch(err => {})
  }

  // private clickLeftMenu({ key }: { key: string }): void {
  //   this.$router.push({ name: key }).catch((err) => {})
  // }

  private calcType(name): string {
    const nameTypeList = {
      home: 'home',
      myStudent: 'contacts',
      attendance: 'carry-out',
      diary: 'book',
      curriculum: 'profile',
      message: 'mail',
      examMgmt: 'highlight',
      otherApp: 'appstore',
      courseMaterial: 'file-zip',
      assignment: 'snippets',
      moment: 'copyright-circle',
      reportMgmt: 'container',
      monthlyReport: 'container',
      teaching: 'snippets',
      dataForm: 'fund',
      addressBook: 'index-address-book-icon',
      graduation: 'read',
    }
    return nameTypeList[name]
  }
}
