

































































































































































import { Component, Vue } from 'vue-property-decorator'
import {
  setToken,
  setSchoolInfo,
  setLocale,
  setUserAuth,
  excludeSpecial,
  setQRcode,
  removeQRcode,
  getLocale,
} from '@/utils/utils'
import { LoginController } from '@/services/request.service'
import { ssoUtils } from 'sso-utils'
import Home from '@/mobileViews/home/home.vue'

@Component
export default class Login extends Vue {
  private userLoginForm: any
  private accountLoginForm: any
  private verifyCodeTimer = 0
  private verifyLoading = false
  private loginLoading = false
  private schoolVisible = false
  private schoolList: any = []
  private selectedSchoolId: any = 0
  private forgetPasswordVisible = false
  private account = ''
  private email = ''
  private resetSuccess = false
  private resetLoading = false
  private language: string = ''
  private languages: any = {
    zh: '中文',
    en: 'English(American)',
    enGB: 'English(British)',
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  // private get isPc(): boolean {
  //   return this.$store.state.isPc
  // }

  private beforeCreate(): void {
    this.userLoginForm = this.$form.createForm(this)
  }

  private changeLocale(language): void {
    const currentLocale = getLocale()
    // if (currentLocale === 'zh' && language !== 'zh') {
    //   this.$i18n.locale = 'en'
    //   this.$store.commit('changeLocale', 'en')
    //   setLocale('en')
    // } else if (currentLocale === 'en' && language !== 'en') {
    //   this.$i18n.locale = 'zh'
    //   this.$store.commit('changeLocale', 'zh')
    //   setLocale('zh')
    // }

    if (currentLocale === language) return
    this.$store.commit('changeLocale', language)
    setLocale(language)
    this.language = this.languages[language]
  }

  private accountSubmit(e: Event): void {
    // 按钮登录中的效果
    this.loginLoading = true
    e.preventDefault()
    const form = this.userLoginForm
    form.validateFields({ first: true }, (err: any, values: any) => {
      if (!err) {
        const account = excludeSpecial(this.userLoginForm.getFieldValue('account'))
        const password = excludeSpecial(this.userLoginForm.getFieldValue('password'))
        LoginController.login({ account, password })
          .then(res => {
            if (res.data.success) {
              const token = res.data.token
              const schools = res.data.schools
              setToken(token, true)

              const locale = schools[0].schoolId === 8 ? 'enGB' : getLocale() || 'en'
              this.$store.commit('changeLocale', locale)
              setLocale(locale)

              this.getQRcode()
              ssoUtils.removeLoginSource()
              setSchoolInfo(schools[0] || {})
              // this.$router.push({ name: 'main' }).catch(err => {})
              // if(this.isPc){
              // }else{
              //   this.$router.push({name: 'main'}).catch(err => {})
              // }
            } else {
              const account = form.getFieldValue('account')
              form.setFields({
                ['account']: {
                  value: account,
                  errors: [{ message: this.$t(`errorCode.${res.data.resCode}`), field: 'account' }],
                },
              })
            }
          })
          .catch(err => {
            console.error(err)
          })
          .finally(() => {
            this.loginLoading = false
          })
      } else {
        this.loginLoading = false
      }
    })
  }

  private getQRcode(): any {
    LoginController.getQRCode()
      .then(res => {
        if (res.data.rand) {
          setQRcode(res.data)
        } else {
          removeQRcode()
        }
      })
      .catch(err => removeQRcode())
  }

  private mounted() {
    this.language = this.languages[this.locale]
  }
}
