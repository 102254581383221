export default {
  platformName: 'Teacher Platform',
  changeLocale: '语言切换',
  applyAll: 'Apply to all',
  isApplyAll: 'Is it confirmed to be applied throughout the reporting period?',

  staticCode: {
    '1001': 'Unopen',
    '1002': 'Enrolling',
    '1003': 'Enroll Over',
    '1004': 'Published',
    '1011': 'Weekly',
    '1012': 'Biweekly',
    '1013': 'Once',
    '1201': 'Unopen',
    '1202': 'Processing',
    '1203': 'Finished',
    '1204': 'Semester1',
    '1205': 'Semester2',
    '1208': 'Not Open',
    '1209': 'Ongoing',
    '1210': 'Graduated',

    // 学生状态
    '1014': 'Active',
    '1015': 'Graduate',
    '1016': 'Transfered',
    '1017': 'Enrolling',
    '1018': 'Transfering',
  },
  relation: {
    father: 'Father',
    mother: 'Mother',
    patGrandPa: 'Paternal Grandfather',
    patGrandMa: 'Paternal Grandmother',
    matGrandPa: 'Maternal Grandfather',
    matGrandMa: 'Maternal Grandmother',
    other: 'Other',
  },
  router: {
    studentHomeworkDetail: 'Student Task Detail',
    homeworkDetail: 'Task Detail',
    publishHomework: 'Publish Task',
    homework: 'Task',
    studentExamPoints: 'Student Exam Score',
    examMgmt: 'Exam',
    examPoints: 'Exam Score',
    examRemark: 'Exam Remark',
    diaryDetail: 'Student Log Detail',
    myStudent: 'Student',
    myClass: 'My Class',
    myMainClass: 'Homeroom',
    myCourseClass: 'Subject Class',
    myHouse: 'Advisory',
    studentDetail: 'Student Detail',
    attendance: 'Attendance',
    studentAttendance: 'Student Attendance',
    dailyAttendance: 'Daily Attendance',
    CCAClassAttendance: 'Life Block',
    subjectClassAttendance: 'Subject Class',
    classAttendance: 'Class Attendance',
    attendanceStat: 'Attendance Analysis',
    dormitoryAtten: 'Dormitory Attendance',
    studyRoomAtten: 'Night Study Attendance',
    sectionStat: 'Grade',
    houseStat: 'Advisory',
    courseClassStat: 'Subject Class',
    classPeriodStat: 'Class Period',
    diary: 'Student Log',
    addDiary: 'Add Student Log',
    editDiary: 'Edit Student Log',
    curriculum: 'Timetable',
    myCurriculum: 'Timetable',
    myAdjustCurriculum: 'Course Adjustment',
    calendar: 'Calendar',
    message: 'Message',
    inbox: 'Inbox',
    outbox: 'Outbox',
    sendMessage: 'Send Message',
    sentDetail: 'Detail',
    replyMessage: 'Reply Message',
    receivedDetail: 'Detail',
    personal: 'Personal',
    systemMessage: 'System Message',
    adjustCurriculum: 'Timetable Adjustment',
    adjustApproval: 'Adjustment Approval',
    teacherCurriculum: 'Teacher Timetable',
    classroomCurriculum: 'Room Timetable',
    otherApp: 'Other',
    CCAClass: 'Life Block',
    CCAClassDetail: 'Life Block Detail',
    parentsMeeting: "Parents' Meeting",
    parentsMeetingDetail: "Parents' Meeting Detail",
    courseMaterial: 'Class Resource',
    leaveApproval: 'Leave Approval',
    addMaterial: 'Add Resource',
    editMaterial: 'Edit Resource',
    dataForm: 'Data Form',
    attendanceReport: 'Attendance Form',
    lifeBlockReport: 'Life Block Form',
    eGradeReport: 'Elementary Grading Form',
    gradeReport: 'Secondary Grading Form',
    reportType: {
      abnormal: 'Absent Form',
      semester: 'Session Attendance Form',
      course: 'Class Attendance Form',
      subjectClass: 'Subject Class Attendance Form',
      absent: 'Missed Register Form',
      cca: 'Life Block Attendance Form',
      selfStudy: 'Night Study Form',
      dormitory: 'Dormitory Attendance Form',
    },
    mainClass: 'Homeroom',
    advisory: 'advisory',
    subjectClass: 'Subject Class',
    moment: 'Moment',
    addMoment: 'Add Moment',
    editMoment: 'Edit Moment',
    gradeStat: 'Grade',
    advisoryStat: 'Advisory',
    subjectClassStat: 'Subject Class',
    assignment: 'Assignment',
    addAssignment: 'Add Assignment',
    editAssignment: 'Edit Assignment',
    assignmentDetail: 'Assignment Detail',
    grading: 'Grading',
    scoreSheet: 'Gradebook',
    editSen: 'Edit SEN',
    addSen: 'Add SEN',
    sen: 'SEN',
    kindergartenAtten: 'Preschool Attendance',
    materialInCourse: 'MaterialInCourse',
    reportMgmt: 'Reporting',
    eGrade: 'Elementary Grading',
    comment: 'Comments',
    report: 'Report',
    headTeacherRemark: 'Head Teacher',
    advisorRemark: 'Advisor',
    subjectRemark: 'Subject',
    LBRemark: 'Life Block',
    lifeBlockRemark: 'Life Block',
    class: 'Class',
    studentList: 'Student List',
    noAuthAttendance: 'No Auth',
    noAuth: 'No Auth',
    addLeave: 'Add Leave',
    teaching: 'Teaching',
    classPeriod: 'Class Period',
    classStat: 'Class',
    dormitoryStat: 'Dormitory',
    selfStudyStat: 'Night Study',
    addressBook: 'Faculty Directory',
    noticeManage: 'Announcement Management',
    contentManagement: 'Content Management',
    noticeEdit: 'Edit Announcement',
    noticeAdd: 'Add Announcement',
    noticeView: 'Announcement Detail',
    departmentManagement: 'Department Management',
    labelManagement: 'Label Management',
    manageExternalStaff: 'Mange External Staff',
    staffDetail: 'Staff Detail',
    recycleBin: 'Recycle Bin',
    home: 'Home',
    schoolNotice: 'School Announcement',
    noticeList: 'Announcement List',
    monthlyReport: 'Reporting',
    assessment: 'Assessment',
    monthlyComment: 'Comment',
    report2: 'Report',
    conduct: 'Conduct',
    academicStanding: 'Academic Standing',
    academicStandingDetail: 'Academic Standing Detail',
    gradeBook: 'GradeBook',
    deputyHeadRemark: 'Head of Section Remark',
    graduation: 'Upper School Academic Management',
    studentInfo: 'Student Info',
  },
  login: {
    userLogin: 'User Login',
    account: 'Account',
    password: 'Password',
    enterAccount: 'Please enter account',
    enterPassword: 'Please enter password',
    loginAuto: 'Auto login within 7 days',
    forgetPassword: 'Forget Password',
    login: 'Login',
    retrievePassword: 'Retrieve Password',
    next: 'Next',
    backToLogin: 'Back to Login',
    tips:
      'It is recommended to use Google Chrome and the latest version of Edge. Compatible with Safari. It is not recommended to use computers with a resolution lower than 1366*768 and mobile phones to view. Not compatible with IE browser.',
  },
  common: {
    finish: 'Finish',
    batchOperate: 'Batch Operate',
    integral: 'Integral',
    operation: 'Operation',
    textLimit100: 'Limit to 100 characters',
    textLimit50: 'Limit to 50 characters',
    textLimit256: 'Limit to 256 characters',
    textLimit2000: 'Limit to 2000 characters',
    textLimit500: 'Limit to 500 characters',
    selected: 'Selected {total} records',
    submit: 'Submit',
    unsaveConfirm: 'Are you sure leave before content has been saved?',
    clear: 'Clear',
    seq: 'Sequence',
    operations: 'Operations',
    total: 'Total {total} records',
    totalStudent: 'Total {total} students',
    add: 'Add',
    edit: 'Edit',
    delete: 'Delete',
    reset: 'Reset',
    resetPwd: 'Reset Password',
    enter: 'Please enter',
    select: 'Please select',
    confirm: 'Confirm',
    cancel: 'Cancel',
    true: 'Yes',
    false: 'No',
    gender: { male: 'Male', female: 'Female' },
    saveSuccess: 'Save Successfully',
    deleteSuccess: 'Delete Successfully',
    resetSuccess: 'Reset Successfully',
    ignoreSuccess: 'Ignore Successfully',
    save: 'Save',
    all: 'All',
    saveAndAdd: 'Save And Add',
    submitSuccess: 'Submit Successfully',
    terminateSuccess: 'Terminate Successfully',
    adjustSuccess: 'Adjust Successfully',
    attendanceSuccess: 'Set Attendance Successfully',
    view: 'View',
    enterContent: 'Please enter content',
    send: 'Send',
    sendSuccess: 'Send Successfully',
    blankOptions: 'Please check all required fields',
    addSuccess: 'Add Successfully',
    setSuccess: 'Set Successfully',
    batchSelectError: 'Please select students first!',
    publish: 'Publish',
    saveAndPublish: 'Save And Publish',
    pass: 'pass',
    reject: 'reject',
    status: 'Status',
    homeroom: 'Homeroom',
    export: 'Export',
    am: 'Am',
    pm: 'Pm',
    downloadResource: 'Download Class Resource',
    grade: 'Grade',
    advisory: 'Advisory',
    house: 'Advisory',
    subjectClass: 'Subject Class',
    subject: 'Subject Category',
    teacher: 'Teacher',
    operateSuccess: 'Operate Success',
    deleteConfirm: 'Are you sure delete?',
    uploadSuccess: 'Upload Success',
    search: 'Search',
    headTeacher: 'Head Teacher',
    others: 'Others',
    other: 'Other',
    subjectTeacher: 'Subject Teacher',
    courseName: 'Course Name',
    pageTotal: 'Total {total} Items',
    date: 'Date',
    share: 'share',
    parent: 'Parent',
    student: 'Student',
    studentId: 'Student Id',
    unshared: 'Unshared',
    CCAClass: 'Life Block',
    description: 'Description',
    time: 'Time',
    loadMore: 'Load More...',
    noMore: 'No More Data',
    images: 'Images',
    backToHome: 'Back To Home',
    logout: 'Logout',
    name: 'Name',
    type: 'Type',
    term: 'Term',
    schoolYear: 'School Year',
    level: 'Level',
    enterCont: 'Please enter content',
    score: 'Score',
    comment: 'Comment',
    mark: 'Mark',
    color: 'Color',
    percentage: 'Percentage',
    backToPortal: 'Back To Portal',
    cancelEdit: 'Cancel Edit',
    next: 'Next',
    last: 'Last',
    download: 'Download',
    tutor: 'Advisor',
    rejectReason: 'Reason',
    content: 'Content',
    attachment: 'Attachment',
    campus: 'Campus',
    personName: 'Name',
    createTime: 'Create Time',
    exportStudent: 'Export Student',
    exportCurriculum: 'Export Time Table',
    studentName: 'Student Name',
    fold: 'Fold',
    unfold: 'Unfold',
    creator: 'Creator',
    surname: 'Last Name',
    givenName: 'First Name',
    enName: 'English Name',
    cnName: 'Name',
    preview: 'Preview',
    academicAchieve: 'Academic Achievements',
    learningEfforts: 'Learning Efforts',
    class: 'Class',
    unset: 'unset',
    success: 'Success',
    fail: 'Fail',
    sent: 'Sent',
    completed: 'Completed',
    quickEntry: 'Quick Entry',
    dormitory: 'Dormitory',
    studyRoom: 'Night Study',
    classroom: 'Room',
    remark: 'Remark',
    startTime: 'Start Time',
    endTime: 'End Time',
    reason: 'Reason',
    replyDemo: 'Reply Demo',
    copy: 'Copy',
    tbe: 'TBE',
    customGroup: 'Custom Group',
    lifeBlock: 'Life Block',
    copySuccess: 'Copy Succeeded',
    birthday: 'Birthday',
    average: 'Average',
    operator: 'Operator',
    operationTime: 'Operation Time',
    credit: 'Credit',
    filter: 'Filter',
    moreOperations: 'More',
    withdrawPass: 'Withdraw Pass',
  },
  main: {
    quit: 'Quit',
    helpDoc: 'Help Document',
    feedback: 'Feedback',
    feedbackTips:
      'If you encounter technical problems during use, please send an email to the school mailbox:',
  },
  myClass: {
    normal: 'Active',
    WFE: 'Enrolling',
    1017: 'Enrolling,the date of enrollment:{date}',
    1018: 'Transfering,Last Schooltime:{date}',
    transfer: 'Inactive',
    lastDate: 'Last Schooltime',
    transferDate: 'Transfer Date',
    enrollment: 'The date of enrollment',
    transfered: 'Transfered',
    transfering: 'Transfering',
    mainClass: 'Homeroom',
    courseClass: 'Subject Class',
    house: 'Advisory',
    CCAClass: 'Life Block',
    section: 'Grade',
    subject: 'Subject Category',
    sendMessage: 'Send Message',
    name: 'Name',
    studentId: 'Student ID',
    selectSection: 'Please select Grade first',
    selectSubject: 'Please select subject first',
    addDiary: 'Add Student Log',
    studentName: 'Name',
    clear: 'Clear',
    todayCourse: 'Today',
    allCourse: 'All',
    student: {
      points: 'Score',
      studentInfo: 'Student',
      parentInfo: 'Parent',
      curriculumInfo: 'Timetable',
      teacherInfo: 'Teacher',
      classInfo: 'Class',
      eveningStudyRoom: 'Night Study',
      dormitory: 'Dormitory',
      subjectInfo: 'Course',
      diary: 'Student Log',
      attendanceInfo: 'Attendance',
      weeklyAttendance: 'Weekly Attendance',
      attendanceAnalysis: 'Attendance Analysis',
      instruction: 'Instruction',
      chineseName: 'Name (Chinese)',
      englishName: 'Name (English)',
      firstName: 'First Name (pinyin)',
      lastName: 'Last Name (pinyin)',
      gender: 'Gender',
      birthday: 'Birthday',
      idType: 'ID Type',
      idNumber: 'ID Number',
      nationality: 'Nationality(Subject to passport)',
      email: 'Email',
      studentEmail: 'Student Email',
      parentEmail: 'Parent Email',
      CopyStudentEmail: 'Copy Student Email',
      CopyParentEmail: 'Copy Parent Email',
      residentialAddress: 'Residential Address',
      address: 'Address',
      section: 'Grade',
      class: 'Class',
      studentId: 'Student ID',
      house: 'Advisory',
      startYear: 'Start Year',
      departureTime: 'Departure Time',
      schoolBus: 'School Bus',
      route: 'Route',
      placeToMeet: 'Place to Meet',
      accommodation: 'Accommodation',
      platformLoginRight: 'Platform Login',
      loginAccount: 'Login Account',
      password: 'Password',
      resetPassword: 'Reset Password',
      siblingsInfo: 'Sibling',
      childName: 'Child Name',
      firstParent: 'Main Contact',
      secondParent: 'Second Contact',
      parentChineseName: 'Parent/Guardian Chinese Name',
      parentEnglishName: 'Parent/Guardian English Name',
      mobile: 'Mobile',
      relation: 'Relation',
      employer: 'Company',
      position: 'Title',
      subject: 'Subject Category',
      courseClass: 'Subject Class',
      teacher: 'Teacher',
      teacherType: 'Teacher Type',
      teacherName: 'Teacher Name',
      medical: 'Medic',
      diet: 'Food',
      national: 'National',
      scholarship: 'Scholarship',
      media: 'Media',
      totalCourse: '{total} classes this week',
      point: 'Point',
      identification: 'Identification',
      passport: 'Passport',
      other: 'Other',
      idTypeList: {
        '1260': '@:(myClass.student.identification)',
        '1261': '@:(myClass.student.passport)',
        '1262': '@:(myClass.student.other)',
      },
      grading: 'Grading',
      showLabel: 'Show Label',
      admissionTime: 'Admission Time',
      report: 'Report',
      finalScore: 'Final Percentage',
      reportName: 'Report Name',
      gradePeriod: 'Grade Period',
      sent: {
        true: 'Sent',
        false: 'Not send',
      },
      gradeBook: 'Gradebook',
      courseAttenStat: 'Course Attendance Analysis',
      reportType: 'Report Type',
      reportTypeList: {
        midTerm: 'Mid-term Report',
        endTerm: 'End-term Report',
        fullSchoolYear: 'Yearly Report',
        monthly: 'Monthly Report',
        longTerm: 'Long Term Report',
        exam: 'Exam Report',
      },
      curriculum: 'Curriculum',
      classType: 'Class Type',
      className: 'Class Name',
      classTypeEnum: {
        '1251': 'Homeroom',
        '1253': 'Advisory',
        '1256': 'Night Study',
        '1257': 'Dormitory',
      },
      outClass: 'Out Of Class',
    },
    selectWeek: 'Please select the date of the schedule you want to export',
    highestQlfctn: 'Highest Qualification',
    graduationInstitution: 'Graduation Institution',
    weChat: 'WeChat',
  },
  adjustCurriculum: {
    teacher: 'Teacher',
    classroom: 'Room',
    subject: 'Subject Category',
    teacherName: 'Name',
    findFreeTeacher: 'Find Free Teacher',
    viewCourse: 'Class Period',
    teacherTotal: '{total} classes this week, with {substitute} substitute classes',
    findFreeClassroom: 'Find Free Room',
    classroomTotal: '{total} classes this week',
    find: 'Find',
    time: 'Time',
    freePerWeek: 'Free Time per Week',
    selectTime: 'Please select time',
    day: '{day} days',
    hour: '{hour} hours',
    min: '{min} minutes',
    applyTime: 'Apply Time',
    status: 'Status',
    addAdjust: 'Add Adjustment',
    createTime: 'Create Time',
    adjust: 'Adjust',
    terminate: 'Terminate',
    statusList: {
      waitingForAudit: 'Pending',
      rearranged: 'substituted',
      terminated: 'Terminated',
      cancelled: 'canceled',
      expired: 'expired',
    },
    applyAdjust: 'Apply for Adjustment',
    courseTotal: 'Include {total} courses',
    courseTime: 'Course Time',
    courseClass: 'Subject Class',
    section: 'Grade',
    submitApplication: 'Submit',
    substituteTeacher: 'Substitute Teacher',
    applyTeacher: 'Apply Teacher',
    addTeacher: 'Add Teacher',
    addSubstituteTeacher: 'Add Substitute Teacher',
    selectTeacher: 'Please select substitute teachers for all classes',
    selected: 'Selected',
    noCourse: 'There are no courses in the selected time, please select again',
    adjustDetail: 'Adjustment Detail',
    editAdjust: 'Edit Adjustment',
    duplicateTime: 'You have submit an application between {period}, please edit and submit again',
    noRecords: 'There is no subject class between these times, please edit and try again',
    uploadResource: 'Upload Resource',
    upload: 'upload',
    rejectConfirmTitle:
      'The course will be taught by original teacher after refusing this transfer apply, are you sure to refuse?',
    cancelConfirm: 'Are you sure to cancel this apply?',
    cancel: 'Cancel Apply',
    noAdjustCourse: 'There are not adjustable courses',
  },
  attendance: {
    attendance: 'Attendance',
    dailyAttendance: 'Daily Attendance',
    classAttendance: 'Class',
    subjectClassAttendance: 'Subject Class',
    CCAClassAttendance: 'Life Block',
    dormitoryAttendance: 'Dormitory',
    studyRoomAttendance: 'Night Study',
    CCAClass: 'Life Block',
    sectionStat: 'Grade',
    houseStat: 'Advisory',
    courseClassStat: 'Subject Class',
    dormitoryStat: 'Dormitory',
    classStat: 'Class',
    selfStudyStat: 'Night Study',
    date: 'Date',
    section: 'Grade',
    mainClass: 'Homeroom',
    name: 'Name',
    studentId: 'Student ID',
    amAttendance: 'Am',
    amRemark: 'Remark',
    pmAttendance: 'Pm',
    pmRemark: 'Remark',
    house: 'Advisory',
    period: 'Period',
    courseClass: 'Subject Class',
    class: 'Homeroom',
    lastAttendance: 'Last',
    currentAttendance: 'Current',
    remark: 'Remark',
    onTime: 'Punctual',
    selectSection: 'Please select Grade first',
    statusList: {
      intime: 'Present',
      late: 'Late',
      excusedLate: 'Excused Late',
      absent: 'Absent',
      examAbsent: 'Missed Assessment',
      personal: 'Excused Absence',
      illness: 'Sick',
      holiday: 'Vacation',
      exams: 'Exams',
      others: 'Others',
      weekendHoliday: 'Vacation',
      noRecords: 'No Records',
      online: 'Online',
    },
    shortStatus: {
      intime: 'P',
      late: 'L',
      absent: 'A',
      personal: 'EA',
      examAbsent: 'MA',
      illness: 'S',
      holiday: 'V',
      exams: 'E',
      others: 'O',
      noRecords: '--',
      online: 'OL',
    },
    rate: 'Attendance Rate',
    statName: 'Name',
    total: 'Total',
    subject: 'Subject Category',
    addRemark: 'Add Remark',
    remarkLimit: 'The remarks exceeds the limit',
    detailTitle: "{name}'s Attendance",
    statDetailTitle: '{key} Detail',
    batchTip: 'Are you sure to set no records students to punctual?',
    detailTip1:
      '"P" means punctual, "OL" means online, "L" means late, "EL" means excused late,"A" means absent, "EA" means absent(excused leave),"MA" means missed assessment,"S" means sick, "V" means vacation, "E" means exams, "O" means others',
    detailTip2: 'Move mouse on the icon to view remark',
    dailyPeriod: 'Period',
    classPeriod: 'Class',
    editAttendance: 'Edit Attendance',
    weekViewer: 'Week View',
    dayViewer: 'Day View',
    session: 'Session',
    course: 'Course',
  },
  diary: {
    selectReceiver: 'Please select receiver',
    selectTime: 'Please select time',
    createTime: 'Create time',
    selectStudents: 'Please select students!',
    pleaseSelect: 'Please select type!',
    student: 'student',
    parent: 'parent',
    teacher: 'teacher',
    deleteConfirm: 'Are you sure delete?',
    description: 'Describe',
    addTeacher: 'Add teacher',
    addIntroduction: 'Add Introduction',
    injuredpartion: 'Injured partion',
    uploadWaring: 'Upload the size of file limits 100m，not more than 10',
    uploadtext: 'Upload file ({total}/10)',
    inputText: 'Please input confirm content',
    receipt: 'Recipient',
    sendEmail: 'Send Message',
    shareParent: 'Share parent platform',
    shareStudent: 'Share student platform',
    accessory: 'Attachment',
    addStudent: 'Add Student',
    relationstudent: 'Linked Student',
    introduction: 'Remark',
    place: 'place',
    time: 'Time',
    achievement: 'achievement',
    attendance: 'attendance',
    className: 'Homeroom',
    consultation: 'consultation',
    countNum: 'Total',
    detention: 'detention',
    dormitory: 'dormitory',
    incident: 'incident',
    medical: 'medical',
    points: 'Points',
    studentNName: 'Name',
    diaryType: 'Student Log Type',
    childType: 'Subtype',
    score: 'Points',
    section: 'Grade',
    class: 'Class',
    house: 'Advisory',
    studentName: 'Name',
    reset: 'Reset',
    open: 'Open',
    fold: 'Fold',
    unFold: 'Unfold',
    shareHeadTeacher: 'Notify Headteachers',
    shareTutor: 'Notify Advisors',
    housePoint: 'House Points',
    conductPoint: 'Conduct Points',
    exportDiary: 'Export Student Log',
    recordTime: 'Time',
    schoolYear: 'School Year',
    modifyRecord: 'Modify Record',
    emailNoticeTeacher: 'Email Notice to Teacher',
    modifyRecordText: {
      recordDesc: '@:(diary.description)',
      recordPoint: '@:(diary.points)',
      recordPrimaryType: '@:(diary.diaryType)',
      recordTime: '@:(diary.time)',
      recordType: '@:(diary.childType)',
      changeTo: 'changed [{type}] to [{value}]',
      createDiary:
        '{user} created a diary，type：{type}，time：{time}，description：{desc}，point:{point}',
    },
    sendedTeachers: 'Sended',
    waitForSendTeachers: 'Selected for Send',
  },
  message: {
    title: 'Title',
    recipient: 'Recipient | {prefix} Recipient',
    sender: 'Sender',
    sendTime: 'Send Time',
    sendMessage: 'Send Message',
    content: 'Content',
    readStatus: 'Read Status',
    attachments: 'Attachments',
    emailNotice: 'Email Notice',
    importantNotice: 'Mark as Important',
    uploadAttachments: 'Upload Attachments',
    attachmentTip1: 'A single attachment is limited to 100m,',
    attachmentTip2: 'add up to 10 attachments',
    attachmentTip3: 'add up to 5 attachments',
    enterTitle: 'Please enter title',
    titleLimit: 'The title must not exceed 100 characters',
    enterContent: 'Please enter content',
    addTeachers: 'Add Teachers',
    addParents: 'Add Parents',
    addStudents: 'Add Students',
    invalidFileSize: 'A single attachment cannot exceed 100m',
    invalidFileFormat:
      'Only accept files with suffix of doc, docx, xls, xlsx, ppt, pptx, pdf, jpg, jpeg and png',
    receivedTime: 'Received Time',
    reply: 'Reply',
    history: 'History Message',
    recipientError: 'Please select recipient',
    read: 'Read',
    unread: 'Unread',
    selectSendMail: 'Please select whether to send mail',
    notifiedByEmail: 'Notified By Email',
    notNotifiedByEmail: 'Not Notified By Email',
    senTips:
      'There are students waiting to be enrolled in the recipients. Are you sure to send the message to the students/parents?',
    normalMes: 'Normal Message',
    senderType: 'Sender Type',
    messageType: 'Message Type',
    messageTypes: {
      takeLeave: 'Take Leave',
      cancelLeave: 'Cancel Take Leave',
      noRecord: 'No Records',
      missNightStudy: 'Night Study Absent',
      miss: 'Absent',
      changeClass: 'Change Class',
      studentLog: 'Student Log',
    },
    forward: 'Forward',
    setRecGroup: 'Recipient Group Setting',
    allRead: 'All Read',
    allReadConfirm: 'Read {num} messages?',
    recGroup: 'Recipient Group | {prefix} Recipient Group',
    shareParent: 'Notify Parent',
    addGroup: 'Add Group',
    readDetail: 'Read Detail',
    recall: 'Recall',
    recalled: 'Recalled',
    recallSuccess: 'Recall Successfully',
    recallFailed: 'Recall failed, the recipient has read the message.',
    recipientType: 'Recipient Type',
    times: 'Times',
    newMessageNotice: 'New Message Notice',
    receivedMessage: `You have received new messages:`,
    systemMessage: '1 system message | {num} system messages',
    normalMessage: '1 normal message | {num} normal messages',
  },
  homework: {
    contentNotEmptyTip: 'Content Not Empty',
    unlawScoreTip: 'Range in 0-100',
    confirmCompleteMarkTip: 'Please Confirm Complete Mark',
    homeworkTitleName: 'The assignment',
    uploadImgTip: 'Uploading Image...',
    suffixHomeWorkName: 'Task',
    publishScoreConfirm:
      'After successful release, the score cannot be modified. confirm the release?',
    publishScoreSuccess: 'Publish Score Success',
    batchMark: 'Batch Marked',
    finishMark: 'Finish Marked',
    insertFailed: 'Insert Failed',
    uploadImgs: 'Upload Imgs',
    scoreStatus: 'Score Status',
    view: 'View',
    mark: 'Marked',
    scorePublished: { true: 'Published', false: 'Unpublished' },
    studentName: 'Name',
    handInStatus: 'HandIn Status',
    handInTime: 'Hand In Time',
    score: 'Score',
    comments: 'Comments',
    tipOne: 'The deadline is {days} day away',
    tipTwo: '{days} day past the deadline',
    publishPoints: 'Publish Points',
    accessory: 'Attachment',
    remindStudent: 'Remind Student',
    remindSuccess: 'Remind Success',
    complete: 'Complete',
    incomplete: 'incomplete',
    gradeTypeList: {
      '1031': 'No Grade',
      '1032': 'Centesimal',
      '1033': 'Grading',
    },
    selectHandInOnline: 'Please Select HandInOnline',
    selectGradeType: 'Please Select GradeType',
    selectCourse: 'Please Select Course',
    enterHomeworkName: 'Enter Task Name',
    enterHomeworkContent: 'Enter Task Content',
    gradeType: 'Grade Type',
    handInOnline: 'Hand In Online',
    publishHomework: 'Publish Task',
    deadlineTime: 'Deadline',
    statusList: {
      '1011': 'Unsubmit',
      '1012': 'Submited',
      '1013': 'Submited beyond time',
      '1021': 'On going',
      '1022': 'Finished',
    },
    homeContent: 'Home Content',
    name: 'Task Name',
    sectionName: 'Grade',
    subjectName: 'Subject Category',
    courseName: 'Subject Class',
    completeNum: 'Completeness',
    creatorName: 'Creator',
    publishTime: 'Publish Time',
    CCAClass: 'Life Block',
    courseClass: 'Subject Class',
    CCAClassHomework: 'Life Block Task',
    courseClassHomework: 'Subject Class Task',
    handInAfterDead: 'Hand In After Deadline',
    allow: 'Allow',
    disallow: 'Disallow',
    scored: 'Scored',
  },
  exam: {
    commentTip: 'Comment Not Null',
    add: 'Add',
    batchAdd: 'Batch Add',
    addCommentBatch: 'Batch Add Comment',
    batchSelectError: 'Please select students first!',
    finishAndNext: 'FinishAndNext',
    template: 'Template',
    commentNotNull: 'Comment Not Null',
    points: 'Points',
    inputNumberOutRange: 'Input Number Out Range',
    downloadTranscript: 'Export',
    writeComment: 'Write Comment',
    house: 'Advisory',
    teachers: 'Teacher',
    courseClass: 'Subject Class',
    subject: 'Subject Category',
    teacherComment: 'Teacher Comment',
    scholasticAttainment: 'Scholastic Performance',
    addComment: 'Add Comment',
    name: 'Name',
    code: 'Student ID',
    className: 'Homeroom',
    gradings: 'Exam Score',
    comment: 'Comment',
    tipt: 'The exam is finished,only see it',
    tip: 'The exam is locked and cannot be edited. to edit, please contact the administrator',
    year: 'School Year',
    exam: 'Exam',
    section: 'Grade',
  },
  calendar: {
    addSchedule: 'Add Schedule',
    editSchedule: 'Edit Schedule',
    setDate: 'Set working day / vacation',
    parentView: "Enter parents' / students' view",
    closeParentView: "Exit parents' / students' view",
    remark: 'Remark',
    location: 'Location',
    time: 'Time',
    date: 'Date',
    workingDay: 'Working Day',
    holiday: 'Vacation',
    summerHoliday: 'Summer Vacation',
    winterHoliday: 'Winter Vacation',
    visibleToParent: 'Visible to parents and students',
    invisibleToParent: 'Invisible to parents and students',
    holidayPlaceholder: 'Enter vacation name',
    deleteConfirm: 'Are you sure to delete this schedule?',
    semesterStartDate: 'Start of semester',
    semesterEndDate: 'End of semester',
    allDay: 'All Day',
    status: 'Status',
    viewSchedule: 'View Schedule',
    name: 'Name',
  },
  addressBook: {
    name: 'Name',
    displayName: 'Display Name',
    type: 'Type',
    gender: 'Gender',
    position: 'Title',
    email: 'Email',
    switchDisplay: 'Display To Parents and Students',
    editDepartment: 'Edit Department',
    editExternalStaff: 'Edit External Staff',
    addExternalStaff: 'Add External Staff',
    addDepartment: 'Add Department',
    editDepartmetn: 'Edit Departmetn',
    deleteDepartment: 'Delete Department',
    confirmDeleteDepartment: 'Are You Sure TO Delete Department',
    confirmDeleteStaff: 'Are You Sure TO Delete Staff',
    contactNumber: 'Contact Number',
    contactEmail: 'Contact Email',
    introduction: 'Introduction',
    enterName: 'Please input name',
    manageDepartmentMember: 'Manage Department Member',
    externalStaff: 'External Staff',
    manageExternalStaff: 'Manage External Staff',
    staff: 'School Staff',
    addTeacher: 'Add Teacher',
    deleteConfirm: 'Are you sure to delete this staff?',
    trrigerError: 'Fail to display,please try again later',
    enterDepName: 'Please input department name',
    enterlegalEmail: 'Please input legal email',
    enterEmail: 'Please input email',
    teachInfo: 'Teach',
    teacherType: 'Teacher Type',
  },
  personal: {
    passwordUnLaw: 'The password is 8-20 characters and must contain numbers and letters',
    nameValidateTip: 'Fill in at least one chinese and english name',
    enterPosition: 'Please input Title',
    enterSex: 'Please select Gender',
    textLimit50: 'Limit to 50 characters',
    enterName: 'Please input chinese name',
    enterenName: 'Please input english name',
    title: 'Personal Information',
    edit: 'Edit',
    name: 'Chinese name',
    enName: 'English name',
    sex: 'Gender',
    position: 'Title',
    secret: 'Password',
    email: 'email',
    modifySecret: 'Modify Password',
    nowSecret: 'Now Password',
    newSecret: 'New Password',
    confirmSecret: 'Confirm Password',
    inputSecret: 'please input Password',
    inputNewSecret: 'please input new Password',
    inputConfirmSecret: 'please confirm Password',
    wrongPassword: 'Password are not same.',
    passwordLimit: 'Password wrong or type wrong',
  },
  transfer: {
    listTitle: '{title} List',
    selectedNum: 'Selected {num} {title}s',
    deleteAll: 'Delete All',
    selectAll: 'Select All',
    enterName: 'Please enter {title} name',
    ungrouped: 'Ungrouped',
    undivided: 'Undivided',
  },
  weekday: {
    '0': 'Sunday',
    '1': 'Monday',
    '2': 'Tuesday',
    '3': 'Wednesday',
    '4': 'Thursday',
    '5': 'Friday',
    '6': 'Saturday',
    '7': 'Sunday',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
  },
  CCAClass: {
    class: 'Life Block',
    credits: 'Credits',
    mainClass: 'Homeroom',
    status: 'Status',
    age: 'Age',
    studentId: 'Student Id',
    studentName: 'Student',
    enrolledNum: 'Num',
    enrollTime: 'Enroll Time',
    enrollRange: 'Enroll Range',
    classroom: 'Room',
    teacherNames: 'Teacher',
    classTime: 'Time',
    name: 'Life Block',
    selectSchoolYear: 'Please select school year',
    schoolYear: 'School Year',
    classDescription: 'Class Description',
    enterDescriptionText: 'Please Enter Description Content',
    cycle: 'Duration',
    minEnrollNum: 'Minimal',
    maxEnrollNum: 'Maximum',
    selectEnrollTime: 'Please Select Enroll Time',
    selectCycle: 'Please Select Cycle',
    selectClassTime: 'Please Select Time',
    addTeacher: 'Add Teacher',
    selectClassroom: 'Please select Room',
    selectColleges: 'Please Select Colleges',
    selectGrades: 'Please Select Grades',
    college: 'Advisory',
    grade: 'Grade',
    enterMinEnrollNum: 'Please enter min enroll num',
    enterMaxEnrollNum: 'Please enter max enroll num',
    enterName: 'Please Enter Class Name',
    conflictContext:
      'There are conflicts with the room and teachers of the existing schedule, please adjust it and save it.',
    got: 'Got It',
    conflictType: 'Conflict Type',
    conflictTime: 'Conflict Time',
    conflictDateList: 'Conflict Date',
    conflictName: 'Conflict Name',
    selectAtLeastOne: 'Select at least one between college and grade',
    selectTeachers: 'Select at least one teacher',
    addTime: 'Add Time',
    enrolled: 'Enrolled',
    publishConfirm: 'Are you sure to publish?',
    reject: 'Reject',
    recallReject: 'Recall Reject',
    publishSuccess: 'Publish Success',
    arrangeEnroll: 'Arrange Enroll',
    conflictWithCurriculum: 'Exist Conflict With Timetable',
    rejectConfirm: 'Are you sure to reject?',
    recallConfirm: 'Are you sure to recall this reject?',
    remain: 'Remain',
    isCharge: 'Fee',
    chargeQuote: 'Price',
    yes: 'Yes',
    no: 'No',
    noClassroom: 'No Room',
    studentExcel: 'Homeroom Enroll Excel',
    courseExcel: 'Course Enroll Excel',
    schoolCourse: 'School Course',
    outOfSchool: 'Out-Of-School Vendors Course',
    courseType: 'Course Type',
    unenrolledExcel: 'Unenrolled Excel',
    studentCourseExcel: 'Student Course Excel',
    isCourse: 'Is Subject Class',
    teachingType: 'Teaching Type',
    enrolledDetail: 'Enrolled Detail',
    enrollSet: 'Enrolment Settings',
    course: 'Course',
    interval: 'Interval',
    forSelection: 'Autonomous enrolment',
    numMin: 'Minimum',
    numMax: 'Maximum',
    basicSetting: 'Basic Setting',
    courseFlag: 'Subject Class',
    selectWeek: 'Please select the date of the timetable to be exported',
    exportAllCourse: 'Course Information Form',
  },
  parentsMeeting: {
    meetingName: "Parents' Meeting Name",
    status: 'status',
    meetingTime: 'Meeting Time',
    meetingDate: 'Meeting Date',
    reserveBeginTime: 'Reserve Begin Time',
    reservedNum: 'Reserved Num',
    duration: 'Duration',
    interval: 'Interval',
    reservableTime: 'Reservable Time',
    classroom: 'Room',
    selectClassroom: 'Please Select Room',
    reservableStudents: 'Reservable Students',
    reservedPerson: 'Reserved Person',
    reservedSituation: 'Reserved Situation',
    unpublished: 'UnPublish',
    published: 'published',
    teacher: 'Teacher',
    ended: 'Finished',
    started: 'Started',
    DNS: 'Not Start',
    reserveTime: 'Reserve Time',
    apptEnded: 'Book Ended',
    onlineLink: 'Online Meeting Link',
  },
  courseMaterial: {
    name: 'Name',
    courseTime: 'Date',
    course: 'Subject Class',
    instruction: 'Instruction',
    attachment: 'Attachment',
    courseClassMaterial: 'Subject Class Resource',
    CCAClassMaterial: 'Life Block Resource',
    subjectClass: 'Subject Class',
    CCAClass: 'Life Block',
    subject: 'Subject Category',
    creator: 'Creator',
    createTime: 'CreateTime',
    enterName: 'Please Enter Resource Name',
    selectCourseTime: 'Please Select Course Time',
    uploadMaterial: 'Please Upload Class Resources',
    materialDetail: 'Resource Detail',
    SubMaterialDetail: 'Substitute Resource Detail',
    updater: 'Updater',
    updateTime: 'Update Time',
    fileNum: 'File Num',
  },
  leaveApproval: {
    student: 'Name',
    studentId: 'Student Id',
    class: 'Homeroom',
    college: 'Advisory',
    leaveTime: 'Leave Time',
    leaveType: 'Type',
    leaveDuration: 'Duration',
    approving: 'Approving',
    approved: 'Approved',
    approvedLabel: 'Approved',
    pending: 'Approving',
    retrieved: 'Recalled',
    auditRetrieved: 'Recalled',
    declined: 'Rejected',
    leavePerson: 'Applicant',
    leaveStatus: 'Status',
    leaveReason: 'Reason',
    holiday: 'Vacation',
    illness: 'Sick',
    passConfirmTips:
      'After the leave is passed, the attendance status will be automatically synchronized to confirm the approval of the leave, are you sure to pass?',
    rejectConfirm: 'Reject Confirm',
    passConfirm: 'Pass Confirm',
    days: 'Days',
    rejectReason: 'Reason',
    rejectConfirmTitle: 'Are you sure to reject this apply?',
    passConfirmTitle: 'Are you sure to pass this apply?',
    exams: 'Exam',
    others: 'Others',
    personal: 'Excused Leave',
    replyDemo: 'Reply Demo',
    cancel: 'cancel',
    cancelConfirm: 'Are you sure to cancel?',
    startLaterThanEnd: 'Start time is later than end time',
    endEarlierThanStart: 'End time is earlier than start time',
    apply: 'Apply',
    modifyTime: 'Modify Time',
    schoolBus: '@:(myClass.student.schoolBus)',
    accommodation: '@:(myClass.student.accommodation)',
    dormitory: '@:(myClass.student.dormitory)',
  },
  attendanceReport: {
    semesterAttendance: 'Session Attendance',
    classAttendance: 'Class Attendance',
    semester: 'Session',
    attendanceStatus: 'Status',
    type: 'Type',
    period: 'Period',
  },
  moment: {
    studentName: 'Student',
    drafts: 'Drafts',
    cancelShare: {
      students: 'Cancel Share Students',
      parents: 'Cancel Share Parents',
    },
    share: { parents: 'Share Parents', students: 'Share Students' },
    copyEdit: 'Copy&Edit',
    expandContent: 'Expand',
    collapseContent: 'Collapse',
    moreFilter: 'More Filters',
    totalLike: 'Total {total} Person Like',
    saveDraft: 'Save Draft',
    translation: 'Translation',
    img: 'picture',
    video: 'video',
  },
  sen: {
    learningSpecialty: 'Learning Specialty | Learning Specialties',
    accommodationsAndInterventions: 'Accommodations And Interventions',
    meetingTime: 'Meeting Time',
    relativeStudents: 'Relative Students',
    relativeTeachers: 'Relative Teachers',
    senDetail: 'SEN Detail',
  },
  kindergartenAtten: {
    allOnTime: 'All Punctual',
    signIn: 'Sign In',
  },
  tips: {
    invalidImg: 'Please upload photos in correct format(.jpg, .jpeg, .png)',
    selectStudents: 'Please select students',
    inputContent: 'please input description',
    momentDeleted: 'This moment has been deleted',
    noCourseArrange: 'There is no courses arrange in selected time',
    selectSubjectClass: 'Please select subject class',
    selectLifeBlock: 'Please select Life Block',
    selectType: 'Please select type',
    selectSubject: 'Please select Subject',
    select: 'Please Select',
    enterDescription: 'Please enter description',
    enterTopScore: 'Please enter top score',
    selectAssignmentType: 'Please select assignment type',
    selectHandInOverDeadline: 'Please select allowing hand in over deadline or not',
    selectTotalIncluded: 'Please select included in final score or not',
    selectOperateStudent: 'Please select student before operating',
    updateScoreSuccess: 'Update Score Info Successfully',
    updateRulesSuccess: 'Update Categories Successfully',
    generateReportSuccess: 'Generate Report Successfully',
    saveSuccess: 'Save Successfully',
    sendSuccess: 'Send Successfully',
    updateSuccess: 'Update Successfully',
    enterRejectReason: 'Please enter reject reason',
    selectGenerateStudent: 'Please select students which has generated report',
    timeStartAfterEnd: 'Illegal start time after end time',
    timeEndBeforeStart: 'Illegal end time before start time',
    selectTime: 'Please select time',
    selectDate: 'Please select date',
    enterName: 'Please enter name',
    selectGrade: 'Please select grade',
    deleteSuccess: 'Delete Successfully',
    selectLearningSpecialties: 'Please select learning specialties',
    selectAccommodationsAndInterventions: 'Please select accommodations and interventions',
    clockInSuccess: 'Clock In Successfully',
    selectStatus: 'Please Select Status',
    attachmentTip: 'Single attachment is limited to {size} and add up to {num} attachments',
    textLimit: 'Limit to {num} characters',
    textLimitZh: 'Limit to {num} characters(Chinese has 2 unit characters)',
    allPunctualConfirm: 'Are you sure to set no records students to punctual?',
    acceptFileTips: 'Only accept files with following suffix: {tips}',
    acceptSizeTips: 'Size of single attachment cannot exceed {size}',
    rejectFileTips: 'Files with the suffix: {tips} are unacceptable',
    notFoundClass: 'Can not find this class.',
    uploadFailed: 'Upload Failed',
    selectAssessStudent: 'Please select student whose grades have been assessed',
    sendReportFailed:
      'The report period is not over or the sending conditions are not met, report cannot be sent',
    pleaseEnterReason: 'Please enter reason',
    selectLeaveType: 'Please select leave type',
    studentTransferError: 'The student has transferred',
    recallConfirm: 'Are you sure to recall?',
    gradeSaved: 'Grade Saved',
    needNum: 'Number Only',
    positiveOnly: 'Positive Number Only',
    negativeOnly: 'Negative Number Only',
    overSize: 'limit {min}-{max}',
  },
  systemMessage: {
    favorTips: 'Favors This Moment',
    notice: 'Notice',
    favor: 'Favor',
    approval: 'Approval',
    momentDetail: 'Moment Detail',
    auditSuccess: 'Adjust Class SuccessFully',
    auditFail: 'Adjust Class Failed',
    content: {
      '1609':
        'Your approval for adjusting ({time}) has passed, please upload course resource in time',
      '1620': 'Initiate a apply for adjusting, time: {time}',
      '1622': 'Initiate a apply for taking leave, time: {time}',
      '1626': 'Your approval for adjusting ({time}) has been rejected by approver',
    },
  },
  errorCode: {
    '403': 'Sorry, you do not have access to this page',
    '404': 'Sorry, the page you visited does not exist.',
    585: 'Unfinished evaluation cannot be sent',
    586: 'The system is processing the report data, please try again later',
    589: 'The report period is not over，report can not be sent',
    '1010': 'Incorrect Account Or Password',
    '1039': 'Incorrect VerCode',
    '1041': 'Account Does Not Exist',
    4006: 'Sorry, you do not have login right',
    594: 'The reporting period is not in progress, save failed',
  },
  placeholder: {
    enterDescription: 'Please Input Description',
    select: 'Please select',
    enterName: 'please Enter Name',
  },
  assignment: {
    deadline: 'Deadline',
    totalIncluded: 'Included In Final Score',
    assignmentType: 'Assignment Type',
    assignmentDetail: 'Assignment Detail Of {name}',
    online1: 'Online',
    online2: 'Online(Students need to submit assignment online)',
    offline: 'Offline',
    creator: 'creator',
    createTime: 'Create Time',
    submitStatus: 'Status',
    submitTime: 'Submit Time',
    topScore: 'Top Score',
    handInAfterDead: 'Hand In After Deadline',
    allow: 'Allow',
    disallow: 'Disallow',
    Submit: 'Submitted',
    notSubmit: 'Not Submitted',
    submitList: 'Submit List',
    typeCode: {
      '1001': 'Prep',
      '1002': 'Lab',
      '1003': 'Quiz',
      '1004': 'Lab',
      '1005': 'Mid Exam',
      '1006': 'End Exam',
      '1007': 'Test',
      '1008': 'Field Observation',
    },
    courseExistType:
      '【{type}】 already exists in 【{courses}】, only one task of this type can be added per semester',
    deleteConfirm:
      "Students' task and score will be deleted  after deleting the task, are you sure to delete?",
    scoreAllow: 'Score Allow',
    allowTips: 'Assignment can be scored after selecting allow option',
    publicScore: 'Public Score',
    description: 'Assignment Description',
    startDate: 'Start Date',
    deadlineError: 'Deadline should be later than start date',
    startDateError: 'Start date should be earlier than deadline',
    resubmitTip: 'The student has resubmit the assignment, please check',
    tip: 'Tip',
    ignore: 'Ignore',
  },
  grading: {
    scoreSheet: {
      rules: 'Categories',
      FSRules: 'Term Weights',
      warningTips: 'The percentage must add up to 100%',
      mark: {
        missing: 'Missing',
        incomplete: 'Incomplete',
        late: 'Late',
      },
      finalScore: 'Final Percentage',
      addType: 'Add Type',
      export: 'Export',
      maxScore: 'Max: {score}',
      level: 'Level: {score}',
      notIncludeTips: 'Not included in final percentage',
      hideTips: 'Score are not shown to students',
      systemScore: 'System Score',
      customScore: 'Custom Score',
      studentVisible: 'Student Visible',
      studentInvisible: 'Student Invisible',
      foldStudent: 'Fold student out of class',
      unfoldStudent: 'Show dropped students',
      scoreIn: 'Edit Grades',
      finishScore: 'Save',
      last: 'Previous',
    },
    report: {
      generateReport: 'Generate',
      sendReport: 'Send',
      regenerateReport: 'Regenerate Report',
      downloadReport: 'Download',
      notGenerated: 'Not Generated',
      unpublished: 'Not Send',
      published: 'Sent',
      reportName: 'Report Name',
      generateTime: 'Generate Time',
      editComment: 'Edit Comment',
      sendConfirm: 'The report cannot be modified after sending,are you sure to send?',
      regenerateConfirm: 'Are you sure to regenerate report?',
      sendResult: 'Send Result',
      exportComments: 'Export Comments',
      headteacherComments: 'Headteacher Comments',
      advisorComments: 'Advisor Comments',
      subjectTeacherComments: 'Subject Teacher Comments',
      academicAchieve: 'Academic Achieve Table',
      commentProgress: '{type} Progress',
      completeStatus: '{type} Complete Status',
      incompleteAssess: 'Incomplete Assessment',
    },
  },
  studentDetail: {
    honor: {
      honorTime: 'Honor Time',
      edit: 'Edit Honor',
      add: 'Add Honor',
      honorDetail: 'Honor Detail',
    },
    leadership: {
      edit: 'Edit Leadership',
      add: 'Add Leadership',
      tenure: 'Tenure',
      leaderDetail: 'Leadership Detail',
    },
    label: {
      honor: 'Honors',
      leadership: 'Leadership',
      report: 'Report',
      points: 'Score',
      studentInfo: 'Student',
      parentInfo: 'Parent',
      curriculumInfo: 'Timetable',
      teacherInfo: 'Teacher',
      classInfo: 'Class',
      subjectInfo: 'Course',
      diary: 'Student Log',
      attendanceInfo: 'Attendance',
      instruction: 'Instruction',
      gradeBook: 'Grade Book',
      transferInfo: 'Transfer Info',
    },
    cropTip:
      'Mouse wheel zoom control image display size, drag and drop to adjust the image position',
    preview: 'Preview',
    reupload: 'Reupload',
    uploadAvatar: 'Upload Avatar',
    wrongAvatar: 'Wrong Picture type',
    wrongBrowser: 'Browser Error',
    score: 'Score',
  },
  eGrade: {
    reportTime: 'Report Time',
    gradeAssessment: 'Grade assessment',
    headteacherRemark: 'Headteacher Comments',
    subjectTeacherRemark: 'SubjectTeacher Comments',
    LBRemark: 'LB Comments',
    assessmentTips: 'academic achievement items Completed / All',
    headteacherTips:
      'The Number of people who have filled in comments/Number of people who need to fill in',
    subjectTeacherTips:
      'The Number of people who have filled in comments/Number of people who need to fill in',
    commentTips:
      'The Number of people who have filled in comments/Number of people who need to fill in',
    applyAll: 'Apply All',
    reportPeriodLockedTip:
      'The report period has been locked and cannot be edited. Please contact the administrator to edit it.',
    reportPeriodEndedTip: 'The report period has been ended, only available for viewing',
    reportPeriodNotEndTip:
      'The current reporting period is not over, you can only send the report after the administrator ends it',
    reportPeriodNotLockTip:
      'The current report period is not locked. After the administrator locks the report, the report can be generated and sent',
    reportPeriodNotLockTipCD:
      'The current report period is not locked. After the administrator locks the report, the report can be generated',
    reportPeriodProgress:
      'The report is being generated, you can send and download after all completed, the current progress: {progress}%',
    reportPeriodProgressCD:
      'The report is being generated, you can download after all completed, the current progress: {progress}%',
    graded: 'Graded',
    levelOfMastery: 'Level of Mastery',
    descriptionOfPerformance: 'Description of Performance',
    viewStandard: 'View Standard',
    remark: 'Comments',
    complete: 'Complete',
    academicAchievements: 'Academic Achievements',
    learningEfforts: 'Learning Efforts',
    advisorRemark: 'Advisor Comments',
    subject: 'Subject Class',
    headTeacher: 'Homeroom',
    advisor: 'Advisory',
    lifeBlock: 'Life Block',
    batchApplyConfirm: 'Are you sure to apply all?',
    termReport: 'Term Report',
    yearlyReport: 'Yearly Report',
    progress: 'The progress of assess',
    completed: 'Completed: 0 student | Completed: 1 student | Completed: {num} students',
    incompleted: 'Incompleted: 0 student | Incompleted: 1 student | Incompleted: {num} students',
    academicAchievementsStandard: 'Academic Achievements Standard',
    learningEffortsStandard: 'Learning Efforts Standard',
    exportAssessmentSheet: 'Export Assessments',
    editFirst: 'Please click the Edit button to edit the grade',
  },
  dormitory: {
    floor: 'Floor',
    room: 'Room',
    bed: 'Bed',
    bedName: 'Room/Bed',
    studyRoom: 'Night Study',
    selfStudy: 'Night Study',
  },
  teaching: {
    assignment: 'Assignment',
    resource: 'Resource',
    notStart: 'Not start',
    started: 'Started',
    inTotal: 'Included In Final Score',
    online: 'Online',
    startDate: 'Start Date',
    downloadAttach: 'Download Attachment',
    downloadAllAttach: 'Download all attachments',
    subjectClass: 'Subject Class',
    CCAClass: 'Life Block',
    taskAndData: 'Assignment & Resource',
    scoreSheet: 'Gradebook',
    schoolYear: 'School Year',
    name: 'Name',
    type: 'Type',
    taskType: 'Task Type',
    status: 'Status',
    scoreAllow: 'Score Allow',
  },
  LBReport: {
    schoolDay: 'School Day',
    export: 'Export',
    xlsxName: 'Student Life Block Form{date}',
    tableName: 'Student Life Block Week Table{date}',
    courseForm: 'Student LB Course Form',
    weekForm: 'Student LB Week Form',
    week: 'Week',
  },
  shortWeekday: {
    '0': 'Sun',
    '1': 'Mon',
    '2': 'Tue',
    '3': 'Wed',
    '4': 'Thu',
    '5': 'Fri',
    '6': 'Sat',
    '7': 'Sun',
    monday: 'Mon',
    tuesday: 'Tue',
    wednesday: 'Wed',
    thursday: 'Thu',
    friday: 'Fri',
    Saturday: 'Sat',
    Sunday: 'Sun',
  },
  notice: {
    title: 'Title',
    department: 'Department',
    date: 'Date',
    status: 'Status',
    creater: 'Creater',
    publishers: 'Publishers',
    releaseTime: 'Release Time',
    operations: 'Operations',
    recycleBin: 'Recycle Bin',
    announcement: 'Announcement',
    sticky: 'Sticky',
    cancelSticky: 'Cancel Sticky',
    moveToRecycle: 'Move To Recycle Bin',
    confirmCancelSticky: 'Are you sure to cancel top the announcement?',
    confirmRecycle: 'Are you sure to move to recycle bin?',
    confirmSticky: 'Are you sure to top the announcement?',
    confirmDeleteDep: 'Are you sure to delete the department?',
    confirmDeleteTag: 'Are you sure to delete the tag?',
    published: 'Published',
    notPublished: 'Unpublished',
    endingDate: 'Ending Date',
    enterEngingDate: 'Please enter ending date',
    enterName: 'Please enter name',
    addDepartment: 'Add Department',
    editDepartment: 'Edit Departmetn',
    addStaffs: 'Add Staffs',
    publishStatus: { published: 'Published', unpublish: 'Unpublish' },
    tagName: 'Title',
    applyType: 'Apply Type',
    types: {
      1: 'Notice',
      2: 'News',
      3: 'Activity ',
    },
    addTag: 'Add Tag',
    editTag: 'Eidt Tag',
    limit: {
      cnName: 'Chinese name cannot exceed 100 characters',
      enName: 'English name cannot exceed 100 characters',
    },
    color: 'Color',
    selectType: 'Please select Type',
    duplicatedName: 'Name cannot be duplicated',
    selectColor: 'Please select Color',
    restore: 'Restore',
    delete: 'Delete',
    confirmRestore: 'Are you sure to restore the file?',
    confirmDeleteNotice: 'Are you sure to delete the file?',
    enterTitle: 'Please enter Title',
    enterDepName: 'Please enter Department Name',
    selectTags: 'Please select Tags',
    selectTime: 'Please select Time',
    selectScope: 'Please select Scope',
    selectDepartment: 'Please select Department',
    enterContent: 'Please enter Content',
    attachmentTip1: 'A single attachment is limited to 100m,',
    attachmentTip2: 'add up to 10 attachments',
    attachmentTip3: 'add up to 5 attachments',
    tag: 'Tag',
    content: 'Content',
    attachment: 'Attachment',
    publishTiming: 'Publish Timing',
    stickyEndingTime: 'Sticky Ending Time',
    recipientScope: 'Recipient Scope',
    editTip: 'Edited Tip',
    notifiedByEmail: 'Notified By Email',
    editInstruction: 'Edited Instruction',
    admin: 'Admin',
    teacher: 'Teacher',
    parent: 'Parent',
    student: 'Student',
    sections: 'Sections',
    boarders: 'Boarders',
    dayStudents: 'Day Students',
    emailNotice: 'Email Notice',
    send: 'Send',
    uploadAttachments: 'Upload Attachments',
    publishTips: '(Announcement will be published immediately if you choose false)',
    sendPreview: 'Send Preview Email',
  },
  announcement: {
    all: 'All',
    campusActivity: 'Campus Activite',
    campusPolicy: 'Campus Policy',
    attention: 'Attention',
    search: 'Search',
    edit: 'The announcement was edited at {time}',
    department: 'Department',
    time: 'Time',
    status: 'Status',
    publisher: 'Publisher',
    isTop: 'Top Or Not',
    topEndTime: 'Top End Time',
    published: 'Published',
    unpublished: 'Unpublished',
    yes: 'Yes',
    no: 'No',
    keyword: 'keyword',
    date: 'Date',
    tag: 'Tag',
  },
  monthlyMgm: {
    courseClass: 'Subject Class',
    setAll: 'Assign grades to all students',
    selectGrade: 'Please select grade',
    tutor: 'Tutor',
    progress: 'The progress of assess',
    progressTip: 'Number of courses completed/all courses requiring assessment',
    title: {
      assess: 'The progress of assess',
      tutor: 'Tutor Comments Complete Status',
      headerTeacher: 'Headteacher Comments Complete Status',
      deputyHead: 'Head of Section Comments Complete Status',
      courseTeacher: 'Course Comments Complete Status',
      percentage: 'Percentage',
    },
    singleColumn: {
      tutor: 'Advisory',
      headerTeacher: 'Homeroom',
      deputyHead: 'Campus',
      courseTeacher: 'Course Class',
      percentage: 'Course Class',
    },
    status: {
      assess: 'Assessments Complete Status',
      other: 'Comments Complete Status',
    },
    comments: 'Comments',
    housePoint: 'House Points',
    houseAchievement: 'House Achievement',
    conductPoint: 'Conduct Points',
    behaviourEvent: 'Bhaviour Event',
    point: 'Point',
    edithouse: 'Edit House Points',
    editconduct: 'Edit Conduct Points',
    advisorRemark: 'Tutor Comments',
    export: 'Export Assessment Data',
    month: 'Month',
    selectMonth: 'Please Select Month',
    deputyHead: 'Head of Section',
    courseTeacher: 'Course Comments',
    percentage: 'Percentage',
    name: 'Name',
    grade: 'Grade',
    house: 'Advisory',
    exportConduct: 'Export Conduct',
    schoolYearHousePoint: 'House points for the school year',
    schoolYearConductPoint: 'Conduct points for the school year',
  },
  academic: {
    modifiedRecord: 'Record',
    modified: 'Modified',
    unmodified: 'Unmodified',
    tag: 'Status',
    extraTag: 'extra Status',
    reportStatus: 'Report Status',
    updatable: 'Updatable',
    updated: 'Updated',
    additionalStatus: 'Additional Status',
    exportAcademicStanding: 'Export Academic Standing',
    exportBelowStandard: 'Export Below Standard Course',
    noLabel: 'No Status',
    // standing: 'Good Standing',
    // warning: 'Academic Warning',
    // probation: 'Academic Probation',
    // review: 'Academic Review',
    standing: 'GS',
    warning: 'AW',
    probation: 'AP',
    review: 'AR',
    scoreSheet: 'Gradebook',
    updateReport: 'Update Report',
    updateReportConfirm: 'Are you sure to update report?',
    updateLabel: 'Edit Status',
    systemLabel: 'Calculated Status',
    label: 'Status',
    editRecord: 'Edit Log',
    editDetail: 'changed status {old} to label {new}',
    timeEditDetail: '{time} {person},changed status {old} to label {new}',
    statement: 'Statements',
    systemStatus: 'Calculated Status',
    curStatus: 'Current Status',
    point: 'Score',
    statementDetail: 'Status Detail',
    warningCourses: 'Courses of 60-70',
    probationCourses: 'Courses of <60',
    newWarningCourse: 'Courses of <=66',
    average: 'Average',
    belowStandard: 'Courses below standard',
    isUnpdated: 'If Synchronize Report',
    normal: 'normal',
  },
  allLabel: {
    standing: 'Good Standing',
    warning: 'Academic Warning',
    probation: 'Academic Probation',
    review: 'Academic Review',
    noLabel: 'No Status',
  },
  campus: {
    '1231': 'Primary School',
    '1232': 'Middle School',
    '1233': 'Senior School',
  },
  graduation: {
    requirementSetting: 'Graduation Standard setting',
    graduationRqm: 'Graduation Standard',
    totalCredit: 'Total credits',
    creationDate: 'Creation Date',
    setAsDefault: 'Set As Default',
    setConfirm: 'Are you sure to set as default?',
    deleteConfirm: 'Are you sure to delete',
    requirements: 'Requirements Setting',
    instruction: 'Instruction',
    COMPULSORY: 'Compulsory',
    compulsory: 'Compulsory',
    compulsoryCredit: 'Compulsory Credits',
    compulsoryLabel: 'Compulsory (credits: {credit})',
    NONE: 'None',
    NONE_COUNTED: 'None',
    ELECTIVE: 'Elective',
    elective: 'Elective',
    electiveCredit: 'Elective Credits',
    electiveLabel: 'Elective (credits: {credit})',
    courseAdmission: 'Course Criteria{index}',
    mark: 'Mark',
    courseGroup: 'Course Group{index}',
    course: 'Course',
    maxRecognizedCreitds: 'Maximum Recognized Creitds',
    title: 'Title',
    selectCourseGroup: 'Please Select Courses',
    validateCredit:
      'The total score of the course group and the required credits should be equal,please adjust before save.',
    addCourse: 'Add Course',
    addCourseGroup: 'Add Course Group',
    addCourseAdmission: 'Add Course Criteria',
    changeStandard: 'Change Standard',
    progress: 'Graduation Progress',
    schoolCredits: 'School Course Credits',
    outOfSchoolCredits: 'Out-Of-School Course Credits',
    addOut: 'Add Out-Of-School Course',
    editOut: 'Edit Out-Of-School Course',
    calcType: 'Calculated Type',
    associate: 'Associate School Course',
    associatedCourse: 'Associate Course',
    finished: 'Finished',
    inProgress: 'In Progress',
    viewRequirements: 'View Graduation Standard',
    changeRequirements: 'Change Graduation Standard',
    selectStandard: 'Please select graduation standard',
    studies: 'Studies',
    completed: 'Completed',
    complete: 'Completed',
    ongoing: 'Ongoing',
    courseType: 'Course Type',
    add: 'Add',
    courseName: 'Course Name',
    passConfirm: 'Are you sure to pass this course?',
    withdrawPassConfirm: 'Are you sure to withdraw the pass of this course?',
    creditOverflow: 'Has exceeded the maximum required credits, included in the elective credits',
    noneTip: 'I have retaken the same course and it will not be counted as credit',
    gotCredits: 'Got Credits',
    completedProgress: 'Completed Progress',
    finishedCredit: 'Finished Credit',
    // ongoingCourse: 'Ongoing Course',
    EXTRA: 'Out-Of-School Course',
    OVERFLOW: 'Counted as elective credit',
    category: 'Subject Category',
    level: 'Grade',
    exportAcademicProgressReport: 'Export Progress Report',
    courseStatus: 'Course Status',
    outOfStandardTip: 'The course is not within the standard',
  },
  qrcode: {
    download: 'download QRcode',
    scanToLogin: 'Scan the QRcode to login mobile terminal',
    deadline: 'Valid until：{time}',
    generateQRCode: 'Generate QR code',
    tip1: 'The QR code is valid for',
    tip2: 'Please regenerate after expiration',
    day: 'days',
    update: 'Update Immddiately',
    expired: 'QR code has expired',
    qrcodeTitle: 'QR cdoe',
  },
}
