




















































































































































































































import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator'
import { CurriculumController } from '@/services/request.service'
import moment, { Moment } from 'moment'
import flattenDeep from 'lodash/flattenDeep'
import cloneDeep from 'lodash/cloneDeep'

@Component
export default class CurriculumForExport extends Vue {
  @Prop({ default: {} }) public readonly curriculumData!: any
  @Prop({ default: {} }) public readonly studentInfo!: any
  @Prop() public readonly id!: any
  @Prop() public readonly type!: any
  @Prop() public readonly startTime!: any
  @Prop() public readonly endTime!: any
  @Prop() public readonly yearKey!: number
  @Prop() public readonly yearList!: Array<any>
  private moment = moment
  private cellHeight: number = 148
  private get periods(): Array<any> {
    return this.type !== 'student'
      ? [
          {
            start: moment()
              .hours(8)
              .minutes(0),
            end: moment()
              .hours(9)
              .minutes(0),
          },
          {
            start: moment()
              .hours(9)
              .minutes(0),
            end: moment()
              .hours(10)
              .minutes(0),
          },
          {
            start: moment()
              .hours(10)
              .minutes(0),
            end: moment()
              .hours(11)
              .minutes(0),
          },
          {
            start: moment()
              .hours(11)
              .minutes(0),
            end: moment()
              .hours(12)
              .minutes(0),
          },
          {
            start: moment()
              .hours(12)
              .minutes(0),
            end: moment()
              .hours(13)
              .minutes(0),
          },
          {
            start: moment()
              .hours(13)
              .minutes(0),
            end: moment()
              .hours(14)
              .minutes(0),
          },
          {
            start: moment()
              .hours(14)
              .minutes(0),
            end: moment()
              .hours(15)
              .minutes(0),
          },
          {
            start: moment()
              .hours(15)
              .minutes(0),
            end: moment()
              .hours(16)
              .minutes(0),
          },
          {
            start: moment()
              .hours(16)
              .minutes(0),
            end: moment()
              .hours(17)
              .minutes(0),
          },
          {
            start: moment()
              .hours(17)
              .minutes(0),
            end: moment()
              .hours(18)
              .minutes(0),
          },
        ]
      : this.curriculumData.classPeriods || []
  }
  private get week(): Array<any> {
    const classArranges = this.classArranges
    const classDay = this.curriculumData.dayOfArranged || []
    let _startTime = cloneDeep(this.startTime)
    return [
      {
        name: '周一',
        enName: 'Mon',
        dayOfWeek: 1,
        dayOfArranged: classDay[0],
        toDay: _startTime.tz('Asia/Shanghai').format('M.D'),
      },
      {
        name: '周二',
        enName: 'Tue',
        dayOfWeek: 2,
        dayOfArranged: classDay[1],
        toDay: _startTime
          .add(1, 'day')
          .tz('Asia/Shanghai')
          .format('M.D'),
      },
      {
        name: '周三',
        enName: 'Wed',
        dayOfWeek: 3,
        dayOfArranged: classDay[2],
        toDay: _startTime
          .add(2, 'day')
          .tz('Asia/Shanghai')
          .format('M.D'),
      },
      {
        name: '周四',
        enName: 'Thu',
        dayOfWeek: 4,
        dayOfArranged: classDay[3],
        toDay: _startTime
          .add(3, 'day')
          .tz('Asia/Shanghai')
          .format('M.D'),
      },
      {
        name: '周五',
        enName: 'Fri',
        dayOfWeek: 5,
        dayOfArranged: classDay[4],
        toDay: _startTime
          .add(4, 'day')
          .tz('Asia/Shanghai')
          .format('M.D'),
      },
      {
        name: '周六',
        enName: 'Sat',
        dayOfWeek: 6,
        dayOfArranged: classDay[5],
        toDay: _startTime
          .add(5, 'day')
          .tz('Asia/Shanghai')
          .format('M.D'),
      },
      {
        name: '周日',
        enName: 'Sun',
        dayOfWeek: 7,
        dayOfArranged: classDay[6],
        toDay: _startTime
          .add(6, 'day')
          .tz('Asia/Shanghai')
          .format('M.D'),
      },
    ]
      .map((item, index) => {
        if (index < 5) {
          if (classArranges && classArranges[item.dayOfWeek]) {
            return {
              ...item,
              ...classArranges[item.dayOfWeek],
            }
          } else {
            return item
          }
        } else {
          if (classArranges && classArranges[item.dayOfWeek]) {
            let showDay = false

            if (this.type === 'student') {
              Object.keys(classArranges[item.dayOfWeek]).forEach(key => {
                if (classArranges[item.dayOfWeek][key]?.type !== 'periodArranges') {
                  showDay = true
                }
              })
            } else {
              classArranges[item.dayOfWeek].forEach(course => {
                if (course.type !== 'periodArranges') {
                  showDay = true
                }
              })
            }
            return showDay
              ? {
                  ...item,
                  ...classArranges[item.dayOfWeek],
                }
              : undefined
          } else {
            return undefined
          }
        }
      })
      .filter(item => item)
  }

  private get courses(): any {
    if (this.type !== 'student') {
      return this.getCrossGroup(this.curriculumData.courses)
    }
    return this.curriculumData.courses || {}
  }

  private get total(): any {
    return this.curriculumData.total
  }

  private get substitute(): any {
    return this.curriculumData.substitute || 0
  }

  private get classArranges(): Array<any> {
    return this.type === 'student'
      ? this.curriculumData.classArranges || []
      : this.curriculumData.courses || []
  }

  private calcLeft(start): string {
    const startHour = moment(start).hours()
    const startMinute = moment(start).minutes()
    return (startHour - 8) * 200 + (startMinute / 60) * 200 + 'px'
  }

  private calcWidth(start, end): string {
    const startHour = moment(start).hours()
    const startMinute = moment(start).minutes()
    const endHour = moment(end).hours()
    const endMinute = moment(end).minutes()
    return (endHour - startHour) * 200 + ((endMinute - startMinute) / 60) * 200 + 'px'
  }
  private calcTop(start): string {
    const startHour = moment(start)
      .tz('Asia/Shanghai')
      .hours()
    const startMinute = moment(start)
      .tz('Asia/Shanghai')
      .minutes()
    return (startHour - 8) * this.cellHeight + (startMinute / 60) * this.cellHeight + 30 + 'px'
  }
  private calcHeight(start, end): string {
    const startHour = moment(start)
      .tz('Asia/Shanghai')
      .hours()
    const startMinute = moment(start)
      .tz('Asia/Shanghai')
      .minutes()
    const endHour = moment(end)
      .tz('Asia/Shanghai')
      .hours()
    const endMinute = moment(end)
      .tz('Asia/Shanghai')
      .minutes()
    return (
      (endHour - startHour) * this.cellHeight +
      ((endMinute - startMinute) / 60) * this.cellHeight +
      'px'
    )
  }
  private calcBg(course): string {
    let newColor = '#fff'
    if (!course) return newColor
    let color = (course.courseId.subject || {}).color
    if (color) {
      const r = parseInt(color.slice(1, 3), 16)
      const g = parseInt(color.slice(3, 5), 16)
      const b = parseInt(color.slice(5, 7), 16)
      newColor = 'rgba(' + r + ', ' + g + ', ' + b + ', ' + '0.48)'
    }
    if (course.type === '1002') {
      return 'rgba(70,194,154,0.2)'
    } else if (course.type === 'periodArranges') {
      return 'rgba(128,128,154,0.04)'
    } else {
      return newColor
    }
  }

  private calcSubstitute(teachers): boolean {
    return (teachers.filter(teacher => teacher.teacherId === this.id)[0] || {}).isSubstitute
      ? true
      : false
  }

  private getTeachersName(course): any {
    return course ? course.teachers.map(item => item.name).join('、') : ''
  }

  private getCrossGroup(courses): any {
    const tmpCourses = cloneDeep(courses)
    let newCourses = {}
    Object.keys(tmpCourses).forEach(key => {
      let dayList: any = []
      tmpCourses[key] = tmpCourses[key].sort((a, b) => b.startTime - a.startTime)
      do {
        const course = tmpCourses[key].pop()
        let groupList = [] as any
        groupList.push({
          ...course,
          crossIndex: 0,
        })
        let index = 0
        tmpCourses[key] = tmpCourses[key]
          .map(course => {
            for (let j = 0; j < groupList.length; j++) {
              if (
                !(
                  groupList[j].startTime >= course.endTime ||
                  groupList[j].endTime <= course.startTime
                )
              ) {
                groupList.push(course)
                return undefined
              }
            }
            return course
          })
          .filter(item => item)
        dayList.push(groupList)
      } while (tmpCourses[key].length)
      dayList = dayList
        .map(group => {
          const _group = cloneDeep(group)
          group = _group
            .sort((a, b) => a.startTime - b.startTime)
            .map((item, idx) => {
              // const height = 100 / Math.min(_group.length, 2) + '%'
              const num: number = _group.length == 3 ? 33.33 : 100 / Math.min(_group.length, 3)
              const width = num + '%'
              return {
                ...item,
                groupIndex: idx,
                crossGroupLength: _group.length,
                crossTop: 50 * (idx % 2) + '%',
                crossLeft: idx * num + '%',
                crossWidth: `calc(${width} - 2px)`,
                showThird: (item.endTime - item.startTime) / 60000 < 30 ? false : true,
              }
            })
          return group
        })
        .flat()
      this.$set(
        newCourses,
        key,
        dayList.sort((a, b) => a.startTime - b.startTime)
      )
    })
    console.log('newCourses__', newCourses)
    return newCourses
  }
}
